import React, { useEffect, useState } from "react";
import CreateNew from "../components/MasterManagement/CreateNew";
import TabsHeader from "../components/MasterManagement/TabsHeader";
import MasterDataDisplay from "../components/MasterManagement/MasterDataDisplay";
import Locations from "../components/MasterManagement/Locations";

function MasterRecordManagement() {
  //Setting initially selected TAB Category to locations.
  const [Category, setCategory] = useState("locations");
  //setting state to be passed on to be set and used by different components.
  const [data, setdata] = useState([]);
  const [trigger, setTrigger] = useState(0);

  //TO check if user is edditing data
  const [Edit, setEdit] = useState(false);
  const [EditData, setEditData] = useState({});
  const [DataChanged, setDataChanged] = useState(false);
  useEffect(() => {
    console.log("data changed");
  }, [data]);

  const changingData = (datas) => {
    // const newData = [...data, datas]; // Create a new array reference
    // setdata(newData);
    setdata((prevData) => [...prevData, datas]);
    setTrigger((prev) => prev + 1);
  };
  return (
    <div className="relative">
      {/* Passing category as props to be set on selection of different tabs */}

      <TabsHeader category={Category} setCategory={setCategory} />
      {Category != "locations" ? (
        <div className="flex pt-16 h-[65vh]">
          {/* Data table display */}
          <MasterDataDisplay
            category={Category}
            data={data}
            setData={setdata}
            Edit={Edit}
            setEdit={setEdit}
            setEditData={setEditData}
            DataChanged={DataChanged}
          />
          {/* Passing Category to show interface according to each category */}
          <CreateNew
            category={Category}
            data={data}
            setData={setdata}
            Edit={Edit}
            EditData={EditData}
            setEdit={setEdit}
            DataChanged={DataChanged}
            setDataChanged={setDataChanged}
            changingData={changingData}
          />
        </div>
      ) : (
        <Locations
          data={data}
          setData={setdata}
          DataChanged={DataChanged}
          setDataChanged={setDataChanged}
          category={"locations"}
        />
      )}
    </div>
  );
}

export default MasterRecordManagement;
