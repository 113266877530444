import React, { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { FaSearch } from "react-icons/fa";
import { getData } from "../../services/NetworkServices";
import NewLocation2 from "./NewLocation2";
import { Pagination } from "antd";
import { useRole } from "../../RoleContext";
import MasterRecordManagement from "../../pages/MasterRecordManagement";
import { usePermission } from "../../PermissionContext";

function Locations({ data, setData, DataChanged, setDataChanged, category }) {
  const [Loading, setLoading] = useState(false);
  //State for search data as this should always be complete data and shouldnt change unless on Editing or deletion.
  const [CompleteData, setCompleteData] = useState(data);
  //For Understanding: Current Page Numer Index is equiv to Current Page - 1
  const [CurrentPageIndex, setCurrentPageIndex] = useState(0);
  // Item being searched. Value for search bar input box.
  const [SearchItem, setSearchItem] = useState("");
  //Sliced Data that would be displayed varying by page to page.
  const [DisplayData, setDisplayData] = useState([]);

  const [ModalOpen, setModalOpen] = useState(false);

  const [Edit, setEdit] = useState(false);
  const [EditData, setEditData] = useState({});

  // const [DataChanged,setDataChanged]=useState(false);

  //Total table rows per page. Can be change by changing this variable.
  const rowPerPage = 3;

  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 5,
    total: data.length,
  });

  //Number of pages possible.
  // const maxPageNumber = Math.ceil(data.length / rowPerPage);
  // const baseURL = "https://pspapi.eraconnect.net/api/";

  //Divide data into slices per each page.
  useEffect(() => {
    setSearchItem("");
  }, [category]);

  // setCurrentPagination((prev) => ({
  //   ...prev,
  //   total: filterDataForactive.length,
  // }));

  const searchData = () => {
    const filteredData = CompleteData?.filter((item) => {
      return (
        category === "locations" &&
        item?.title?.toLowerCase()?.includes(SearchItem)
      );
    });
    setData(filteredData);
    setCurrentPagination((prev) => ({
      ...prev,
      total: filteredData.length,
      current: 1,
    }));
  };

  const setGetApiData = async (apiURL) => {
    try {
      setLoading(true);
      let res = await getData(apiURL);
      setData(res.data.data);
      setCompleteData(res.data.data);
      setCurrentPagination((prev) => ({
        ...prev,
        total: res.data.data.length,
      }));
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const sliceDataPerPage = () => {
    const startIndex =
      (currentPagination.current - 1) * currentPagination.pageSize;
    const endIndex = startIndex + currentPagination.pageSize;
    return data.slice(startIndex, endIndex);
  };

  //Change Display Data on every page change or data change.
  useEffect(() => {
    setDisplayData(sliceDataPerPage());
  }, [currentPagination.current, currentPagination.pageSize, data]);

  //Search and show results as soon as the search value changes
  useEffect(() => {
    searchData();
  }, [SearchItem]);

  // useEffect(() => {
  //   setCompleteData(data);
  // }, [Edit]);

  useEffect(() => {
    setGetApiData("BusinessUnits/GetAllBusinessUnits");
  }, []);

  useEffect(() => {
    setGetApiData("BusinessUnits/GetAllBusinessUnits");
    // setCompleteData(data);
  }, [DataChanged]);

  //Search and show results as soon as the search value changes

  //To re-set data after it has been edited.
  // useEffect(() => {
  //   setCompleteData(data);
  // }, [DataChanged]);

  //To increase page. To go forward.
  // const incrementPageNumber = () => {
  //   //Check if page number is within limit
  //   if (CurrentPageIndex < maxPageNumber - 1) {
  //     setCurrentPageIndex(CurrentPageIndex + 1);
  //     //Set the data.
  //     setDisplayData(sliceDataPerPage());
  //   }
  // };
  // To decrease page number. Go backward
  // const decrementPageNumber = () => {
  //   // Make sure page index is never negative
  //   if (CurrentPageIndex > 0) {
  //     setCurrentPageIndex(CurrentPageIndex - 1);
  //     setDisplayData(sliceDataPerPage());
  //   }
  // };

  const [paginatedData, setPaginatedData] = useState([]);

  const onPageChange = (page, pageSize) => {
    setCurrentPagination({
      current: page,
      pageSize,
      total: currentPagination.total,
    });
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(paginatedData.slice(0, currentPagination.pageSize));
  };

  const { permissions } = usePermission();
  const [editPermission, setEditPermission] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions.forEach((permission) => {
      if (permission.pageRoute === "/masterrecordmanagement") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEditPermission(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <div className="relative">
      {ModalOpen && (
        // <div className="bg-yellow-200">
        <NewLocation2
          ModalOpen={ModalOpen}
          setModalOpen={setModalOpen}
          MainData={data}
          setMainData={setData}
          setCompleteData={setCompleteData}
          Edit={Edit}
          setEdit={setEdit}
          EditData={EditData}
          setEditData={setEditData}
          setDataChanged={setDataChanged}
          DataChanged={DataChanged}
        />
        // </div>
      )}
      <div className=" mt-14 p-4 w-full border-[1px] border-gray-400 rounded-lg min-h-[60vh]">
        {Loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="min-w-full flex justify-between items-center mb-1">
              <h1 className="text-xl font-bold pl-4">Locations</h1>
              <div className="flex">
                <div className="relative mr-3">
                  <label className="bg-white absolute left-7 bottom-7 font-bold">
                    Search
                  </label>
                  <input
                    type="text"
                    className="border border-gray-400 rounded-full py-2 px-8 outline-gray-400"
                    value={SearchItem}
                    onChange={(e) => {
                      setSearchItem(e.target.value?.toLowerCase());
                    }}
                  />
                  <button className="absolute left-2 top-2">
                    <FaSearch className="w-5 h-5 text-gray-400 mr-2" />
                  </button>
                </div>
                {add && (
                  <button
                    className="btnSubmit"
                    onClick={(e) => {
                      e.preventDefault();
                      setModalOpen(true);
                    }}
                    style={{ width: "9vw", textTransform: "capitalize" }}
                  >
                    Create New
                  </button>
                )}
              </div>
            </div>
            <table className="min-w-full bg-[#F7F7F7] text-sm">
              <thead className="border-b-4  border-black">
                <tr className="w-full border- border-b ">
                  <th className="py-2 px-4 text-left font-light">
                    Business Units
                  </th>
                  <th className="py-2 px-4 text-left font-light">Address</th>
                  {/* <th className="py-2 px-4 text-left font-light">
                    No. of Persons
                  </th> */}
                  <th className="py-2 px-4 text-left font-light">
                    Contact No.
                  </th>
                  <th className="py-2 px-4 text-left font-light">
                    Coordinates
                  </th>
                  <th className="py-2 px-4 text-left font-light">
                    Radius(Meters)
                  </th>
                  <th className="py-2 px-4 text-left font-light">
                    Location Exemption
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="h-3/4">
                {DisplayData &&
                  DisplayData.length != 0 &&
                  DisplayData.map((row, index) => (
                    <>
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                      >
                        <td className="py-2 text-left px-4 border-b capitalize">
                          {row.title}
                        </td>
                        <td className="py-2 text-left px-4 border-b capitalize text-wrap">
                          {row.subLocalityName}
                        </td>
                        {/* <td className="py-2 text-left px-4 border-b capitalize">
                          {row.noOfPatientsOrStudents}
                        </td>{" "} */}
                        <td className="py-2 text-left px-4 border-b capitalize">
                          {row.contactNumber}
                        </td>
                        <td className="py-2 text-left px-4 border-b capitalize">
                          <p>{row.latitude},</p>
                          <p>{row.longitude}</p>
                        </td>
                        <td className="py-2 text-left px-4 border-b capitalize">
                          {row.radius}
                        </td>
                        <td
                          className={
                            row.locationExemptionFlag
                              ? "py-2 px-4 text-left font-light"
                              : "py-2 px-4 text-left font-light"
                          }
                        >
                          {row.locationExemptionFlag ? "Yes" : "No"}
                        </td>
                        {editPermission && (
                          <td className="py-2 text-left px-4 border-b">
                            <button
                              className="bg-black hover:bg-red-600 text-white rounded-lg py-1 px-5"
                              onClick={() => {
                                setEdit(true);
                                setEditData(row);
                                setModalOpen(true);
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        )}
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>

            {/* <div className="flex justify-end items-end mt-4">
              <button className="px-2 font-bold" onClick={decrementPageNumber}>
                {"<"}
              </button>
              <p className="font-bold">Page{CurrentPageIndex + 1} </p>
              <button className="px-2 font-bold" onClick={incrementPageNumber}>
                {">"}
              </button>
            </div> */}

            {/* <div className="">
                <Pagination
                  size="small"
                  total={currentPagination?.total}
                  pageSize={currentPagination.pageSize}
                  current={currentPagination.current}
                  onChange={onPageChange}
                />
            </div> */}

            <div className="flex justify-end items-center mt-4">
              <Pagination
                size="small"
                total={currentPagination.total}
                pageSize={currentPagination.pageSize}
                current={currentPagination.current}
                onChange={onPageChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Locations;
