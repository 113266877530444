import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";

function LocationsInput({
  inputField,
  placeholder,
  isDropDown = true,
  name,
  handleChange,
  type = "string",
  fetchDropDownData = [], // Ensure default empty array
  required = true,
  value,
  prefix,
  pattern,
  openModal,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const handleSelection = (option) => {
    handleChange({
      target: {
        name,
        value: option.id,
      },
    });
    setShowDropdown(false);
    setSearchTerm(""); // Clear search term after selection
  };

  const filteredOptions = fetchDropDownData.filter((option) => {
    const optionText =
      name === "masterRecordTypeID" ? option.name : option.title;
    return optionText.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {isDropDown ? (
        <div className="relative w-full">
          <input
            type="text"
            className="outline-red-200 py-3 px-6 w-full border border-red-400 rounded-full relative text-xs cursor-pointer"
            value={
              value
                ? fetchDropDownData.find((option) => option.id === value)
                    ?.name ||
                  fetchDropDownData.find((option) => option.id === value)?.title
                : searchTerm
            }
            placeholder={placeholder}
            onClick={() => setShowDropdown((prev) => !prev)}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleChange({
                target: {
                  name,
                  value: "",
                },
              });
            }}
          />
          <IoIosArrowDropdownCircle
            className="absolute bottom-3 right-4 text-xl"
            onClick={() => setShowDropdown((prev) => !prev)}
          />
          {showDropdown && (
            <div className="absolute z-10 w-full max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div
                    key={option.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleSelection(option)}
                  >
                    {name === "masterRecordTypeID" ? option.name : option.title}
                  </div>
                ))
              ) : (
                <div className="px-4 py-2 text-gray-500">No results found</div>
              )}
            </div>
          )}
        </div>
      ) : (
        <input
          className="outline-red-200 py-3 px-6 w-full placeholder:text-black border border-red-400 rounded-full relative text-xs"
          name={name}
          placeholder={placeholder}
          type={name === "contactNumber" ? type : "tel"}
          minLength={name === "contactNumber" ? 11 : undefined}
          maxLength={name === "contactNumber" ? 11 : undefined}
          required={required}
          onChange={(e) => handleChange(e)}
          value={value}
          prefix={<SiGooglemaps />}
          pattern={
            name === "contactNumber"
              ? "[0-9]{11}"
              : ["latitude", "longitude"].includes(name)
              ? "^-?\\d*\\.\\d+$"
              : undefined
          }
        />
      )}
      {["latitude", "longitude"].includes(name) && (
        <SiGooglemaps
          className="absolute top-3 right-4 text-xl cursor-pointer"
          onClick={openModal}
        />
      )}
      <p className="absolute bottom-9 left-8 font-bold bg-white text-sm">
        {inputField}
      </p>
    </div>
  );
}

export default LocationsInput;
