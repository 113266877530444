import axios from "axios";
import React, { useState, useEffect } from "react";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import JourneyDetails from "../components/journey/JourneyDetails";
import JourneyReports from "../components/journey/JourneyReports";
import { Link } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { getData } from "../services/NetworkServices";
import formatDate from "../components/journey/DateFormatFunc";
import { useRole } from "../RoleContext";
import { usePermission } from "../PermissionContext";
import VisitReport from "../components/journey/VisitReport";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";

export default function Journey({ category }) {
  const [data, setData] = useState([]);
  const [CompleteData, setCompleteData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState([]);
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [DataActiveField, setDataActiveField] = useState(true);

  const navigate = useNavigate()
  const JouneyData = async () => {
   const token = localStorage.getItem("token")
    setLoading(true);
    getData("Journey/ViewAllJourneyCycle",token)
      .then((res) => {
        setData(res?.data?.data);
        setCurrentPagination((prev) => ({
          ...prev,
          total: res?.data?.data?.filter((x) => x.status == DataActiveField)
            .length,
        }));
        if (!token) {
          navigate("/login")
        }
      })
      .catch((err) => {
        throw new Error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const handleJourneyClick = (item) => {
    setSelectedJourney(item);
  };

  const clickedItem = (index) => {
    setClicked(index);
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPagination({
      current: page,
      pageSize,
      total: currentPagination.total,
    });
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(paginatedData.slice(0, currentPagination.pageSize));
  };
  useEffect(() => {
    JouneyData();
  }, []);

  useEffect(() => {
    const startIndex =
      (currentPagination.current - 1) * currentPagination.pageSize;
    const endIndex = startIndex + currentPagination.pageSize;
    const filterDataForactive = filteredData.filter((item) => {
      if (item.status == DataActiveField) return item;
    });
    setPaginatedData(filterDataForactive.slice(startIndex, endIndex));
  }, [currentPagination, data, DataActiveField]);

  useEffect(() => {
    setCurrentPagination((prev) => ({ ...prev, current: 1 }));
  }, [search]);

  const filteredData = data.filter((item) => {
    return search?.toLowerCase() === ""
      ? item
      : item?.title?.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    const startIndex =
      (currentPagination.current - 1) * currentPagination.pageSize;
    const endIndex = startIndex + currentPagination.pageSize;
    setPaginatedData(
      filteredData
        .filter((item) => {
          if (item.status == DataActiveField) return item;
        })
        .slice(startIndex, endIndex)
    );
  }, [!filteredData]);

  const boxShadowStyle = { boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.5)" };
  const buttonStyle = "font-medium py-2 px-5 rounded-lg shadow-md";
  const [activeButton, setActiveButton] = useState("planning");
  //const { permissions } = useRole();
  // const hasPermission = (permission) => permissions.includes(permission);

  const { permissions } = usePermission();
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/journey") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <div className="flex w-[100%] justify-between items-center max-w-[70vw] overflow-auto min-w-[100%]">
      <div className="min-w-[100%] flex flex-col justify-start items-center overflow-auto">
        <div className="flex w-full justify-start items-center text-2xl font-semibold">
          Journey Planning
        </div>
        <div className="flex justify-center items-center w-[100%] ">
          <div className="flex items-center w-[100%] justify-between gap-5 p-3">
            <div className="flex justify-center items-center">
              <div className="flex items-center gap-5">
                <button
                  className={`${buttonStyle} ${
                    activeButton === "planning"
                      ? "bg-teal text-white"
                      : "bg-gray-200 text-black"
                  }`}
                  style={boxShadowStyle}
                  onClick={() => setActiveButton("planning")}
                >
                  Planning
                </button>
                <button
                  className={`${buttonStyle} ${
                    activeButton === "reports"
                      ? "bg-teal text-white"
                      : "bg-gray-200 text-black"
                  }`}
                  style={boxShadowStyle}
                  onClick={() => setActiveButton("reports")}
                >
                  Reports
                </button>
              </div>
            </div>
            <div className="flex gap-5"> 
              <div className=" flex justify-center items-center relative ">
                <input
                  type="text"
                  className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                  placeholder="Search by Journey Name"
                  name="username"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                  Search
                </div>
                <div className="absolute left-5 text-sm font-bold"></div>
              </div>
              {add && (
                <Link to={"/journeyplan"}>
                  <button className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-3 rounded-bl-3xl btnCancel btn">
                    New Journey
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-center items-start w-full">
          <div className="w-1/2 flex justify-center items-start flex-col mr-4">
            <div className="self-end flex">
              <p className="font-bold text-lg p-1">Status</p>
              <select
                className="shadow-slate-300 z-[100] shadow-md outline-none"
                value={DataActiveField}
                onChange={(e) => {
                  const value = e.target.value === "true";
                  setDataActiveField(value);
                  setCurrentPagination((prev) => ({
                    ...prev,
                    total: data?.filter((x) => x.status != DataActiveField)
                      .length,
                  }));
                }}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            <div className="flex justify-between pl-4 pr-5  rounded-t-lg w-full h-12 items-center shadow-xl ">
              <h1 className="flex justify-center items-center text-black text-2xl font-bold">
                Journeys
              </h1>
              <div className="">
                <Pagination
                  size="small"
                  total={currentPagination?.total}
                  pageSize={currentPagination.pageSize}
                  current={currentPagination.current}
                  onChange={onPageChange}
                  totalBoundaryShowSizeChanger={1}
                />
              </div>
            </div>
            <div className=" w-full overflow-auto max-h-[500px]">
              {/* <Spin
                className="flex justify-center items-center  h-96"
                tip="Loading"
                size="large"
                spinning={loading}
              /> */}
              {loading ? (
                <LoadingSpinner className="mt-10" />
              ) : (
                <ul className="flex justify-center items-start flex-col w-full mt-1">
                  {paginatedData?.map((item, index) => (
                    <li
                      onClick={() => {
                        handleJourneyClick(item);
                        clickedItem(index);
                      }}
                      key={index}
                      className={`w-full h-28 flex justify-center items-center flex-col shadow-xl transition-all duration-500 mb-4 ${
                        clicked === index ? "bg-gray-300" : "bg-[#F7F7F7]"
                      }`}
                    >
                      <div className="flex justify-between items-center w-full pl-5">
                        <p className="font-bold text-lg">{item?.title}</p>
                        <button className="bg-[#1C838A] text-white rounded-3xl px-6 mr-3 py-1">
                          {item.status ? "Active" : "Not active"}
                        </button>
                      </div>
                      <p
                        onClick={JouneyData}
                        className="flex justify-start w-full pl-5"
                      >
                        {`${formatDate(
                          item?.startDate.split("T")[0]
                        )} to ${formatDate(item?.endDate.split("T")[0])}`}
                      </p>
                      <p className="flex justify-start w-full pl-5 items-center">
                        {item?.regionName}{" "}
                        <MdOutlineArrowRightAlt className="mt-1" />{" "}
                        {item?.zoneName}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="w-1/2 flex justify-center items-start ml-4">
            {activeButton === "planning" ? (
              <JourneyDetails data={selectedJourney} />
            ) : (
              <>
                <JourneyReports data={selectedJourney} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
