import React, { useState } from "react";

function DeleteConfirmModal({
  title,
  ModalOpen,
  setModalOpen,
  handleSubmit,
  id,
}) {
  const closeModal = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  return (
    ModalOpen && (
      <div className="z-[100] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-8 shadow-lg w-80 border border-red-600">
          <h2 className="text-lg text-center font-bold mb-4">
            Delete Objective
          </h2>
          <p className="mb-6">
            Are you sure you want to delete the "{title}" objective?
          </p>
          <div className="flex justify-between">
            <button
              onClick={handleSubmit}
              className="bg-red-600 text-white px-8 py-2 rounded-full hover:bg-red-500"
            >
              Delete
            </button>
            <button
              onClick={closeModal}
              className="bg-black text-white px-8 py-2 rounded-full hover:bg-gray-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default DeleteConfirmModal;
