import React, { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

export default function AccordionItem({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-400 w-[1100px]">
      <button
        className="w-full flex justify-between items-center p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl font-semibold">{title}</span>
        {isOpen ? (
          <IoIosArrowUp className="transition ease-in-out" size={20} />
        ) : (
          <IoIosArrowDown className="" size={20} />
        )}
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
}
