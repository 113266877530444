import React, { useState, useEffect, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../services/NetworkServices";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

const JourneyPlan = ({
  visitData,
  setVisitData,
  setZoneTitle,
  setSelectedValues,
  zoneId,
  setZoneId,
  selectedValues,
  setRegionId,
  regionId,
  edit,
  userId,
  setuserId,
}) => {

  const navigate = useNavigate();
  let { id } = useParams();
  const [visitorData, setVisitorData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [filteredZoneData, setFilteredZoneData] = useState([]);
  const [filteredVisitorData, setFilteredVisitorData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDropdown, setShowDropdown] = useState({
    startDate: false,
    endDate: false,
    region: false,
    zone: false,
    visitor: false,
  });

  const dropdownRefs = {
    startDate: useRef(null),
    endDate: useRef(null),
    region: useRef(null),
    zone: useRef(null),
    visitor: useRef(null),
  };

  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);

  useEffect(() => {
    getData("User-Data/GetVisitorsforJC")
      .then((response) => {
        if (response?.status === 200) {
          const sortedVisitorData = response?.data?.data.sort((a, b) => {
            return a.fullName.localeCompare(b.fullName);
          });
          setVisitorData(sortedVisitorData);
          setFilteredVisitorData(sortedVisitorData);
        }
      })
      .catch((error) => console.error("Error fetching visitors:", error));
  }, []);

  useEffect(() => {
    if (edit) setSelectedUserId(userId);
  }, [userId]);

  useEffect(() => {
    if (regionId) {
      getData(`Zone/zones/${regionId}`)
        .then((res) => {
          setZoneData(res?.data);
          setFilteredZoneData(res?.data);
        })
        .catch((error) => console.error("Error fetching zones:", error));
    }
  }, [regionId]);

  const SubmitJourney = async (e) => {
    e.preventDefault();

    const date = new Date(selectedValues.startDate);
    const startDate = dayjs(date).format("YYYY-MMM-DD");
    const lastDate = new Date(selectedValues.endDate);
    const endDate = dayjs(lastDate).format("YYYY-MMM-DD");

    const data = edit
      ? {
          journeyMasterID: Number(id.split("-")[0]),
          title: selectedValues.journeyTitle,
          startDate: startDate,
          endDate: endDate,
          regionId: selectedRegionId,
          zoneId: selectedZoneId,
          areaId: 79, //Needs to remove the hard coded value ASAP
          details: visitData,
        }
      : {
          title: selectedValues.journeyTitle,
          startDate: startDate,
          endDate: endDate,
          regionId: selectedRegionId,
          zoneId: selectedZoneId,
          areaId: 79,
          details: visitData,
        };

    if (
      !data.title ||
      !data.startDate ||
      !data.endDate ||
      !data.regionId ||
      !data.zoneId ||
      data.details.length === 0
    ) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const jsonData = JSON.stringify(data);
      let endPoint = edit
        ? "Journey/EditJourneyCycle"
        : "Journey/AddJourneyCycle";
      const res = await postData(endPoint, jsonData, {
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      navigate("/journey");
      console.log("Successfully submitted", res);
    } catch (error) {
      console.log("Error submitting journey", error);
    }
  };

  const handleClickOutside = (e) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(e.target)
      ) {
        setShowDropdown((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isFormValid = () => {
    return (
      selectedValues.journeyTitle !== null &&
      selectedValues.startDate !== null &&
      selectedValues.endDate !== null &&
      selectedRegionId !== null &&
      selectedZoneId !== null &&
      visitData.length > 0
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
  };

 

  return (
    <div
      className={
        "w-full flex justify-center items-center border-2 border-r-black-300 shadow shadow-gray-300 `app-container ${!isFormValid() ? 'blurred' : ''}`"
      }
    >
      <form
        className="w-[390px] bg-white flex flex-col gap-6 rounded-xl p-6"
        onSubmit={SubmitJourney}
      >
        <div className="text-center text-3xl font-semibold p-2">
          <p>Journey Details</p>
        </div>
          <div className="relative w-full">
            <input
              autoComplete="off"
              className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full"
              type="text"
              name="journeyTitle"
              value={selectedValues.journeyTitle}
              onChange={handleChange}
            />
            <p className="absolute bottom-8 left-8 bg-white font-bold">
              Journey Title
            </p>
          </div>
        <div className="relative w-full">
          <DatePicker
            id="DatePicker"
            autoComplete="off"
            selected={selectedValues.startDate}
            value={selectedValues.startDate}
            onChange={(date) => {
              setSelectedValues((prevState) => ({
                ...prevState,
                startDate: date,
              }));

              if (date > selectedValues.endDate) {
                setSelectedValues((prevState) => ({
                  ...prevState,
                  endDate: null,
                }));
              }
            }}
            className="outline-red-200 py-3 px-6 w-[350px] border-2 border-red-200 rounded-full relative hover:border-red-200 bg-transparent"
            placeholderText="Select the Date"
            name="startDate"
          />
          <FaCalendarAlt
            className=" text-black absolute bottom-3 right-4 text-xl cursor-pointer"
            onClick={(e) => document.getElementById("DatePicker").click()}
          />
          <p className="absolute bottom-10 left-8 font-bold bg-white">
            Start Date
          </p>
        </div>
        <div className="relative w-full" ref={dropdownRefs.endDate}>
          <DatePicker
            id="EndDate"
            autoComplete="off"
            selected={selectedValues.endDate}
            value={selectedValues.endDate}
            onChange={(lastDate) =>
              setSelectedValues((prevState) => ({
                ...prevState,
                endDate: lastDate,
              }))
            }
            name="endDate"
            className="outline-red-200 py-3 px-6 w-[350px] border-2 border-red-200 rounded-full relative"
            placeholderText="Select the Date"
            disabledDate={(current) =>
              current && current < new Date(selectedValues.startDate)
            }
          />
          <FaCalendarAlt
            className="absolute bottom-3 right-4 text-xl cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("EndDate").click();
            }}
          />
          <p className="absolute bottom-10 left-8 font-bold bg-white">
            End Date
          </p>
        </div>
    
        <div className="relative w-full" >
    <Select
  // mode="multiple"
    showSearch
    className="w-full"
    value={selectedValues.visitor}
    placeholder="Select the visitor"
    onChange={(value, option) => {
      const selectedVisitor = filteredVisitorData.find(item => item.id.toString() === option.key);
      
      if (selectedVisitor) { // Check if selectedVisitor is defined
        setSelectedValues((prev) => ({
          ...prev,
          visitor: selectedVisitor.username,
        }));

        const dataa = [...visitData];
        dataa.forEach(data => {
          data.journeyUsersDtos.forEach(user => {
            user.userInfoId = selectedVisitor.id;
            user.userName = selectedVisitor.fullName;
          });
        });

        setShowDropdown((prev) => ({
          ...prev,
          visitor: false,
        }));

        setSelectedRegionId(selectedVisitor?.regions[0]?.id);
        setSelectedZoneId(selectedVisitor?.zones[0]?.id);
        setSelectedUserId(selectedVisitor.id);
        setuserId(selectedVisitor.id);

        if (setRegionId) {
          setRegionId(selectedVisitor.regions[0]?.id);
        }
        if (setZoneId) {
          setZoneId(selectedVisitor.zones[0]?.id);
        }
      }
      else{
        setSelectedValues((prev) => ({
          ...prev,
          visitor: "",
        }));
      }
    }}
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
  >
    {filteredVisitorData.map((item) => (
      <Select.Option key={item.id} value={item.username}>
        {item.fullName}
      </Select.Option>
    ))}
  </Select>
  <p className="absolute bottom-10 left-8 font-bold bg-white">Visitor</p>
</div>
        <div className="flex justify-center items-center pb-6 gap-10">
          <button
            id="btnSubmit"
            type="submit"
            className={`btnSubmit btn w-3.5 ${!isFormValid() ? "hidden" : ""}`}
            disabled={!isFormValid()}
          >
            {edit ? "Edit Journey" : "Add Journey"}{" "}
          </button>
          <Link to={"/journey"}>
            <button
              type="button"
              className="btnCancel btn"
              onClick={() => console.log("Cancel")}
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default JourneyPlan;
