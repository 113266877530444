import React, { useEffect, useState, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { getData } from "../../services/NetworkServices";
import { Button, DatePicker, message, Select } from "antd";
import dayjs from "dayjs";

const JourneyPlanNewVisitPopUp = ({
  pop,
  setPop,
  addVisit,
  zoneId,
  zoneTitle,
  selectedValues,
  userId,
}) => {


  const [areaData, setAreaData] = useState([]);
  const [beltData, setBeltData] = useState([]);
  const [townData, setTownData] = useState([]);
  const [localityData, setLocalityData] = useState([]);
  const [subLocalityData, setSubLocalityData] = useState([]);
  const [objectiveData, setObjectiveData] = useState([]);
  const [businessData, setBuisnessData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [filterAreaData, setFilterAreaData] = useState([]);
  const [filterBeltData, setFilterBeltData] = useState([]);
  const [filterTownData, setFilterTownData] = useState([]);
  const [filterLocalityData, setFilterLocalityData] = useState([]);
  const [filterSubLocalityData, setFilterSubLocalityData] = useState([]);
  const [filterObjectiveData, setFilterObjectiveData] = useState([]);
  const [filterbusinessData, setFilterbusinessData] = useState([]);
  const [filterMasterData, setFilterMasterData] = useState([]);
  const [showDropdown, setShowDropdown] = useState({
    town: false,
    area: false,
    belt: false,
    locality: false,
    master: false,
    subLocality: false,
    businessUnit: false,
    objective: false,
  });

  const [selectedTown, setSelectedTown] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedBelt, setSelectedBelt] = useState("");
  const [selectedLocality, setSelectedLocality] = useState("");
  const [selectedSubLocality, setSelectedSubLocality] = useState("");
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [selectedObjective, setSelectedObjective] = useState("");
  const [listOfAllSteps, setListOfAllSteps] = useState([]);
  const [journeyVisitDate, setJourneyVisitDate] = useState();
  const [description, setDescription] = useState("");
  const [areaId, setAreaId] = useState(null);
  const [beltId, setBeltId] = useState(null);
  const [townId, setTownId] = useState(null);
  const [localityId, setLocalityId] = useState(null);
  const [subLocalityId, setSubLocalityId] = useState(null);
  const [businessUnitID, setBusinessUnitID] = useState(null);
  const [objectiveID, setObjectiveID] = useState(null);

  const [selectedMaster, setSelectedMaster] = useState("");
  const [masterRecordTypeID, setMasterRecordTypeID] = useState(null);
  const [popUp, setPopUp] = useState(pop);
  const [requiredStep, setRequiredStep] = useState([]);
  const dropdownRefs = {
    town: useRef(null),
    area: useRef(null),
    belt: useRef(null),
    locality: useRef(null),
    subLocality: useRef(null),
    master: useRef(null),
    businessUnit: useRef(null),
    objective: useRef(null),
  };
  // Fetch data from the API
  useEffect(() => {
    if (zoneId) {
      getData(`Area/areas/${zoneId}`).then((response) => {
        const sortedAreaData = response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setAreaData(sortedAreaData);
        setFilterAreaData(sortedAreaData);
      });
    }
  }, [zoneId]);

  // Fetch data from the API
  useEffect(() => {
    if (areaId) {
      getData(`Belt/belts/${areaId}`).then((response) => {
        const sortedBeltData = response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setBeltData(sortedBeltData);
        setFilterBeltData(sortedBeltData);
      });
    }
  }, [areaId]);

  // Fetch data from the API
  useEffect(() => {
    if (beltId) {
      getData(`Town/towns/${beltId}`).then((response) => {
        const sortedTownData = response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setTownData(sortedTownData);
        setFilterTownData(sortedTownData);
      });
    }
  }, [beltId]);

  // Fetch data from the API
  useEffect(() => {
    if (townId) {
      getData(`Locality/localities/${townId}`).then((response) => {
        const sortedLocalityData = response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setLocalityData(sortedLocalityData);
        setFilterLocalityData(sortedLocalityData);
      });
    }
  }, [townId]);

  // Fetch data from the API
  useEffect(() => {
    if (localityId) {
      getData(`SubLocality/subLocalities/${localityId}`).then((response) => {
        const sortedSubLocalityData = response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        setSubLocalityData(sortedSubLocalityData);
        setFilterSubLocalityData(sortedSubLocalityData);
      });
    }
  }, [localityId]);

  useEffect(() => {
    getData(`master-record-type`).then((response) => {
      const sortedMasterData = response.data?.data?.sort((a, b) => {
        return a?.title?.localeCompare(b?.title);
      });
      setMasterData(sortedMasterData);
      setFilterMasterData(sortedMasterData);
    });
  }, []);

  useEffect(() => {
    if (subLocalityId) {
      getData(
        `BusinessUnits/GetBusinessUnitBySubLocality/${subLocalityId}`
      ).then((response) => {
        const sortedBusinessUnitData = response.data?.data?.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setBuisnessData(sortedBusinessUnitData);
        setFilterbusinessData(sortedBusinessUnitData);
      });
    }
  }, [subLocalityId]);

  useEffect(() => {
    getData("Objective").then((response) => {
      const sortedObjectiveData = response.data.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
      setObjectiveData(sortedObjectiveData);
      setFilterObjectiveData(sortedObjectiveData);
    });
  }, []);

  useEffect(() => {
    getData("Journey/ListOfAllSteps")
      .then((response) => {
        if (response.status === 200) {
          setStepData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);


  

  const handleAddVisit = () => {
    const date = new Date(journeyVisitDate.$d);

    // Format the date to dd-mm-yyyy
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    const newVisit = {
      visitDate: formattedDate,
      masterRecordTypeID: masterRecordTypeID,
      businessUnitID: businessUnitID,
      masterRecordType: selectedMaster,
      subLocalityId: subLocalityId,
      objectiveID: objectiveID,
      steps: listOfAllSteps,
      
      area: selectedArea,
      town: selectedTown,
      belt: selectedBelt,
      locality: selectedLocality,
      subLocality: selectedSubLocality,
      businessUnit: selectedBusinessUnit,
      objective: selectedObjective,
      // description: description,

      qtMaster_Id: null,
      journeyUsersDtos: [
        {
          userInfoId: userId,
        },
      ],
      journeyStepsDtos: requiredStep,
      // [
      //   {
      //     journeyStepID: 2,
      //     isRequired: true,
      //     stepID: 0,
      //     journeyMasterId:0 ,
      //     journeyDetailId: 0,
      //     stepName: "Take Picture",
      //   }
      // ]
    };

    addVisit(newVisit);
  };

  const handleCheckboxChange = (stepID) => {
    setListOfAllSteps((prevSteps) => {
      const isAlreadyChecked = prevSteps?.includes(stepID);

      let updatedSteps;
      if (isAlreadyChecked) {
        updatedSteps = prevSteps?.filter((id) => id !== stepID);
      } else {
        updatedSteps = [...prevSteps, stepID];
      }

      // Update requiredSteps based on the updated list
      const updatedRequiredSteps = updatedSteps.map((id) => ({
        stepID: id,
        isRequired: true,
      }));

      setRequiredStep(updatedRequiredSteps);
      return updatedSteps;
    });
  };
  return (
    <div className="w-full h-[730px] flex justify-center items-center fixed inset-0 bg-blend-darken backdrop-brightness-50">
      <div className=" w-[680px] bg-white flex flex-col gap-6 rounded-xl p-4">
        <div className="text-center text-3xl font-semibold p-2">
          <p>New Visit</p>
        </div>

        <div className="flex gap-10 w-full ">
          {/* Journey Visit Date */}
          <div className="relative w-full ">
            <DatePicker
              selected={journeyVisitDate}
              id="journeyVisitDate"
              autoComplete="off"
              onChange={(date) => setJourneyVisitDate(date)}
              className="outline-red-200 py-3 px-6 w-[300px] border-2 border-red-200 rounded-full"
              placeholder="Select the Date"
              disabledDate={(current) =>
                current &&
                ((selectedValues.startDate
                  ? current < new Date(selectedValues.startDate)
                  : false) ||
                  (selectedValues.endDate
                    ? current > new Date(selectedValues.endDate)
                    : false))
              }
            />
           
          </div>

          {/* Area Selection */}
          <div className="relative w-full">
            <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Area</p>
          <Select
      showSearch
  placeholder="Select the Area"
  value={selectedArea} 
  onChange={(value, option) => {
    const selectedArea = filterAreaData.find((item) => item.id === value); 

    setSelectedArea(selectedArea?.title); 
    setAreaId(value);  

    if (!value) {
      setSelectedBelt("");
      setBeltId(null);
      setSelectedTown("");
      setTownId(null);
      setSelectedLocality("");
      setLocalityId(null);
      setSelectedSubLocality("");
      setSubLocalityId(null);
      setSelectedBusinessUnit("");
      setBusinessUnitID(null);
    }
  }}
  filterOption={(input, option) => {
    const label = option.label || '';
    return label.toLowerCase().includes(input.toLowerCase());
  }}
  disabled={!zoneId}  // Disable if no zone is selected
>

  {(filterAreaData || []).map((item) => (
    <Select.Option key={item.id} value={item.id} label={item.title}>  {/* Set item.id as the value */}
      {item.title}  {/* Display the title in the dropdown */}
    </Select.Option>
  ))}
</Select>

          </div>
        </div>

        {/* Belt Selection */}
        <div className="flex gap-10">
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Belt</p>

            <Select
              showSearch
              placeholder="Select the Belt"
              value={selectedBelt}
              onChange={(value,option) => {
                const selectedBelt = filterBeltData.find((item) => item.id === value); 
                setSelectedBelt(selectedBelt.title);
                setBeltId(value);
                // Reset dependent fields after selecting a new belt
                setSelectedTown("");
                setTownId(null);
                setSelectedLocality("");
                setLocalityId(null);
                setSelectedSubLocality("");
                setSubLocalityId(null);
                setSelectedBusinessUnit("");
                setBusinessUnitID(null);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={!selectedArea}
            >
              {(filterBeltData || []).map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>

          {/* Town Selection */}
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Town</p>

            <Select
              showSearch
              placeholder="Select the Town"
              value={selectedTown}
              onChange={(value,option) => {
                const selectedTown = filterTownData.find((item) => item.id === value); 
                setSelectedTown(selectedTown.title);
                setTownId(value);
                // Reset dependent fields
                setSelectedLocality("");
                setLocalityId(null);
                setSelectedSubLocality("");
                setSubLocalityId(null);
                setSelectedBusinessUnit("");
                setBusinessUnitID(null);
              }}
              filterOption={(input, option) => {
                // Ensure option.label is a string
                const label = option.label || '';
                return label.toLowerCase().includes(input.toLowerCase());
              }}
              disabled={!selectedBelt}
            >
              {(filterTownData  || []).map((item) => (
                <Select.Option key={item.id} value={item.id} label={item.title}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        {/* Locality Selection */}
        <div className="flex gap-10">
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Locality</p>

            <Select
              showSearch
              placeholder="Select the Locality"
              value={selectedLocality}
              onChange={(value, option) => {
                const selectedLocality = filterLocalityData.find((item) => item.id === value); 

                setSelectedLocality(selectedLocality?.title);
                setLocalityId(value);
                // Reset dependent fields
                setSelectedSubLocality("");
                setSubLocalityId(null);
                setSelectedBusinessUnit("");
                setBusinessUnitID(null);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={!selectedTown}
            >
              {(filterLocalityData|| []).map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>

          {/* Sub-Locality Selection */}
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Sub Locality</p>

            <Select
              showSearch
              placeholder="Select the Sub-Locality"
              value={selectedSubLocality}
              onChange={(value,option) => {
                const selectedSubLocality = filterSubLocalityData.find((item) => item.id === value); 
                setSelectedSubLocality(selectedSubLocality.title);
                setSubLocalityId(value);
                // Reset Business Unit after selecting a new sub-locality
                setSelectedBusinessUnit("");
                setBusinessUnitID(null);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={!selectedLocality}
            >
              {(filterSubLocalityData || []).map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        {/* Business Unit Selection */}
        <div className="flex gap-10">
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Business Unit</p>

            <Select
              showSearch
              placeholder="Select the Business Unit"
              value={selectedBusinessUnit}
              onChange={(value,option) => {
                const selectedBusinessUnit = filterbusinessData.find((item) => item.id === value); 
                setSelectedBusinessUnit(selectedBusinessUnit.title);
                setBusinessUnitID(value);
              }}  filterOption={(input, option) => {
                // Ensure option.label is a string
                const label = option.label || '';
                return label.toLowerCase().includes(input.toLowerCase());
              }}
              disabled={!selectedSubLocality}
            >
              {(filterbusinessData || []).map((item) => (
                <Select.Option key={item.id} value={item.id} label={item.title}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>

          {/* Record Type Selection */}
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Record Type</p>

            <Select
              showSearch
              placeholder={"Select the Record Type"}
              value={selectedMaster}
              onChange={(value,option) => {
                const selectedMaster = filterMasterData.find((item) => item.id === value); 
                setSelectedMaster(selectedMaster?.name);
                setMasterRecordTypeID(value);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {(filterMasterData || []).map((item) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex gap-10">
          {/* Objective Selection */}
          <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Objective</p>

            <Select
              showSearch
              placeholder="Select the Objective"
              value={selectedObjective}
              onChange={(value) => {
                
                const selectedObjective = filterObjectiveData.find((item) => item.id === value); 

                setSelectedObjective(selectedObjective?.title);
                setObjectiveID(value);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {(filterObjectiveData || []).map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="relative w-full">
          <textarea
            name="textArea"
            className="outline-red-200 py-6 px-10 w-full border-2 border-red-200 rounded-3xl relative resize-none"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <p className="absolute bottom-24 left-14 font-bold bg-white">
            Description
          </p>
        </div>
        {/* steps starts here */}
        <div className="flex justify-center items-center gap-4 flex-wrap w-[600px]">
      {stepData.map((item) => (
    <div className="flex flex-col gap-4" key={item.stepID}>
      <div className="form-control flex gap-4">
        <label className="label cursor-pointer w-[200px]">
          <input
            type="checkbox"
            name="steps"
            checked={listOfAllSteps.includes(item.stepID)} // Check if the stepID is in the list
            onChange={() => handleCheckboxChange(item.stepID)}
            className="checkbox checkbox-red"
            value={item.stepID}
          />
          <span className="label-text pl-5">{item.stepName}</span>
        </label>
      </div>
    </div>
  ))}
</div>

        <div className="flex justify-center items-center w-full  pb-4 gap-10">
          
          <button
          
            disabled={
              !journeyVisitDate ||
              !selectedArea ||
              !selectedBelt ||
              !selectedTown ||
              !selectedLocality ||
              !selectedSubLocality ||
              !selectedMaster ||
              !selectedBusinessUnit ||
              !selectedObjective
            }
            onClick={()=>{
              handleAddVisit()
              setPop(true)
              setSelectedBusinessUnit("")
              setListOfAllSteps([])
              setRequiredStep([])
              message.success('Visit added successfully!'); 
            }}
            className="btn btnSubmit"
            style={{width:"200px"}}
             >
            Add And Continue
          </button>
          <button
            disabled={
              !journeyVisitDate ||
              !selectedArea ||
              !selectedBelt ||
              !selectedTown ||
              !selectedLocality ||
              !selectedSubLocality ||
              // !selectedMaster ||
              !selectedBusinessUnit ||
              !selectedObjective
            }
            onClick={handleAddVisit}
            className="btnSubmit btn w-3.5"
          >
            Add Visit
          </button>
          <button onClick={() => setPop(false)} className="btnCancel btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default JourneyPlanNewVisitPopUp;
