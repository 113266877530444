import React, { useState } from 'react'
import UserTracking from '../UserTracking/UserTracking';
import AttendanceUser from '../components/userManagement/AttendenceUser';

function UserTrackingPage() {
    const [activeButton, setActiveButton] = useState("Map");

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };
  return (
    <>
      <div className="w-[50%]">
                    <div className="flex justify-center gap-10 ">
                        <button
                            className={`shadow-lg rounded-md py-2 px-12 ${activeButton === "Map" ? "bg-[#16A085] text-white" : "bg-[#E5E8E8]"}`}
                            onClick={() => handleButtonClick("Map")}
                        >
                            Map
                        </button>
                        <button
                            className={`shadow-lg rounded-md py-2 px-5 ${activeButton === "Attendance" ? "bg-[#16A085] text-white" : "bg-[#E5E8E8]"}`}
                            onClick={() => handleButtonClick("Attendance")}
                        >
                            Attendance
                        </button>
                    </div>
                </div>
                {activeButton === "Map" && (<UserTracking/> )}

{activeButton === "Attendance" && 
<AttendanceUser 
/>}
    </>
  )
}

export default UserTrackingPage
