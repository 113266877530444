import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { getData, getDataForPermission, postData } from "../../services/NetworkServices"; // Import postData
import { message } from "antd";
import { usePermission } from "../../PermissionContext";
function RolePermissions({token}) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [visitorData, setVisitorData] = useState([]);
  const [filteredVisitorData, setFilteredVisitorData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [data, setData] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const { permissions, setPermissions } = usePermission();
  // const token = localStorage.getItem("token");

  useEffect(() => {
    getDataForPermission(`User-Data/roles`, token)
      .then((res) => {
        const sortedRoles = res.data?.data?.sort((a, b) => {
          return a?.name?.localeCompare(b?.name);
        });
        setData(sortedRoles);
        console.log("dataaaa", data);
      })
      .catch((err) => console.log("error fetching", err));
  }, [token]);

  useEffect(() => {
    if (selectedUserId) {
      getDataForPermission(`UserPermission/role-accesses/${selectedUserId}`,token)
        .then((res) => {
          setPermissionData(res.data.data);
          const roleId = localStorage.getItem("roleId");
          if (roleId === res?.data?.data[0]?.roleId) {
            setPermissions(res?.data?.data);
          }
          const states = res.data.data.map((item) => ({
            canAdd: item.canAdd,
            canEdit: item.canEdit,
            canView: item.canView,
            canDelete: item.canDelete,
          }));
          setCheckedStates(states);
        })
        .catch((err) => console.log("error fetching permissions", err));
    }
  }, [selectedUserId, token]);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (index, field) => {
    const updatedStates = [...checkedStates];
    updatedStates[index][field] = !updatedStates[index][field];
    setCheckedStates(updatedStates);

    const updatedPermissionData = [...permissionData];
    updatedPermissionData[index][field] = updatedStates[index][field];
    setPermissionData(updatedPermissionData);
  };

  const handleSave = () => {
    const payload = {
      roleId: selectedUserId,
      detailDto: permissionData.map((item) => ({
        pageId: item.pageId,
        canView: item.canView,
        canAdd: item.canAdd,
        canEdit: item.canEdit,
        canDelete: item.canDelete,
        canFly: item.canFly,
      })),
    };

    postData(`UserPermission/role-accesses`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Permissions updated successfully:", response.data);
        message.success("Permissions updated successfully");
      })
      .catch((error) => {
        console.log("Error updating permissions:", error);
        message.error("Error updating permissions. Please try again.");
      });
  };

  const dropdownRef = useRef(null);

  const fields = ["Page", "View", "Add", "Edit", "Delete"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value || value === "") {
      setShowMessage(false);
    }
    setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div className="relative w-96 pb-1" ref={dropdownRef}>
        <input
          autoComplete="off"
          className="outline-red-200 py-2 px-6 w-full border-2 mt-16 border-red-200 rounded-full"
          type="text"
          placeholder="Select the role"
          value={selectedValues.rolename || ""}
          onChange={(e) => {
            handleChange(e);
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        />
        <IoIosArrowDropdownCircle className="absolute bottom-4 right-4 text-xl" />
        <p className="absolute bottom-8 left-8 font-bold bg-white">Role</p>
        {showDropdown && (
          <div className="absolute z-10 w-full max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
            {data?.map((item) => (
              <div
                key={item.id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  setSelectedValues({ rolename: item.name });
                  setShowDropdown(false);
                  setSelectedUserId(item.id);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-12">
        <table className="border-2 border-gray-700 rounded-2xl w-[95%] mx-auto text-left capitalize">
          <tbody>
            <tr className="">
              {fields?.map((field) => (
                <th className="font-bold pt-8 pb-4 px-2" key={field}>
                  {field}
                </th>
              ))}
            </tr>

            {permissionData?.map((item, index) => (
              <tr className="odd:bg-white even:bg-gray-100" key={item.pageId}>
                <td className="p-2">{item.pageTitle}</td>
                {["canView", "canAdd", "canEdit", "canDelete"].map((field) => (
                  <td className="p-2" key={field}>
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedStates[index]?.[field] || false}
                      onChange={() => handleCheckboxChange(index, field)}
                    />
                    <span className="pl-2">
                      {checkedStates[index]?.[field] ? "Yes" : "No"}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button
            onClick={handleSave}
            className="float-end items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-4 mr-6 mt-5 py-2 rounded-bl-3xl btnSubmit btn"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default RolePermissions;
