import React, { useEffect, useState } from "react";
import { getData } from "../../services/NetworkServices";
import { useNavigate, useLocation } from "react-router-dom";
import Accordion from "./Accordion";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import VisitReportPDF from "./VisitReportPDF";
import { pdf } from "@react-pdf/renderer";

export default function VisitReport() {
  const location = useLocation();
  const { id } = location.state || {};
  const [data, setData] = useState(null);
  useEffect(() => {
    getData(`Journey/ViewJourneyResponse/` + id)
      .then((res) => {
        setData(res.data.data);
        console.log("res", res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
        // setError(true);
      });
  }, [id]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("../../Journey");
  };

  // const exportToPDF = async () => {
  //   const element = document.querySelector(".min-h-screen");

  //   const images = element.querySelectorAll("img");
  //   const imageLoadPromises = Array.from(images).map((img) => {
  //     return new Promise((resolve, reject) => {
  //       if (img.complete) {
  //         resolve();
  //       } else {
  //         img.onload = resolve;
  //         img.onerror = reject;
  //       }
  //     });
  //   });

  //   try {
  //     await Promise.all(imageLoadPromises);

  //     const canvas = await html2canvas(element, {
  //       scale: 2,
  //       useCORS: true,
  //       allowTaint: true,
  //     });

  //     const imgData = canvas.toDataURL("image/png");

  //     const pdfWidth = canvas.width * 0.65; // Adjust this value for more width
  //     const pdfHeight = canvas.height * 0.9;

  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "px",
  //       format: [pdfWidth, pdfHeight],
  //     });

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("VisitReport.pdf");
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  const exportToPDF = async (data) => {
    console.log("in vis repo", data);
    const blob = await pdf(<VisitReportPDF values={data} />).toBlob();
    var url = window.URL.createObjectURL(blob);
    const a = document.createElement("A");
    a.href = url;
    a.download = url.substr(url.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <div>
        <h1 className="font-bold text-2xl ml-2 mt-7">Visit Report</h1>
        <button
          className="items-center text-lg text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 mt-3 ml-4 mb-2 rounded-bl-3xl bg-black"
          onClick={handleClick}
        >
          Back
        </button>
        <button
          className="float-end items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 mr-6 py-2 rounded-bl-3xl btnSubmit btn"
          onClick={() => exportToPDF(data)}
        >
          Export to PDF
        </button>
      </div>

      <div className="min-h-screen bg-white flex justify-center w-full p-4">
        <Accordion />
      </div>
    </>
  );
}
