import React, { useState, useRef, useEffect } from "react";
import { getData } from "../../services/NetworkServices";
import { FaMagnifyingGlass, FaAngleUp, FaAngleDown } from "react-icons/fa6";
import { Pagination } from "antd";
import { DatePicker } from "antd";
import { FaCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";

function Report() {
  const [selectedValues, setSelectedValues] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [reportData, setReportData] = useState([]);
  const dropdownRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [originalData, setOriginalData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedDates, setSelectedDates] = useState({
    fromDate: dayjs().subtract(1, "day"),
    toDate: dayjs().subtract(1, "day"),
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value === "") {
      setReportData(originalData); // reset to original data when search field is cleared
    } else {
      const filteredData = originalData.filter((item) => {
        return (
          item.materialCode.toLowerCase().includes(value.toLowerCase()) ||
          item.materialDesc.toLowerCase().includes(value.toLowerCase())
        );
      });
      setReportData(filteredData);
    }
  };

  const paginatedData = reportData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    getData("Stock/stock-in-hands").then((res) => {
      setReportData(res.data.data);
      setOriginalData(res.data.data); // store original data for search functionality
      console.log("Stock/stock-in-hands", res.data.data);
    });
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const storedZones = localStorage.getItem("zones");
  // Convert the JSON string back to an array
  const zonesArray = storedZones ? JSON.parse(storedZones) : [];
  let sortedZoness = zonesArray.sort((a, b) => a.title.localeCompare(b.title));

  const dropdownRefs = {
    // ... existing refs ...
    fromDate: useRef(null),
    toDate: useRef(null),
  };

  return (
    <>
      <div className="flex justify-between mt-0 h-16  items-center w-full ">
        <div className="">
          <h1 className="font-semibold text-3xl ml-8">Stock in-hand</h1>
        </div>
        <div
          className="relative w-60 bottom-10 mt-8 right-80"
          ref={dropdownRef}
        >
          <input
            autoComplete="off"
            className="outline-red-200 py-2 px-6 border-2 mt-44 border-red-400 rounded-full w-60"
            type="text"
            placeholder="Select the Zone"
            value={selectedValues.zonename || ""}
            onChange={(e) => handleChange(e)}
            onClick={() => setShowDropdown(!showDropdown)}
          />
          {showDropdown ? (
            <FaAngleUp
              className="absolute bottom-3 left-52 text-xl cursor-pointer"
              onClick={() => setShowDropdown(!showDropdown)}
            />
          ) : (
            <FaAngleDown
              className="absolute bottom-3 left-52 text-xl cursor-pointer"
              onClick={() => setShowDropdown(!showDropdown)}
            />
          )}
          <p className="absolute bottom-8 left-8 font-bold bg-white">Zone</p>

          {showDropdown && (
            <div className="absolute z-10 w-60 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
              {sortedZoness?.map((item) => (
                <div
                  key={item.id}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    setSelectedValues({ zonename: item.title });
                    setShowDropdown(false);
                    setSelectedUserId(item.id);
                  }}
                >
                  {item.title}
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="relative w-[30px] right-[400px] top-16"
          ref={dropdownRefs.fromDate}
        >
          <DatePicker
            id="fromDate"
            autoComplete="off"
            selected={selectedDates.fromDate}
            value={selectedDates.fromDate}
            onChange={(date) => {
              setSelectedDates((prevState) => ({
                ...prevState,
                fromDate: date,
              }));

              if (date > selectedDates.toDate) {
                setSelectedDates((prevState) => ({
                  ...prevState,
                  toDate: null,
                }));
              }
            }}
            className="outline-red-200 py-2 px-6 w-[200px] border-2 border-red-400 rounded-full relative hover:border-red-400 bg-transparent"
            placeholderText="Select the Date"
            name="fromDate"
            disabledDate={(current) => current > dayjs()}
          />
          {/* <FaCalendarAlt
            className=" text-black absolute bottom-3 right-4 text-xl cursor-pointer"
            onClick={(e) => document.getElementById("fromDate").click()}
          /> */}
          <p className="absolute bottom-8 left-8 font-bold bg-white">From</p>
        </div>
        <div
          className="relative w-[30px] right-[310px] top-16"
          ref={dropdownRefs.toDate}
        >
          <DatePicker
            id="toDate"
            autoComplete="off"
            selected={selectedDates.toDate}
            value={selectedDates.toDate}
            onChange={(date) =>
              setSelectedDates((prevState) => ({
                ...prevState,
                toDate: date,
              }))
            }
            name="toDate"
            className="outline-red-400 py-2 px-6 w-[200px] border-2 border-red-400 rounded-full relative"
            placeholderText="Select the Date"
            disabledDate={(current) =>
              current < selectedDates.fromDate || current > dayjs()
            }
          />
          {/* <FaCalendarAlt
            className="absolute bottom-3 right-4 text-xl cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("toDate").click();
            }}
          /> */}
          <p className="absolute bottom-8 left-8 font-bold bg-white">To</p>
        </div>

        <div className="flex justify-center items-center w-[300px] ">
          <div className="flex justify-center items-center relative w-96">
            <input
              type="text"
              className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
              name="username"
              value={searchTerm}
              onChange={handleChange}
              //value={codesearch}
              //onChange={(e) => setcodesearch(e.target.value)}
            />
            <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
              Search
            </div>
            <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[80vw] h-[60vh] flex justify-center items-start border border-black rounded-3xl overflow-auto mt-20">
        <div className="flex flex-col w-full max-w-full">
          {/* Table Header */}
          <div className="w-full static">
            <ul className="flex justify-start items-center min-w-max bg-[#F7F7F7] rounded-t-2xl h-12 border-b border-black">
              {[
                "Material Code",
                "Material Description",
                "Batch Code",
                "Created On",
                "Zone Name",
                "Auto-DA",
                "GIN",
                "GRN",
                "SAN",
                "Opening Stock",
                "Closing Stock",
              ].map((heading, index) => (
                <li
                  key={index}
                  className="w-[150px] flex justify-center items-center font-semibold"
                >
                  {heading}
                </li>
              ))}
            </ul>
          </div>

          {/* Table Body with Scroll */}
          <div className="w-full h-[300px]">
            {paginatedData.length === 0 ? (
              <p className="flex justify-center items-center w-full text-red-600">
                No data available
              </p>
            ) : (
              <div className="min-w-max">
                {paginatedData.map((item, index) => (
                  <ul
                    key={index}
                    className={`flex justify-start items-center w-full h-14 ${
                      index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                    } transition-transform duration-200 ease-in-out`}
                  >
                    <li className="w-[150px] flex justify-center items-center">
                      {item.materialCode}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.materialDesc}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.batchCode}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {dayjs(item.createdOn).format("YYYY-MM-DD")}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.zoneName}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.autoDa_Qty}
                    </li>
                    <li className="w-[150px] flex justify-center items-center text-sm">
                      {item.giN_Qty}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.grN_Qty}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.saN_Qty}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.openingStock || "N/A"}
                    </li>
                    <li className="w-[150px] flex justify-center items-center">
                      {item.closingStock || "N/A"}
                    </li>
                  </ul>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-start items-center mt-4  pt-1 text-center">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={reportData.length}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
        />
      </div>
    </>
  );
}

export default Report;
