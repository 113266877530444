import React from "react";

function TabsHeader({ category, setCategory }) {
  //Using variables for recurring classnames for each tabs.
  const boxShadowStyle = { boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.5)" };
  const buttonStyle = "font-medium py-2 px-5 rounded-lg shadow-md";

  //Check if button is selected then return teal button with white text else gray button with black txt
  const buttonSelected = (buttonCategory) => {
    return category == buttonCategory
      ? "bg-teal text-white "
      : "bg-gray-200 text-black";
  };
  //Change the current category to selected category paramter by clicking on its tab.
  const handleClick = (selectedCategory) => {
    setCategory(selectedCategory);
  };
  return (
    <div>
      <h1 className="font-bold text-xl m-3">Master Record Management</h1>
      <div className="flex justify-evenly">
        <button
          className={`${buttonSelected("locations")} ${buttonStyle}`}
          style={boxShadowStyle}
          onClick={() => handleClick("locations")}
        >
          Locations
        </button>
        <button
          className={`${buttonSelected("recordTypes")} ${buttonStyle}`}
          style={boxShadowStyle}
          onClick={() => handleClick("recordTypes")}
        >
          Record Types
        </button>
        <button
          className={`${buttonSelected("objectives")} ${buttonStyle}`}
          style={boxShadowStyle}
          onClick={() => handleClick("objectives")}
        >
          Objectives
        </button>
        <button
          className={`${buttonSelected("visitSteps")} ${buttonStyle}`}
          style={boxShadowStyle}
          onClick={() => handleClick("visitSteps")}
        >
          Visit Steps
        </button>
      </div>
    </div>
  );
}

export default TabsHeader;
