import React, { useEffect } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import formatDate from "./DateFormatFunc";
import { SpaceContext } from "antd/es/space";
import { TbDeviceTabletSearch } from "react-icons/tb";
import { getData } from "../../services/NetworkServices";
export default function JourneyReports({ data }) {
  // const [EditID, setEditID] = useState(null);
  const navigate = useNavigate();
  const handleIconClick = (id) => {
    navigate(`/VisitReport/${id}`, { state: { id: id } });
  };
  return (
    <div className="w-full bg-[#F7F7F7] shadow-xl flex justify-center items-center flex-col rounded-md ">
      <div className="w-11/12 flex items-center ">
        <h1 className="text-2xl font-bold pt-4">Visits - {data.title}</h1>
        {/* <div className="ml-28">
          <div className="rounded-full w-10 h-10 bg-teal mt-3">
            <p className="text-xl text-white ml-1 top-2 relative font-thin">
              1/3
            </p>
          </div>
        </div>
        <div>
          <h2 className="ml-4 mt-2 font-semibold">
            Visits <br /> Completed
          </h2>
        </div> */}
      </div>
      <div className="w-11/12 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full mb-6 mt-6">
          <ul className="flex justify-evenly items-center border-b-2 border-black w-full">
            <li className=" w-28">Business unit</li>
            <li className=" w-28">Visit Date</li>
            <li className=" w-28">Objective</li>
            <li className=" w-28">Status</li>
            <li className=" w-28">Action</li>
          </ul>
          <div className="flex flex-col justify-center items-center w-full mt-1">
            {data?.details?.map((item, index) => (
              <ul
                key={index}
                className={`flex px-2 h-16 justify-evenly items-center w-full text-sm mt-1 ${
                  index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                } hover:scale-105   transition-transform duration-200 ease-in-out`}
              >
                <li className=" w-28 px-2">{item.busniessUnitName}</li>
                <li className="w-28">
                  {formatDate(item?.visitDate.split("T")[0])}
                </li>
                {/* <li className="flex justify-center items-center flex-col  w-28">
                  {item.objectiveName}
                </li> */}
                <li className=" w-28">{item.objectiveName}</li>
                <li className="w-28 px-3">{item.visitStatusName}</li>
                <li className="flex justify-center flex-col items-center w-28 cursor-pointer">
                  <TbDeviceTabletSearch
                    size={30}
                    onClick={() => handleIconClick(item.journetDetailID)}
                    className="mr-12"
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
