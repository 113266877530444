import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCalendarAlt, FaDatabase, FaStickyNote } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { TfiLayoutMediaCenterAlt } from "react-icons/tfi";
import { FaBook } from "react-icons/fa";
import { BsPersonCircle } from "react-icons/bs";
import axios from "axios";
import { postData, postDataForLogOut } from "../services/NetworkServices";
import { RiSurveyLine } from "react-icons/ri";
import { GrStatusGoodSmall } from "react-icons/gr";
import { usePermission } from "../PermissionContext";
import { GoIssueOpened, GoIssueTrackedBy } from "react-icons/go";

export default function SecondaryLayout() {
  const token = localStorage.getItem("token")
  const [data, setData] = useState("");
  const [userdata, setUserdata] = useState({ username: "", userRole: "" });
  const [active, setActive] = useState(0);
  const [slide, setSlide] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const profileButtonRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubList, setShowSubList] = useState(false);

  const { permissions } = usePermission();

  const hasViewPermission = (route) => {
    // Check if permissions is an array and not undefined/null
    if (!Array.isArray(permissions)) {
      return false; // Default to false if permissions is not an array
    }
  
    return permissions.some(
      (permission) => permission.pageRoute === route && permission.canView
    );
  };
  
  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(() => {
    hasViewPermission();
  }, [permissions]);

  const getData = async () => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setData(parsedData);
      const names = parsedData?.username?.split(" ");
      let username =
        names.length >= 2
          ? (names[0][0] + names[1][0]).toUpperCase()
          : (names[0][0] + names[0][1]).toUpperCase();
      setUserdata({ username, userRole: parsedData.userRole });
    }
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     window.location.href = "/login";
  //   }
  //   if (location.pathname === "/") {
  //     setActive(1);
  //   } else if (
  //     location.pathname === "/journey" ||
  //     location.pathname === "/journeyplan"
  //   ) {
  //     setActive(2);
  //   }
  //   //  else if (location.pathname === "/stockManagement") {
  //   //   setActive(3);
  //   // }
  //   else if (location.pathname === "/GIN") {
  //     setActive(4);
  //   } else if (location.pathname === "/GRN") {
  //     setActive(5);
  //   } else if (location.pathname === "stockManagement") {
  //     setActive(6);
  //   } else if (location.pathname === "/media") {
  //     setActive(7);
  //   } else if (location.pathname === "/masterRecordManagement") {
  //     setActive(8);
  //   } else if (location.pathname === "/manageUser") {
  //     setActive(9);
  //   } else if (location.pathname === "/survey") {
  //     setActive(10);
  //   } else if (location.pathname === "/location") {
  //     setActive(11);
  //   }else if (location.pathname === "/autoDas") {
  //     setActive(13);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    // Handle the active menu item based on the current location
    switch (location.pathname) {
      case "/":
        setActive(1);
        break;
      case "/journey":
      case "/journeyplan":
        setActive(2);
        break;
      case "/GIN":
        setActive(4);
        break;
      case "/GRN":
        setActive(5);
        break;
      case "/stockManagement":
        setActive(6);
        break;
      case "/media":
        setActive(7);
        break;
      case "/masterRecordManagement":
        setActive(8);
        break;
      case "/manageUser":
        setActive(9);
        break;
      case "/survey":
        setActive(10);
        break;
      case "/location":
        setActive(11);
        break;
      case "/autoDas":
        setActive(13);
        break;
      case "/stockNotes":
        setActive(14);
        break;
      case "/stockin-hand":
        setActive(15);
        break;
      default:
        setActive(0);
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    getData();
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await postDataForLogOut("account/logout",data,token);
      console.log("LogOut => ", token);
    }
    localStorage.clear();
    navigate("/login",{replace:true});
  };
  

  const handleClickOutside = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !profileButtonRef.current.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex  justify-around w-[100%] h-screen shadow-lg ">
      <div
        className={`bg-[#F7F7F7] transition-all duration-300 h-full ${
          slide ? "w-80" : "w-14"
        } flex flex-col items-center shadow-2xl`}
      >
        <li className="relative flex justify-center items-center w-full">
          <span
            className="absolute top-[400px] cursor-pointer -right-4"
            onClick={() => setSlide(!slide)}
          >
            <img
              src={`${
                slide ? "/assets/Collapse2.png" : "/assets/Collapse.png"
              }`}
              alt=""
            />
          </span>
        </li>
        <div className="flex w-full justify-center h-24 items-center mt-8">
          <img
            src="/assets/Colgate.png"
            alt=""
            className={`${slide ? "opacity-100 " : "opacity-0"}`}
          />
        </div>
        <ul className="mt-20 flex justify-start items-center gap-10 w-full flex-col gap-y-7 px-2 overflow-auto pb-8">
          <li
            className={`flex justify-start w-full items-center h-10 px-2 ${
              active === 1 ? "bg-[#F50005] text-white" : ""
            } transition-all duration-300 text-black`}
          >
            <a
              href="/"
              className="flex w-full items-center h-10 px-2"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                navigate("/"); // Handle navigation with React Router
              }}
            >
              <MdOutlineDashboard className="w-5 h-5" />
              <span
                className={`ml-1 text-base font-semibold ${
                  slide ? "flex" : "hidden"
                } ${
                  slide ? "opacity-100 delay-300" : "opacity-0"
                } duration-500 transition-opacity`}
              >
                Dashboard
              </span>
            </a>
          </li>
          {hasViewPermission("/journey") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 2 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/journey"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  navigate("/journey"); // Handle navigation with React Router
                }}
              >
                <FaCalendarAlt className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  Journey Planning
                </span>
              </a>
            </li>
          )}
          <li
            className={`flex justify-start w-full items-center h-10 px-4 cursor-pointer ${
              active === 3 ? "bg-[#F50005] text-white" : ""
            } transition-all duration-300 text-black`}
            onClick={() => setShowSubList(!showSubList)}
          >
            <IoStatsChart className="w-5 h-5" />
            <span
              className={`ml-1 text-base font-semibold ${
                slide ? "flex" : "hidden"
              }`}
            >
              Stock Management
            </span>
          </li>
          {showSubList && (
            <>
              {hasViewPermission("/gin") && (
                <li
                  className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                    active === 4 ? "bg-[#F50005] text-white" : ""
                  } transition-all duration-300 text-black`}
                >
                  <a
                    href="/GIN"
                    className="flex w-full items-center h-10 px-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/GIN");
                    }}
                  >
                    <GoIssueOpened className="w-6 h-6 font-bold" />
                    <span
                      className={`ml-1 text-base font-semibold ${
                        slide ? "flex" : "hidden"
                      }`}
                    >
                      Goods Issue Note
                    </span>
                  </a>
                </li>
              )}
              {hasViewPermission("/grn") && (
                <li
                  className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                    active === 5 ? "bg-[#F50005] text-white" : ""
                  } transition-all duration-300 text-black`}
                >
                  <a
                    href="/GRN"
                    className="flex w-full items-center h-10 px-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/GRN");
                    }}
                  >
                    <GoIssueTrackedBy className="w-6 h-6 font-bold" />
                    <span
                      className={`ml-1 text-base font-semibold ${
                        slide ? "flex" : "hidden"
                      }`}
                    >
                      Goods Return Note
                    </span>
                  </a>
                </li>
              )}

              <li
                className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                  active === 14 ? "bg-[#F50005] text-white" : ""
                } transition-all duration-300 text-black`}
              >
                <a
                  href="/autoDas"
                  className="flex w-full items-center h-10 px-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/stockNotes");
                  }}
                >
                  <FaBook className="w-5 h-5" />
                  <span
                    className={`ml-1 text-base font-semibold ${
                      slide ? "flex" : "hidden"
                    }`}
                  >
                    Stock Adjustment Note
                  </span>
                </a>
              </li>
              {hasViewPermission("/stockmanagement") && (
                <li
                  className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                    active === 6 ? "bg-[#F50005] text-white" : ""
                  } transition-all duration-300 text-black`}
                >
                  <a
                    href="/stockManagement"
                    className="flex w-full items-center h-10 px-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/stockManagement");
                    }}
                  >
                    <IoStatsChart className="w-5 h-5" />
                    <span
                      className={`ml-1 text-base font-semibold ${
                        slide ? "flex" : "hidden"
                      }`}
                    >
                      Stock Report
                    </span>
                  </a>
                </li>
              )}
              {hasViewPermission("/autoDas") && (
                <li
                  className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                    active === 13 ? "bg-[#F50005] text-white" : ""
                  } transition-all duration-300 text-black`}
                >
                  <a
                    href="/autoDas"
                    className="flex w-full items-center h-10 px-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/autoDas");
                    }}
                  >
                    <FaDatabase className="w-5 h-5" />
                    <span
                      className={`ml-1 text-base font-semibold ${
                        slide ? "flex" : "hidden"
                      }`}
                    >
                      Auto Das
                    </span>
                  </a>
                </li>
              )}
              {/* <li
                className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                  active === 14 ? "bg-[#F50005] text-white" : ""
                } transition-all duration-300 text-black`}
              >
                <a
                  href="/autoDas"
                  className="flex w-full items-center h-10 px-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/stockNotes");
                  }}
                >
                  <FaBook className="w-5 h-5" />
                  <span
                    className={`ml-1 text-base font-semibold ${
                      slide ? "flex" : "hidden"
                    }`}
                  >
                    Stock Adjustment Notes
                  </span>
                </a>
              </li> */}
              <li
                className={`flex justify-start w-full items-center h-10 pl-10 px-2 ${
                  active === 15 ? "bg-[#F50005] text-white" : ""
                } transition-all duration-300 text-black`}
              >
                <a
                  href="/reports"
                  className="flex w-full items-center h-10 px-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/stockin-hand");
                  }}
                >
                  <FaBook className="w-5 h-5" />
                  <span
                    className={`ml-1 text-base font-semibold ${
                      slide ? "flex" : "hidden"
                    }`}
                  >
                    Stock in-hand
                  </span>
                </a>
              </li>
            </>
          )}
          {hasViewPermission("/media") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 7 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/media"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/media");
                }}
              >
                <TfiLayoutMediaCenterAlt className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  Media Management
                </span>
              </a>
            </li>
          )}
          {hasViewPermission("/masterrecordmanagement") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 8 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/masterRecordManagement"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/masterRecordManagement");
                }}
              >
                <FaBook className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  Master Record Management
                </span>
              </a>
            </li>
          )}
          {hasViewPermission("/usermanagement") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 9 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/manageUser"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/manageUser");
                }}
              >
                <BsPersonCircle className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  User Management
                </span>
              </a>
            </li>
          )}
          {hasViewPermission("/survey") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 10 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/survey"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/survey");
                }}
              >
                <RiSurveyLine className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  Surveys
                </span>
              </a>
            </li>
          )}
          {hasViewPermission("/usertracking") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 11 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/location"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/location");
                }}
              >
                <RiSurveyLine className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  User Tracking
                </span>
              </a>
            </li>
          )}
          {hasViewPermission("/permissions") && (
            <li
              className={`flex justify-start w-full items-center h-10 px-2 ${
                active === 12 ? "bg-[#F50005] text-white" : ""
              } transition-all duration-300 text-black`}
            >
              <a
                href="/permissions"
                className="flex w-full items-center h-10 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/permissions");
                }}
              >
                <RiSurveyLine className="w-5 h-5" />
                <span
                  className={`ml-1 text-base font-semibold ${
                    slide ? "flex" : "hidden"
                  }`}
                >
                  Permissions
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="flex-1 flex flex-col ">
        <div className="w-full bg-gradient-to-r from-[#F50005] to-[#BF0004] h-16 flex justify-end items-center pr-7 ">
          <div className="relative ">
            <div
              className="w-10 h-10 flex justify-center items-center rounded-full bg-white text-center cursor-pointer "
              ref={profileButtonRef}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <h1 className="font-bold text-lg">{userdata.username}</h1>
            </div>
            <div
              ref={dropdownRef}
              className={`absolute top-13 w-32 right-2  ${
                showDropdown ? "h-28" : "h-0"
              } bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden justify-between flex   z-50`}
            >
              <ul
                className={`flex flex-col justify-evenly w-full ${
                  showDropdown ? "opacity-100 delay-300" : "opacity-0"
                } items-start ml-4 transition-opacity duration-700`}
              >
                <li className="font-bold">{data?.username}</li>
                <li className="flex text-sm">{data.userRole}</li>
                {/* <li className="mt-3 flex justify-center items-center text-center cursor-pointer hover:text-red-600 transition-all duration-300">
                  <img src="/assets/userpro.png" alt="" />
                  <span className="flex text-sm font-semibold ml-2 mb-[2px]">
                    Edit Profile
                  </span>
                </li>
                <li className="mt-3 flex justify-center items-center text-center cursor-pointer hover:text-red-600 transition-all duration-300">
                  <img src="/assets/question.png" alt="" />
                  <span className="flex text-sm font-semibold ml-2 mb-[2px]">
                    About
                  </span>
                </li> */}
                <li
                  className="mt-3 flex justify-center items-center text-center cursor-pointer hover:text-red-600 transition-all duration-300"
                  onClick={handleLogout}
                >
                  <img src="/assets/signout.png" alt="" />
                  <span className="flex text-sm font-semibold ml-2 mb-[2px]">
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex-auto p-3 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
