import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  postData,
  getData,
  putData,
  postFormData,
  putFormData,
} from "../../services/NetworkServices.js";
import { Button, DatePicker, message, Select } from "antd";
import dayjs from "dayjs";

const MediaPopUp = (props) => {
  const [regionData, setRegionData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [filterRegionData, setFilterRegionData] = useState([]);
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [filterMasterData, setFilterMasterData] = useState([]);
  const [showDropdown, setShowDropdown] = useState({
    region: false,
    zone: false,
    master: false,
  });

  const{
    remarks: remarks,
    title:mediaTitle,
    thumbnailName: media,
    regionName: regionName,
    zoneName: zoneName,
    recordTypeTitle: recordTypeTitle,
    publicationDate: initialPublicationDate,
    validTillDate: initialValidTillDate,
    regionId:initialRegionId,
    zoneId:initialZoneId,
    masterRecordTypeId:initialRecordTypeId,


  }=props.edit ||{}

  const { setLoading, setTriggerApi } = props;

  const [selectedValues, setSelectedValues] = useState({
    remarks,
    mediaTitle,
    media
  });
  const [publicationDate, setPublicationDate] = useState(
    initialPublicationDate ? dayjs(initialPublicationDate) : ""
  );
    const [validTillDate, setValidTillDate] = useState(
       initialValidTillDate? dayjs(initialValidTillDate) : ""
    );
  const [selectedRegion, setSelectedRegion] = useState(regionName);
  const [selectedZone, setSelectedZone] = useState(zoneName);
  const [selectedMaster, setSelectedMaster] = useState(recordTypeTitle);
  
  const [regionId, setRegionId] = useState(initialRegionId);
const [zoneId, setZoneId] = useState(initialZoneId);
  const [masterRecordTypeID, setMasterRecordTypeID] = useState(initialRecordTypeId);
  const dropdownRefs = {
    region: useRef(null),
    zone: useRef(null),
    master: useRef(null),
  };

  const [creating, setCreating] = useState(false);
  // Fetch data from the API
  useEffect(() => {
    getData(`region`).then((res) => {
      const sortedRegionData = res.data?.sort((a, b) => {
        return a?.title?.localeCompare(b?.title);
      });
      setRegionData(sortedRegionData);
      setFilterRegionData(sortedRegionData);
      console.log("res rigion", res);
    });
  }, []);
  useEffect(() => {
    if (regionId) {
      getData(`Zone/zones/${regionId}`).then((res) => {
        const sortedZoneData = res.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setZoneData(sortedZoneData);
        setFilterZoneData(sortedZoneData);
      });
    }
  }, [regionId]);

  useEffect(() => {
    getData(`master-record-type`).then((res) => {
      const sortedMasterData = res.data?.data?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
      setMasterData(sortedMasterData);
      setFilterMasterData(sortedMasterData);
      console.log("maaaaaster", res);
    });
  }, []);

  const handleClickOutside = (e) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(e.target)
      ) {
        setShowDropdown((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const onSubmit = async () => {
    
    // e.preventDefault()
    try {
      const requestFormData = new FormData();
      requestFormData.append("Title", selectedValues.mediaTitle);
      requestFormData.append("regionId", regionId);
      requestFormData.append("zoneId", zoneId);
      requestFormData.append("masterRecordTypeId", masterRecordTypeID);
      requestFormData.append("remarks", selectedValues.remarks);
      requestFormData.append("publicationDate", dayjs(publicationDate).format("YYYY-MMM-DD"));
      requestFormData.append("validTillDate",  dayjs(validTillDate).format("YYYY-MMM-DD"));
      requestFormData.append("media", selectedValues.file);
      let res;
      if (!props.edit) {
        setCreating(true);
        res = await postFormData(
          "media-management/create-media",
          requestFormData,localStorage.getItem("token")
        );
        
        // console.log("Create Media res", res);
        setTriggerApi(true);
        // setLoading(true);
      } else {
        setCreating(true);
        res = putFormData(`media-management/${props.edit.id}`, requestFormData);
        // setTriggerApi(true);
        // setCreating(false);
        setCreating(false);
        hide()
        setTriggerApi(true)
      }
      if (res.status === 200) {
        message.success(
          `Media ${!props?.edit ? "Added" : "Edited"} Successfully`
        );
        hide();
      }
    } catch (err) {
      setCreating(false);
      message.error(`Media ${!props?.edit ? "Adding" : "Editing"} Faild`);
      console.log("Error");
    }
  };
  const handleChange = (e) => {
    const { name } = e.target;

    if (name === "file") {
      const file = e.target.files[0]; // Get the selected file

      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = btoa(
            new Uint8Array(reader.result).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          ); // Convert to Base64 string

          setSelectedValues((prevState) => ({ ...prevState, [name]: file }));
        };

        reader.readAsArrayBuffer(file);
      }
    } else {
      const { value } = e.target;
      setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const hide = () => {
    props.hide();
  };

  return (
    <div className="w-full h-[730px] flex justify-center items-center fixed inset-0 bg-blend-darken backdrop-brightness-50 overflow-scroll">
      <div className=" w-[650px] bg-white flex flex-col gap-6 rounded-2xl p-6">
        <div className="flex flex-col w-full gap-10">
          <div className="text-center text-3xl font-semibold p-2">
            <p>Upload Media</p>
          </div>
          <div className="flex gap-10 w-full ">
            <div className="relative w-full">
              <input
                autoComplete="off"
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full"
                type="text"
                name="mediaTitle"
                value={selectedValues.mediaTitle || []}
                onChange={handleChange}
              />
              <p className="absolute bottom-9 left-8 bg-white font-bold">
                Name*
              </p>
            </div>
            <div className="relative w-full ">
              <DatePicker
                selected={publicationDate}
                value={publicationDate} // Ensures the value is set correctly
                id="publicationDate"
                autoComplete="off"
                onChange={(date) => {
                  setPublicationDate(date);
                }}
                className="outline-red-200 py-2 px-6 w-[280px] border-2 border-red-200 rounded-full relative"
                placeholderText="Select the Date"
              />
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Publication Date*
              </p>
            </div>
          </div>
          <div className="flex gap-10 w-full ">
            <div className="relative w-full ">
              <DatePicker
                selected={validTillDate}
                value={validTillDate}
                id="validTillDate"
                autoComplete="off"
                onChange={(date)=> setValidTillDate(date) } 
                className=" outline-red-200 py-3 px-6 w-[280px] border-2 border-red-200 rounded-full relative"
                placeholderText="Select the Date"
                // maxDate={publicationDate || new Date()}
                disabledDate={(current) =>
                  current && current < new Date(publicationDate)
                }
              />
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Valid Till Date
              </p>
            </div>
            <div className="relative w-full" ref={dropdownRefs.region}>
              <Select
              showSearch
              placeholder="Select the Region"
              value={selectedRegion}
                onChange={(value) => {
                  const selectedRegion = filterRegionData.find((item)=> item.id === value)
                  setSelectedRegion(selectedRegion.title);
                  setRegionId(value);
                  setSelectedZone("")
                  setSelectedMaster("")

                }}
                filterOption={(input, option) => {
                  // Ensure option.label is a string
                  const label = option.label || '';
                  return label.toLowerCase().includes(input.toLowerCase());
                }}
              >

              {filterRegionData.map((item) =>(
                <Select.Option key={item.id} value={item.id} label={item.title}>
                  {item.title}
                </Select.Option>

              ))}
              </Select>
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Region*
              </p>
              {/* <input
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative transparent z-100"
                type="text"
                name="region"
                autoComplete="off"
                placeholder="Select the Region"
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, region: !prev.region }))
                }
                value={selectedRegion ||[]}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              {showDropdown.region && (
                <div className="absolute z-10 w-[270px] max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg right-1">
                  {filterRegionData.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        setSelectedRegion((prev) => (prev = item.title));
                        setRegionId((prev) => (prev = item.id));
                        console.log("reginId", item.id);
                        setShowDropdown((prev) => ({ ...prev, region: false }));
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>
          <div className="flex gap-10">
            <div className="relative w-full" ref={dropdownRefs.zone}>
            <Select
            showSearch
              placeholder="Select the Zone"
              value={selectedZone}
                onChange={(value) => {
                  const selectedZone = filterZoneData.find((item)=> item.id === value)
                  setSelectedZone(selectedZone.title);
                  setZoneId(value);

                }}
                filterOption={(input, option) => {
                  // Ensure option.label is a string
                  const label = option.label || '';
                  return label.toLowerCase().includes(input.toLowerCase());
                }}
              >

              {filterZoneData.map((item) =>(
                <Select.Option key={item.id} value={item.id} label={item.title}>
                  {item.title}
                </Select.Option>

              ))}
              </Select>
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Zone*
              </p>
              {/* <input
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative"
                type="text"
                autoComplete="off"
                name="zone"
                placeholder="Select the Zone"
                value={selectedZone || []}
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, zone: !prev.zone }))
                }
                onChange={(e) => {
                  setSelectedZone(e.target.value);
                  filterZone(e);
                }}
                disabled={!selectedRegion}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-8 left-8 font-bold bg-white">
                Zone*
              </p>
              {showDropdown.zone && (
                <div className="absolute z-10 w-[270px] max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg right-1">
                  {filterZoneData.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      autoComplete="off"
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        setSelectedZone(item.title);
                        setZoneId(item.id);
                        setShowDropdown((prev) => ({ ...prev, zone: false }));
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
            <div className="relative w-full">
          <p className="absolute bottom-9 left-8 font-bold bg-white z-10">Record Type</p>

            <Select
              showSearch
              placeholder={"Select the Record Type"}
              value={selectedMaster}
              onChange={(value,option) => {
                const selectedMaster = filterMasterData.find((item) => item.id === value); 
                setSelectedMaster(selectedMaster?.name);
                setMasterRecordTypeID(value);
              }}
              filterOption={(input, option) => 
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {(filterMasterData || []).map((item) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
            {/* <div className="relative w-full" ref={dropdownRefs.master}>
              <input
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative"
                type="text"
                name="master"
                autoComplete="off"
                placeholder="Select the Master"
                value={selectedMaster || []}
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, master: !prev.master }))
                }
                onChange={(e) => {
                  setSelectedMaster(e.target.value);
                  filterMaster(e);
                }}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-8 left-8 font-bold bg-white">
                Record type*
              </p>
              {showDropdown.master && (
                <div className="absolute z-10 w-[270px] max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg right-1">
                  {filterMasterData.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        setSelectedMaster(item.name);
                        setMasterRecordTypeID(item.id);
                        setShowDropdown((prev) => ({ ...prev, master: false }));
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}
            </div> */}
          </div>
          <div className="flex gap-10">
            <div className="relative w-full">
              <input
                autoComplete="off"
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full"
                type="text"
                name="remarks"
                value={selectedValues.remarks}
                onChange={handleChange}
              />
              <p className="absolute bottom-9 left-8 bg-white font-bold">
                {" "}
                Remarks*
              </p>
            </div>
            <div className="relative w-full" ref={dropdownRefs.file}>
              <input
                className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative"
                type="file"
                name="file"
                // selected={selectedValues.file}
                // value={selectedValues.file}
                accept="/*"
                onChange={handleChange}
                
                
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-center flex gap-10 justify-center">
            <Button
              style={{ height: "45px" }}
              type="button"
              loading={creating}
              className="btnSubmit btn w-3.5"
              onClick={() => {
                onSubmit();
              }}
              disabled={
                !selectedValues.mediaTitle ||
                !props.edit && !selectedValues.file ||
                !publicationDate ||
                !selectedMaster ||
                !selectedRegion ||
                !selectedZone
              }
            >
              Submit
            </Button>
            <button
              type="button"
              className="btnCancel btn"
              onClick={() => hide()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPopUp;
