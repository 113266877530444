import { DatePicker } from 'antd'
import React, { useState } from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { FaAngleDown, FaAngleUp, FaMagnifyingGlass } from 'react-icons/fa6'

export default function CreateSurvey() {
  const [clicked,setclicked] = useState(1)
  const [startDate,setStartDate] = useState([])
  const [drop,setdrop]=useState([])
  
  return (
    <div className="flex justify-center flex-col items-center mt-1 w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex justify-start items-start w-full mt-12">
            <h1 className="font-semibold text-3xl">Surveys - Create Survey</h1>
          </div>
          <div className='w-11/12 flex justify-center items-center bg-white rounded-2xl shadow-md mb-6'>
               <div className='h-64 flex justify-center items-start w-full'>

                <div className='flex flex-col justify-start items-center w-full mt-2 ml-8'>
                  <div className='w-full'>
                    <h1 className='font-semibold text-2xl'>Survey Details</h1>
                  </div>
                  <div className='flex justify-evenly items-center w-full mt-2'>

                  <div className='flex justify-center items-center relative  w-80'>
          <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Survey Name
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>


          {/* Second Card */}


          <div className="relative flex justify-center items-center w-80 ">
          <DatePicker
          autoComplete="off"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className=" py-3 px-14 w-[350px] border-2 border-red-400 rounded-full relative "
            placeholderText="Select the Date"
            name='startDate'
          />
          <FaCalendarAlt className="absolute bottom-4 right-4 text-xl cursor-pointer" />
          <p className="absolute -top-[9px] left-12 font-bold text-sm bg-white">
            Start Date
          </p>
        </div>
          {/* Third Card */}

          <div className='flex justify-center items-center relative  w-80'>
          <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Search
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>
                  </div>

                 {/* Second grid */}

                 <div className='flex justify-evenly items-center w-full mt-10'>

                 <div className='flex justify-center items-center relative  w-80 mt-2 transition-all duration-500 '>
          <input
                type="text"
                className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                
                name="username"
                
                onClick={()=>setdrop(3)}

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Recieve Quantity
              </div>
              <div className="absolute right-5 text-sm font-bold">
              {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
              <p className='text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 '>{drop===1?<FaAngleUp className='cursor-pointer' onClick={()=>setdrop(0)} />:<FaAngleDown className='cursor-pointer' onClick={()=>setdrop(1)} />}</p>
                
              </div>

              <div className={`absolute top-11 w-full  ${drop ===1 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30`}>
              <ul className={`flex flex-col justify-start w-full ${drop===1 ? "opacity-100 delay-300" : "opacity-0"} items-start ml-4 transition-opacity duration-700`}>
                <li className='font-bold'>Anas Danish</li>
                
                <li className='mt-1 flex justify-center items-center text-center'>About</li>
                <li className='mt-1 flex justify-center items-center text-center'>Logout</li>
              </ul>
            </div>
              
          </div>


{/* Second Card */}


<div className='flex justify-center items-center relative  w-80 mt-2 transition-all duration-500 '>
          <input
                type="text"
                className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                
                name="username"
                
                onClick={()=>setdrop(2)}

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Recieve Quantity
              </div>
              <div className="absolute right-5 text-sm font-bold">
              {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
              <p className='text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 '>{drop===2?<FaAngleUp className='cursor-pointer' onClick={()=>setdrop(0)} />:<FaAngleDown className='cursor-pointer' onClick={()=>setdrop(2)} />}</p>
                
              </div>

              <div className={`absolute top-11 w-full  ${drop ===2 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30`}>
              <ul className={`flex flex-col justify-start w-full ${drop===2 ? "opacity-100 delay-300" : "opacity-0"} items-start ml-4 transition-opacity duration-700`}>
                <li className='font-bold'>Anas Danish</li>
                
                <li className='mt-1 flex justify-center items-center text-center'>About</li>
                <li className='mt-1 flex justify-center items-center text-center'>Logout</li>
              </ul>
            </div>
              
          </div>
{/* Third Card */}

<div className='flex justify-center items-center relative  w-80 mt-2 transition-all duration-500 '>
          <input
                type="text"
                className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                
                name="username"
                
                onClick={()=>setdrop(3)}

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Recieve Quantity
              </div>
              <div className="absolute right-5 text-sm font-bold">
              {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
              <p className='text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 '>{drop===3?<FaAngleUp className='cursor-pointer' onClick={()=>setdrop(0)} />:<FaAngleDown className='cursor-pointer' onClick={()=>setdrop(3)} />}</p>
                
              </div>

              <div className={`absolute top-11 w-full  ${drop ===3 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30`}>
              <ul className={`flex flex-col justify-start w-full ${drop===3 ? "opacity-100 delay-300" : "opacity-0"} items-start ml-4 transition-opacity duration-700`}>
                <li className='font-bold'>Anas Danish</li>
                
                <li className='mt-1 flex justify-center items-center text-center'>About</li>
                <li className='mt-1 flex justify-center items-center text-center'>Logout</li>
              </ul>
            </div>
              
          </div>
</div>

                   
                      


                </div>
               
               </div>
               
          </div>


{/* Question 1 */}

<div className='w-11/12 flex justify-center items-center bg-white rounded-2xl shadow-md mb-5'>
<div className='h-64 flex justify-center items-start w-full'>
  <div className='flex flex-col justify-start items-center w-full'>
   <div className='mt-4 ml-4 flex justify-between items-center w-full'>
     <div className='flex justify-center items-center'>
       <h1 className='font-semibold text-2xl'>Question 1</h1>
     </div>

     <div className='flex justify-center items-center'>
      <div className='mr-4 font-semibold'>
        <h1>Required</h1>
      </div>
      <div className='flex justify-center items-center w-full transition-all duration-300 mr-3'>
        <div className={`w-24 h-10 border border-[#F50005] rounded-2xl flex justify-start transition-all duration-300 items-center gap-x-2 relative`}>
            <div onClick={()=>setclicked(0)} className={`absolute top-2 cursor-pointer transition-all duration-300 left-2 ${clicked===0?"text-white":""}`}>
              Yes
            </div>
            <div onClick={()=>setclicked(1)} className={`absolute top-2 cursor-pointer right-2 transition-all duration-300 ${clicked===1?"text-white":""}`}>
              No
            </div>
            <span className={`bg-[#F50005] w-[48px] h-10  flex justify-center transition-all duration-300 ${clicked===1?"ml-12":"ml-o"} rounded-2xl`}></span>
        </div>
      </div>
     </div>

     

     
   </div>


<div className='flex  justify-center items-start w-full'>
  <div className='w-2/3 flex flex-col justify-center items-center ml-12 mt-10 '>
  <div className='flex justify-center items-center relative  w-full'>
          <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Question
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>
          <div className='flex justify-center items-center relative mt-7  w-full'>
          <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>
  </div>
  <div className='w-1/3  mt-10 ml-2'>
  <div className='flex justify-center items-center relative  w-full transition-all duration-500 '>
          <input
                type="text"
                className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                
                name="username"
                
                onClick={()=>setdrop(4)}

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Type
              </div>
              <div className="absolute right-5 text-sm font-bold">
              {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
              <p className='text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 '>{drop===4?<FaAngleUp className='cursor-pointer' onClick={()=>setdrop(0)} />:<FaAngleDown className='cursor-pointer' onClick={()=>setdrop(4)} />}</p>
                
              </div>

              <div className={`absolute top-11 w-full  ${drop ===4 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30`}>
              <ul className={`flex flex-col justify-start w-full ${drop===4 ? "opacity-100 delay-300" : "opacity-0"} items-start ml-4 transition-opacity duration-700`}>
                <li className='font-bold'>Anas Danish</li>
                
                <li className='mt-1 flex justify-center items-center text-center'>About</li>
                <li className='mt-1 flex justify-center items-center text-center'>Logout</li>
              </ul>
            </div>
              
          </div>
  </div>

</div>





   
   
  </div>
</div>
  </div>




  <div className='w-11/12 flex justify-center items-center bg-white rounded-2xl shadow-md'>
<div className='h-64 flex justify-center items-start w-full'>
  <div className='flex flex-col justify-start items-center w-full'>
   <div className='mt-4 ml-4 flex justify-between items-center w-full'>
     <div className='flex justify-center items-center'>
       <h1 className='font-semibold text-2xl'>Question 1</h1>
     </div>

     <div className='flex justify-center items-center'>
      <div className='mr-4 font-semibold'>
        <h1>Required</h1>
      </div>
      <div className='flex justify-center items-center w-full transition-all duration-300 mr-3'>
        <div className={`w-24 h-10 border border-[#F50005] rounded-2xl flex justify-start transition-all duration-300 items-center gap-x-2 relative`}>
            <div onClick={()=>setclicked(0)} className={`absolute top-2 cursor-pointer transition-all duration-300 left-2 ${clicked===0?"text-white":""}`}>
              Yes
            </div>
            <div onClick={()=>setclicked(1)} className={`absolute top-2 cursor-pointer right-2 transition-all duration-300 ${clicked===1?"text-white":""}`}>
              No
            </div>
            <span className={`bg-[#F50005] w-[48px] h-10  flex justify-center transition-all duration-300 ${clicked===1?"ml-12":"ml-o"} rounded-2xl`}></span>
        </div>
      </div>
     </div>

     

     
   </div>


<div className='flex  justify-center items-start w-full'>
  <div className='w-2/3 flex flex-col justify-center items-center ml-12 mt-10 '>
  <div className='flex justify-center items-center relative  w-full'>
          <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Question
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>
          <div className='flex justify-center items-center relative mt-7  w-full'>
          <textarea
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
                
                

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
              </div>
              <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className='w-5 h-5'/>

              </div>
              
          </div>
  </div>
  <div className='w-1/3  mt-10 ml-2'>
  <div className='flex justify-center items-center relative  w-full transition-all duration-500 '>
          <input
                type="text"
                className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                
                name="username"
                
                onClick={()=>setdrop(4)}

              />
              <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                Type
              </div>
              <div className="absolute right-5 text-sm font-bold">
              {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
              <p className='text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 '>{drop===4?<FaAngleUp className='cursor-pointer' onClick={()=>setdrop(0)} />:<FaAngleDown className='cursor-pointer' onClick={()=>setdrop(4)} />}</p>
                
              </div>

              <div className={`absolute top-11 w-full  ${drop ===4 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30`}>
              <ul className={`flex flex-col justify-start w-full ${drop===4 ? "opacity-100 delay-300" : "opacity-0"} items-start ml-4 transition-opacity duration-700`}>
                <li className='font-bold'>Anas Danish</li>
                
                <li className='mt-1 flex justify-center items-center text-center'>About</li>
                <li className='mt-1 flex justify-center items-center text-center'>Logout</li>
              </ul>
            </div>
              
          </div>
  </div>

</div>





   
   
  </div>
</div>
  </div>

        </div>
    </div>
  )
}
