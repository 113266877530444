// React Imports
import React, { useState, useEffect } from "react";
// Third Party Imports
import axios from 'axios';
import { Pagination } from 'antd';
import { useNavigate } from "react-router-dom";

import { getData } from '../services/NetworkServices.js'
import { useRole } from "../RoleContext.js";
// constants
const ManageSurvey = () => {
  const navigate = useNavigate();
  // data holders
  const fields = ['Survey Name', 'Record Type', 'Region', 'Zone', 'Expiry Date'];
  // data records holder
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [click, setClicked] = useState(false);
  const [selectedRecord, onSelectRecord] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);
  const [rectype, setRecType] = useState('list')
  
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0
  });

  const { permissions } = useRole();

  const hasPermission = (permission) => permissions.includes(permission);

  const getRecord = async() => {
    const url = rectype === 'list' ? "Survey/ViewAllResponseList" :
    "Survey/ViewResponseSurvayDetail?ResponseMasterId=0"
    getData(url).then((res)=>{
      console.log(res,"res")
      setData(res?.data?.data);
      setCurrentPagination(prev => ({ ...prev, total: res?.data?.data?.length }));
      setPaginatedData(res?.data?.data?.slice(0, currentPagination.pageSize));
    }).catch((err)=> {
      throw new Error("fetchin error",err);
      
    })
  }


    const onPageChange = (page, pageSize) => {
      setCurrentPagination({ current: page, pageSize, total: currentPagination.total });
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setPaginatedData(data?.slice(startIndex, endIndex));
    };

    useEffect(() => {
      const startIndex = (currentPagination.current - 1) * currentPagination.pageSize;
      const endIndex = startIndex + currentPagination.pageSize;
      setPaginatedData(data?.slice(startIndex, endIndex));
    }, [currentPagination, data]);

  useEffect(() => {
    getRecord();
  }, [rectype])

  return (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="w-[50%]">
          <h2 className="my-10 ml-10 font-bold text-2xl">Surveys</h2>
          <div className="space-x-8  ml-10">
            <button className={ rectype !== 'list' ? 'bg-[#E5E8E8] shadow-lg rounded-md py-2 px-5' : 'bg-[#16A085] shadow-lg rounded-md py-2 px-5 text-white'} onClick={() => { setRecType('list')}}>Survey List</button>
            <button className={ rectype === 'list' ? 'bg-[#E5E8E8] shadow-lg rounded-md py-2 px-5' : 'bg-[#16A085] shadow-lg rounded-md py-2 px-5 text-white'} onClick={() => { setRecType('responses')}}>Responses</button>
          </div>
        </div>

        <div className="w-[50%]">
          <form className="flex justify-end mx-auto pr-14">
            <div className="relative w-[50%] mr-6">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  "
                placeholder="Search"
              />
            </div>
            {hasPermission("survey:ADD") && (
              <button
              type="submit"
              className="bg-red-600 text-white rounded-br-full rounded-tl-full rounded-bl-full w-[22%] text-sm"
              onClick={() => { navigate('/CSurveys/0') }}
            >
              Create Survey
            </button>
            )}
            
  
          </form>
        </div>
      </div>

      { rectype === 'list' ? (
        <div className="my-10">
          <table className="border-2 border-gray-700 rounded-2xl w-[95%] mx-auto text-left capitalize">
           <thead>
           <tr className="">
              { fields?.map(field => (<th key={field.id} className="font-bold pt-8 pb-4 px-2">{field}</th>))}
            </tr>
           </thead>

            {paginatedData?.map((r) => (
            <tbody>
                <tr className="odd:bg-white even:bg-gray-100">
                <td className="p-2">{r.description}</td>
                <td className="p-2">{r.recordMasterTypeName}</td>
                <td className="p-2">{r.regionName}</td>
                <td className="p-2">{r.zoneName}</td>
                <td className="p-2">{r.responseDate}</td>
                <td className="p-2">
                  <button className="bg-black text-white py-2 px-6 rounded-lg" onClick={() => { debugger; navigate('/CSurveys/' + r.responseSurvayID) }}>Edit</button>
                </td>
              </tr>
            </tbody>
            ))}
          </table>

          <div className="text-right mt-2">
            <Pagination
              size="small"
              total={currentPagination?.total}
              pageSize={currentPagination.pageSize}
              current={currentPagination.current}
              onChange={onPageChange}
            />
          </div>
        </div>
      ): (<div className="my-10">
        <table className="border-2 border-gray-700 rounded-2xl w-[95%] mx-auto text-left capitalize">
          <thead>
          <tr className="">
            { fields?.map(field => (<th className="font-bold pt-8 pb-4 px-2">{field}</th>))}
          </tr>
          </thead>

          {paginatedData?.map((r) => (
           <tbody>
             <tr className="odd:bg-white even:bg-gray-100">
              <td className="p-2">{r.description}</td>
              <td className="p-2">{r.recordMasterTypeName}</td>
              <td className="p-2">{r.regionName}</td>
              <td className="p-2">{r.zoneName}</td>
              <td className="p-2">{r.responseDate}</td>
              <td className="p-2">
                <button className="bg-black text-white py-2 px-6 rounded-lg" onClick={() => { onSelectRecord(r);setClicked(!click) }}>View All</button>
              </td>
            </tr>
           </tbody>
          ))}
        </table>

        <div className="text-right mt-2">
          <Pagination
            size="small"
            total={currentPagination?.total}
            pageSize={currentPagination.pageSize}
            current={currentPagination.current}
            onChange={onPageChange}
          />
        </div>
      </div>)}
    </>
  );
};

export default ManageSurvey;
