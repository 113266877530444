import React, { useState, useEffect } from "react";
import { useRole } from "../RoleContext.js";
import AttendanceUser from "../components/userManagement/AttendenceUser.jsx";
import UserDialog from "../components/userManagement/dialog.jsx";
import ManageUser from "../components/userManagement/ManageUser.jsx";
import { Spin } from "antd";
import { usePermission } from "../PermissionContext.js";

function UserManagement() {
  const [activeButton, setActiveButton] = useState("ManageUser");
  const [selectedRecord, setSelectRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [click, setClicked] = useState(false);

  const [SearchItem, setSearchItem] = useState("");
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);

  const { permissions } = usePermission();
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/usermanagement") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  // const handleButtonClick = (button) => {
  //     setActiveButton(button);
  // };

  return (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="w-full">
          <form className="flex justify-end  mx-auto pr-14">
            <div className="relative w-[30%] mr-6">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                type="text"
                className="searchByName bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Search by name"
                value={SearchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
            {add && (
              <button
                type="button"
                className="btnForNotes btn"
                onClick={() => {
                  setSelectRecord(null);
                  setClicked(!click);
                }}
              >
                Create User
              </button>
            )}
          </form>
        </div>
      </div>
      {click ? (
        <UserDialog
          hide={() => {
            setClicked(false);
          }}
          setLoading={setLoading}
          data={selectedRecord}
          edit={selectedRecord !== null}
        />
      ) : null}

      <Spin className="" tip="Loading" size="large" spinning={loading}>
        {activeButton === "ManageUser" && (
          <ManageUser
            setSearchItem={setSearchItem}
            SearchItem={SearchItem}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Spin>

      {/* {activeButton === "Attendance" && <AttendanceUser />} */}
    </>
  );
}

export default UserManagement;