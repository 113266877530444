import React, { useEffect, useState, useRef } from "react";
import { getData, postData, putData } from "../../services/NetworkServices";
import fields from "./config";
import axios from "axios";
import { Input, message, Select, Switch } from "antd";
import ToggleSwitchForUserManagement from "./ToggleSwitchForUserManagement";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const token = localStorage.getItem("token");

const UserDialog = ({ data, hide, reloadPage, edit, setLoading }) => {
  const formType = "UserFields";
  const [formData, setFormData] = useState({});
  const [fieldSet, setFieldSet] = useState({});
  const [ZoneData, setZoneData] = useState([]);
  const [disable, setDisabled] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [filteredZoneData, setFilteredZoneData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);


  const [filteredRegionData, setFilteredRegionData] = useState([]);

  const [filteredRecordTypeData, setFilteredRecordTypeData] = useState([]);
  const [reportingDropdownOpen, setReportingDropdownOpen] = useState(false);
  const dropdownRefs = {
    region: useRef(null),
    zone: useRef(null),
    recordType: useRef(null),
    reporting: useRef(null),
  };

  const renameKey = (oldKey, newKey, obj) => {
    if (oldKey !== newKey && obj.hasOwnProperty(oldKey)) {
      const { [oldKey]: old, ...rest } = obj;
      return {
        ...rest,
        [newKey]: old,
      };
    }
    return obj;
  };

  useEffect(() => {
    const apis = [];
    const apiFieldMap = [];

    fields[formType].forEach((item) => {
      if (item.type === "dropdown") {
        apiFieldMap.push(item.key);
        apis.push(
          getData(item.url)
        );
      }
    });
    if (apis.length > 0) {
      let respList = {};
      Promise.all([...apis]).then((response) => {
        response.forEach((item, i) => {
          respList = {
            ...respList,
            [apiFieldMap[i]]:
              apiFieldMap[i] === "userRole" || apiFieldMap[i] === "status"
                ? item?.data.data
                : item?.data,
          };
        });
        setFieldSet(respList);
        setFilteredRegionData(respList.regionIds || []);
        setFilteredRecordTypeData(respList.masterRecordTypeId?.data || []);
      });
    }

    if (data) {
      setFormData({
        ...data,
        regionIds: data.regions ? data.regions.map((region) => region.id) : [],
        zoneIds: data.zones ? data.zones.map((zone) => zone.id) : [],
        masterRecord: data.masterRecord
          ? data.masterRecord.map((masterRecord) => masterRecord.id)
          : [],
        reportingUser: data.reportingUser
          ? data.reportingUser.map((e) => e.id)
          : [],
        status: (parseInt(data.isActiveUser)+1),
        activeDirectoryFlag: data.activeDirectoryFlag,
      });
    }
    // console.log("formData",formData)
  }, [data]);
  // console.log("props.data",props.data)

  // console.log("Record Type ID:", formData.masterRecordTypeId);

  useEffect(() => {
    const getZoneData = async () => {
      if (formData.regionIds?.length > 0) {
        const zonePromises = formData.regionIds.map((regionId) =>
          getData(`Zone/zones/${regionId}`)
        );
        const zoneResponses = await Promise.all(zonePromises);
        const allZones = zoneResponses.reduce((acc, response) => {
          return acc.concat(response.data);
        }, []);
        setZoneData(allZones);
        setFilteredZoneData(allZones);
      }
    };
    getZoneData();
  }, [formData.regionIds]);

  const fetchAccountData = async (email) => {
    try {
      const res = await getData(`account/VerifyADAccount?Email=${email}`);
      if (res && res.data) {
        const fetchedData = res.data.data;
        setFormData((prevFormData) => ({
          ...prevFormData,
          username: fetchedData.username,
          fullName: fetchedData.fullName,
          contactNo: fetchedData.contact,
        }));
        setIsDataFetched(true); // Mark as fetched
        message.success("Account data fetched successfully!");
      } else {
        message.warning("No account data found.");
      }
    } catch (error) {
      message.error("Failed to fetch account data.");
    }
  };

  const handleSearchZone = (value) => {
    if(!value){
      setFilteredZoneData(ZoneData)
      return;
    }
    const filtered = ZoneData.filter((zone) =>
      zone.title.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredZoneData(filtered);
  };
  const handleSearchRegion = (value) => {
    if(!value){
      setFilteredRegionData(fieldSet.regionIds || [])
      return;
    }
    const filtered = (fieldSet.regionIds || []).filter((region) =>
      region.title.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredRegionData(filtered);
  };

  const handleSearchRecordType = (value) => {
    if(!value){
     setFilteredRecordTypeData  (fieldSet.masterRecordTypeId?.data || [])
      return;
    }
    const filtered = (fieldSet.masterRecordTypeId?.data || []).filter(
      (recordType) =>
        recordType.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredRecordTypeData(filtered);
  };

  function validatePassword(password) {
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
      return false;
    }
    return true;
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevFormData) => {
        const newArray = checked
          ? [...(prevFormData[name] || []), parseInt(value)]
          : prevFormData[name].filter((item) => item !== parseInt(value));
        return {
          ...prevFormData,
          [name]: newArray,
        };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getPlaceholder = (field) => {
    if (field == "zoneIds") {
    }
    if (Array.isArray(formData[field]) && formData[field]?.length > 1) {
      return "Multiple selection";
    }

    const selectedOption = Array.isArray(fieldSet[field])
      ? fieldSet[field]?.find((option) => option?.id === formData[field]?.[0])
      : null;

    return selectedOption
      ? selectedOption.name ||
          selectedOption.title ||
          selectedOption.username ||
          selectedOption.id
      : "Select";
  };
  // console.log("formData.Status" ,formData)
  const onSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      reportingUser: [Number(formData.reportingUser)],
      isActive: (parseInt(formData.status)-1),
      zoneIds: formData.zoneIds,
      regionIds: formData.regionIds,
      masterRecordType: formData?.masterRecord,
      contactNo: formData.contactNo,
      deviceInfo: formData.deviceInfo,
      email: formData.email,
      password: formData.password ? formData.password : "",
      userRole: formData.userRole,
      username: formData.username,
      fullName: formData.fullName,
      activeDirectoryFlag: formData.activeDirectoryFlag,
    };
    try {
      let res;
      if (!edit) {
        // const token = localStorage.getItem("token")
        res = await postData("account/create-user", payload);
      } else {
        res = await putData(`User-Data/${data?.id}`,payload);
        setIsDataFetched(res.data.data);
      }

      if (res.status === 200) {
        message.success(`User ${!edit ? "Created" : "Edited"} Successfully`);
        setLoading(true);
        hide();
      } else {
        message.error("Operation failed");
      }
    } catch (error) {
      message.error(`User ${!edit ? "Creation" : "Edit"} Failed`);
    }
  };

  const statuses = [
    {
      id: 1,
      value: "Inactive",
    },
    {
      id: 2,
      value: "Active",
    },
  ];

  // const handleBlur = (e) => {
  //   const email = e.target.value;

  //   if (disable && ["username", "fullName", "contactNo"].some(field => formData[field] === "")) {
  //     fetchAccountData(email);
  //   }
  // };

  // console.log("formData", formData);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto">
        <div className="flex items-center justify-center  h-screen text-center sm:items-center sm:p-0">
          <form
            onSubmit={onSubmit}
            className="flex flex-col w-[800px] gap-5 relative transform h-[600px] rounded-lg bg-white text-left shadow-xl transition-all p-10 sm:my-8 overflow-auto"
          >
            {/* <div className="">

          </div> */}
            <div className="font-bold text-xl text-center">
              {data === null ? "New User" : "Edit User"}
            </div>
            <div className="text-center  flex justify-center items-center">
            <ToggleSwitchForUserManagement
                setFormData={setFormData}
                formData={formData}
                setDisabled={setDisabled}
                disable={disable}
                isDataFetched={isDataFetched}
                isEditing={edit} // Pass whether it's an edit operation
              />
            </div>
            <div className="h-auto mt-2 grid gap-4 gap-y-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
              {fields.UserFields.map((field) => (
                <div key={field.key}>
                  {field.type !== "dropdown" ? (
                    <div className="relative">
                      <Input
                        allowClear
                        autoComplete="new-password"
                        autoFocus={false}
                        placeholder={
                          field.key === "fullName"
                            ? "Full Name"
                            : `Type ${field.key}`
                        }
                        type={
                          field.key === "password"
                            ? showPassword
                              ? "text"
                              : "password"
                            : field.type || "text"
                        }
                        required={field.required}
                        name={field.key}
                        value={formData[field.key] || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (field.key === "email" && formData.activeDirectoryFlag) {
                            const email = e.target.value;
                            fetchAccountData(email); 
                          }
                        }}
                        disabled={
                          field.key && (
                            (!edit && formData.activeDirectoryFlag && ["username", "password", "fullName", "contactNo"].includes(field.key)) ||
                            (edit && formData.activeDirectoryFlag && ["username", "password", "fullName", "contactNo", "email"].includes(field.key))
                          )
                        }
                      />
                      {field.key === "password" &&
                        (showPassword ? (
                          <MdOutlineVisibility
                            className="absolute cursor-pointer bottom-4 right-8 bg-white font-bold z-10"
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className="absolute cursor-pointer bottom-4 right-8 bg-white font-bold z-10"
                            onClick={() => setShowPassword(true)}
                          />
                        ))}
                      <p className="absolute bottom-10 left-6 bg-white font-bold z-10">
                        {field.name}
                      </p>
                    </div>
                  ) : (
                    <div className="relative">
                      {field.name !== "Zone*" && (
                        <label className="absolute bottom-9 bg-white left-9 font-bold z-10">
                          {field.name}
                        </label>
                      )}
                      {field.name === "Zone*" ? (
                        <div className=" relative">
                          <p className="absolute bottom-9 left-7 bg-white z-10 font-bold">
                            Zone*
                          </p>
                          <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select Zone"
                            value={formData.zoneIds || []}
                            onSearch={handleSearchZone}
                            onChange={(value) => {
                              if (value.includes("all")) {
                                const allZoneIds = filteredZoneData.map(
                                  (zoneId) => zoneId.id
                                );

                                // Check if allRegionIds are already selected, then unselect them
                                if (
                                  formData.zoneIds?.length === allZoneIds.length
                                ) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    zoneIds: [],
                                  }));
                                } else {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    zoneIds: allZoneIds,
                                  }));
                                }
                              } else {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  zoneIds: value,
                                }));
                              }

                              // if(formData.regionIds !== formData.zoneIds){
                              //   setFormData((prevState)=>({
                              //     ...prevState,
                              //     zoneIds: null
                              //   }))
                              // }
                            }}
                            filterOption={false}
                          >
                            <Select.Option key="all" value="all">
                              Select All
                            </Select.Option>
                            {filteredZoneData.map((zone) => (
                              <Select.Option key={zone.id} value={zone.id}>
                                {zone.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : field.name === "Region*" ? (
                        <>
                          <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select Region"
                            value={formData.regionIds || []}
                            onSearch={handleSearchRegion}
                            onChange={(value) => {
                              if (value.includes("all")) {
                                const allRegionIds = filteredRegionData.map(
                                  (region) => region.id
                                );

                                // Check if allRegionIds are already selected, then unselect them
                                if (
                                  formData.regionIds?.length ===
                                  allRegionIds.length
                                ) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    regionIds: [],
                                  }));
                                } else {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    regionIds: allRegionIds,
                                  }));
                                }
                              } else {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  regionIds: value,
                                }));
                              }

                              // Reset zoneIds if regionIds change
                              if (value !== formData.regionIds) {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  zoneIds: null,
                                }));
                              }
                            }}
                            filterOption={false}
                          >
                            <Select.Option key="all" value="all">
                              Select All
                            </Select.Option>
                            {filteredRegionData.map((region) => (
                              <Select.Option key={region.id} value={region.id}>
                                {region.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </>
                      ) : field.name === "Record Type*" ? (
                        <Select
                          mode="multiple"
                          showSearch
                          placeholder="Select Record Type"
                          value={formData.masterRecord || []}
                          onSearch={handleSearchRecordType}
                          onChange={(value) => {
                            if (value.includes("all")) {
                              const allRecordTypeIds =
                                filteredRecordTypeData.map(
                                  (recordTypeId) => recordTypeId.id
                                );

                              // Check if allRegionIds are already selected, then unselect them
                              if (
                                formData.masterRecord?.length ===
                                allRecordTypeIds.length
                              ) {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  masterRecord: [],
                                }));
                              } else {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  masterRecord: allRecordTypeIds,
                                }));
                              }
                            } else {
                              setFormData((prevState) => ({
                                ...prevState,
                                masterRecord: value,
                              }));
                            }
                          }}
                          filterOption={false}
                        >
                          <Select.Option key="all" value="all">
                            Select All
                          </Select.Option>
                          {filteredRecordTypeData.map((recordType) => (
                            <Select.Option
                              key={recordType.id}
                              value={recordType.id}
                            >
                              {recordType.name}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : field.name === "Reporting to" ? (
                        <div
                          ref={dropdownRefs.reporting}
                          // className="outline-red-200 py-3 px-6 mt-2 w-full border-2 border-red-200 rounded-full cursor-pointer"
                          onClick={() =>
                            setReportingDropdownOpen(!reportingDropdownOpen)
                          }
                        >
                          {getPlaceholder("reportingUser")}
                          {reportingDropdownOpen && (
                            <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                              <ul className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {(fieldSet.reportingUser || []).map(
                                  (reporting) => (
                                    <li
                                      key={reporting.id}
                                      className="flex items-center p-2"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-checkbox"
                                        name="reportingUser"
                                        value={reporting.id}
                                        checked={formData?.reportingUser?.includes(
                                          reporting.id
                                        )}
                                        onChange={handleChange}
                                      />
                                      <label className="ml-2">
                                        {reporting.name || reporting.username}
                                      </label>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : (
                        <Select
                          required={field.required}
                          placeholder={field.name}
                          name={field.key}
                          onChange={(value) => {
                            // console.log("loading => check", formData, field);
                            handleChange({
                              target: {
                                name: field.key,
                                value,
                                type: "select",
                              },
                            });
                          }}
                          value={formData[field.key] || ""}
                        >
                          {/* {!edit && (
                            <Select.Option value="" disabled>
                              Select the {field.name}
                            </Select.Option>
                          )} */}

                          {field.name === "Status*" ? (
                            <>
                              {statuses?.map((status, index) => (
                                <Select.Option key={status.index} value={status.id}>
                                  {status.value}
                                </Select.Option>
                              ))}
                            </>
                          ) : (
                            Array.isArray(fieldSet[field.key]) &&
                            fieldSet[field.key].map((r) => {
                              return (
                                <Select.Option
                                  key={r.id}
                                  value={field.name === "Role*" ? r.name : r.id}
                                >
                                  {r.name || r.title || r.username}
                                </Select.Option>
                              );
                            })
                          )}
                        </Select>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="bg-gray-50 px-4 py-3 mt-5 flex w-full justify-center gap-5">
              <button
                type="submit"
                className="btnSubmit btn"
                style={{ width: "100px" }}
              >
                {!edit ? "Create" : "Edit"}
              </button>
              <button
                type="button"
                onClick={() => hide()}
                className="btnCancel btn"
                style={{ width: "100px" }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDialog;
