import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Space,
  Button,
  Popover,
  Spin,
  Popconfirm,
  message,
  Select,
} from "antd";
import { Pagination } from "antd";
import { deleteData, getData } from "../services/NetworkServices.js";
import { useRole } from "../RoleContext.js";
import MediaPopUp from "../components/media/MediaPopUp.jsx";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import MediaCard from "../components/media/MediaCard.js";
import { usePermission } from "../PermissionContext.js";

const MediaUser = () => {
  const [SearchItem, setSearchItem] = useState("");
  const [clicked, setClicked] = useState(false);
  const [click, setClick] = useState(1);
  const [rectype, setRecType] = useState("user");
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [regionData, setRegionData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [filterRegionData, setFilterRegionData] = useState([]);
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [filterMasterData, setFilterMasterData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedMaster, setSelectedMaster] = useState("");
  const [form, setForm] = useState({ type: 0, region: 0, zone: 0 });
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerApi, setTriggerApi] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 0,
    total: 0,
  });

  const onPageChange = (page, pageSize) => {
    setCurrentPagination({
      current: page,
      pageSize: pageSize,
      total: currentPagination.total,
    });
    setTriggerApi((prev) => !prev);
  };

  const getMedData = async (str) => {
    try {
      setLoading(true);
      setTriggerApi(false);
      const res = await getData(
        `media-management/filteredMediaData?pageNumber=${
          currentPagination.current
        }&${str ? str : ""}`
      );
      if (res?.data?.data) {
        setMediaData(res?.data?.data?.mediaDto);
        setCurrentPagination((p) => ({
          ...p,
          total: res?.data?.data?.totalItems,
          pageSize: res?.data?.data?.pageSize,
        }));
        console.log("res media data", res);
      }
    } catch (error) {
      console.error("Failed to fetch media data", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let str = "";
    const r = [];
    setCurrentPagination({ current: 1, ...currentPagination });
    if (form.region) {
      r.push(`regionId=${form.region}`);
    }
    if (form.zone) {
      r.push(`zoneId=${form.zone}`);
    }
    if (form.type) {
      r.push(`recordTypeId=${form.type}`);
    }
    if (form) {
      r.push(`status=${true}`);
    }

    // If no filter is applied, don't pass any query string to fetch all cards
    if (r.length > 0) {
      str = r.join("&");
    }

    getMedData(str ? str : ""); // Pass empty string if no filters
  }, [form, triggerApi, currentPagination.current]);

  // const downRec = (id) => {
  //   getData(`media-management/download/${id}`).then(() => {
  //     getMedData();
  //   });
  // };

  const delRec = (id) => {
    deleteData(`media-management/deleteMedia/${id}`).then(() => {
      getMedData();
    });
  };

  const editRec = (id) => {
    getData(`media-management/medias/${id}`).then((res) => {
      setSelectedEditData(res.data.data);
      setClicked(!clicked);
      console.log("fetch media data", res);
      getMedData();
    });
  };

  const content = (item) => (
    <div className="flex flex-col justify-center items-center gap-2">
      <p className="cursor-pointer">
        <Popconfirm
          title="Delete the Media"
          description="Are you sure you want to delete this Media?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            delRec(item.id);
            message.success("Media Deleted");
          }}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      </p>
      <p>
        <Button
          onClick={() => {
            editRec(item.id);
          }}
          info
        >
          edit
        </Button>
      </p>
    </div>
  );

  useEffect(() => {
    getData("region").then((res) => {
      const sortedRegionData = res?.data?.sort((a, b) => {
        return a?.title?.localeCompare(b?.title);
      });
      setRegionData(sortedRegionData);
      setFilterRegionData(sortedRegionData);
    });
  }, []);

  useEffect(() => {
    if (form.region) {
      getData(`Zone/zones/${form.region}`).then((res) => {
        const sortedZoneData = res?.data?.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setZoneData(sortedZoneData);
        setFilterZoneData(sortedZoneData);
      });
    }
  }, [form.region]);

  useEffect(() => {
    getData("master-record-type").then((res) => {
      const sortedMasterData = res?.data?.data?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
      setMasterData(sortedMasterData);
      setFilterMasterData(sortedMasterData);
    });
  }, []);

  useEffect(() => {
    let filteredData = mediaData.filter((item) => {
      if (item?.title?.toLowerCase().includes(SearchItem)) return item;
      // else{
      //   return item;
      // }
    })
    setMediaData(filteredData);
  }, [SearchItem]);


  const { permissions } = usePermission();
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/journey") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between w-full items-center">
          <div className="w-[70%]">
            <h2 className="my-9 ml-10 font-bold text-2xl">Media Management</h2>
          </div>

          <div className="w-[40%] ">
            <div className="flex justify-end mx-auto pr-10">
              <div className="relative w-[50%] mr-6">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  "
                  placeholder="Search"
                  required
                  value={SearchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </div>
              {add && (
                <button
                  type="submit"
                  className="btnSubmit btn w-3.5"
                  onClick={() => {
                    setClicked(!clicked);
                    setSelectedEditData("");
                  }}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between w-full items-center ">
          <div className="w-full inline">
            <div className="space-x-8  ml-10 flex">
              <div className="flex gap-24 w-full ">
                <div className="relative w-full">
                  <Select
                    allowClear
                    placeholder="Select the Region"
                    value={selectedRegion}
                    onChange={(value) => {
                      if (value === undefined) {
                        // Reset all filters when region is cleared
                        setSelectedRegion("");
                        setSelectedZone("");
                        setSelectedMaster("");
                        setForm({ type: 0, region: 0, zone: 0 });
                      } else {
                        const selectedRegion = filterRegionData.find(
                          (item) => item.id === value
                        );
                        setSelectedRegion(selectedRegion?.title || "");
                        setSelectedZone("");
                        setSelectedMaster("");
                        setForm((prev) => ({
                          ...prev,
                          region: selectedRegion.id,
                          zone: null,
                        }));
                      }
                    }}
                    filterOption={(input, option) => {
                      const label = option.label || option.children;
                      return label
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {filterRegionData.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.title}
                      >
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>

                  <p className="absolute bottom-9 left-8 font-bold bg-white">
                    Region*
                  </p>
                </div>
                <div className="relative w-full">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select the Zone"
                    value={selectedZone}
                    onChange={(value) => {
                      if (value === undefined) {
                        setSelectedZone("");
                        setForm((prev) => ({ ...prev, zone: null }));
                      } else {
                        const selectedZone = filterZoneData.find(
                          (item) => item.id === value
                        );
                        setSelectedZone(selectedZone?.title || "");
                        setForm((prev) => ({
                          ...prev,
                          zone: selectedZone?.id,
                        }));
                      }
                    }}
                    disabled={!selectedRegion}
                    filterOption={(input, option) => {
                      const label = option.label || option.children;
                      return label
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {filterZoneData.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.title}
                      >
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>

                  <p className="absolute bottom-9 left-8 font-bold bg-white">
                    Zone*
                  </p>
                </div>

                <div className="relative w-full">
                  <Select
                    showSearch
                    placeholder="Select the Record Type"
                    value={selectedMaster}
                    onChange={(value) => {
                      const selectedMaster = filterMasterData.find(
                        (item) => item.id === value
                      );
                      setSelectedMaster(selectedMaster?.name);
                      setForm((prev) => ({
                        ...prev,
                        type: selectedMaster?.id,
                      }));
                    }}
                    filterOption={(input, option) => {
                      return option.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {(filterMasterData || []).map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <p className="absolute bottom-9 left-8 font-bold bg-white">
                    Record Type
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* cards starts here */}
        <div className="mt-3 mb-3">
          <div className="text-right mt-2 flex justify-end">
            <span className="">Page</span>
            <Pagination
              size="small"
              total={currentPagination.total}
              pageSize={currentPagination.pageSize || 10}
              current={currentPagination.current}
              onChange={onPageChange}
            />
          </div>
          <Spin
            className="flex justify-center items-center  h-96"
            tip="Loading"
            size="large"
            spinning={loading}
          />
          <div className="flex gap-10 flex-wrap justify-center">
            {(mediaData || []).map((item, index) => (
              <MediaCard
                key={item.id}
                item={item}
                inedx={index}
                content={content}
              />
            ))}
          </div>
        </div>
        <div>
          {clicked ? (
            <MediaPopUp
              setLoading={setLoading}
              setTriggerApi={setTriggerApi}
              hide={() => setClicked(!clicked)}
              edit={selectedEditData}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MediaUser;
