import React, { useState, useEffect } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { getData, postData, putData } from "../../services/NetworkServices";
import LocationsInput from "./LocationsInput";
import ToggleSwitch from "./ToggleSwitch";
import { Form, message } from "antd";
import { SiGooglemaps } from "react-icons/si";
import {
  useJsApiLoader,
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import Modal from "react-modal";
import { Navigate, useNavigate } from "react-router-dom";
// const center = { lat: 48.8548, lng: 2.294 };
const containerStyle = {
  width: "766px",
  height: "400px",
};

const center = {
  lat: 24.8607,
  lng: 67.0011,
};

const NewLocation2 = ({
  ModalOpen,
  setModalOpen,
  MainData,
  setMainData,
  setCompleteData,
  Edit,
  setEdit,
  EditData,
  setEditData,
  setDataChanged,
  DataChanged,
  APIProvider,
  Map,
  initialPosition,
}) => {
  const [LocationData, setLocationData] = useState({
    id: 0,
    title: "",
    subLocalityId: null,
    masterRecordTypeID: 0,
    latitude: 0,
    longitude: 0,
    noOfPatientsOrStudents: 0,
    contactNumber: 0,
    contactName: "",
    radius: 0,
    locationExemptionFlag: true,
    localityId: null,
    townId: null,
    beltId: null,
    areaId: null,
    zoneId: null,
    regionId: null,
    status: true,
  });

  const [recordTypes, setRecordTypes] = useState([]);
  const [RegionData, setRegionData] = useState([]);
  const [ZoneData, setZoneData] = useState([]);
  const [AreaData, setAreaData] = useState([]);
  const [TownData, setTownData] = useState([]);
  const [LocalityData, setLocalityData] = useState([]);
  const [SubLocalityData, setSubLocalityData] = useState([]);
  const [BeltData, setBeltData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [latitudeClicked, setLatitudeClicked] = useState("");
  const [markerPosition, setMarkerPosition] = useState(
    initialPosition || center
  );
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    if (initialPosition) {
      setMarkerPosition(initialPosition);
    }
  }, [initialPosition]);

  const handleMapClick = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    console.log("LATITUDE IS -- ", newLat, "LONGITUDE IS -- ", newLng);

    setSelectedPosition({ lat: newLat, lng: newLng });
    setLocationData({ ...LocationData, latitude: newLat, longitude: newLng });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const onLoad = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setSelectedPlace(place);

      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setSelectedPosition({ lat, lng });
        setLocationData({ ...LocationData, latitude: lat, longitude: lng });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    const fetchDropDownData = async () => {
      let record = await getData(`master-record-type`);
      const sortedRecordTypes = record.data.data.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
      setRecordTypes(sortedRecordTypes);

      let region = await getData(`region`);
      const sortedRegionData = region.data.sort((a, b) => {
        return a?.title?.localeCompare(b?.title);
      });
      setRegionData(sortedRegionData);
    };
    fetchDropDownData();
  }, [LocationData]);

  useEffect(() => {
    if (Edit) setLocationData(EditData);
    else {
      setLocationData({
        id: 0,
        title: "",
        subLocalityId: null,
        masterRecordTypeID: 0,
        latitude: "",
        longitude: "",
        noOfPatientsOrStudents: 0,
        contactNumber: 0,
        contactName: "",
        radius: 0,
        locationExemptionFlag: true,
        localityId: null,
        townId: null,
        beltId: null,
        areaId: null,
        zoneId: null,
        regionId: null,
        status: true,
      });
    }
  }, [Edit]);

  useEffect(() => {
    const getZone = async () => {
      if (LocationData.regionId) {
        let zoneData = await getData(`Zone/zones/${LocationData.regionId}`);
        const sortedZone = zoneData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setZoneData(sortedZone);
      } else {
        setZoneData([]);
      }
    };
    getZone();
  }, [LocationData.regionId]);

  useEffect(() => {
    const getAreaData = async () => {
      if (LocationData.zoneId) {
        let areaData = await getData(`Area/areas/${LocationData.zoneId}`);
        const sortedArea = areaData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setAreaData(sortedArea);
      } else {
        setAreaData([]);
      }
    };
    getAreaData();
  }, [LocationData.zoneId]);

  useEffect(() => {
    const getBeltData = async () => {
      if (LocationData.areaId) {
        let beltData = await getData(`Belt/belts/${LocationData.areaId}`);
        const sortedBelt = beltData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setBeltData(sortedBelt);
      } else {
        setBeltData([]);
      }
    };
    getBeltData();
  }, [LocationData.areaId]);

  useEffect(() => {
    const getTownData = async () => {
      if (LocationData.beltId) {
        let townData = await getData(`Town/Towns/${LocationData.beltId}`);
        const sortedTown = townData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setTownData(sortedTown);
      } else {
        setTownData([]);
      }
    };
    getTownData();
  }, [LocationData.beltId]);

  useEffect(() => {
    const getLocalitiesData = async () => {
      if (LocationData.townId) {
        let localData = await getData(
          `locality/localities/${LocationData.townId}`
        );
        const sortedLocal = localData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setLocalityData(sortedLocal);
      } else {
        setLocalityData([]);
      }
    };
    getLocalitiesData();
  }, [LocationData.townId]);

  useEffect(() => {
    const getSubLocalitiesData = async () => {
      if (LocationData.localityId) {
        let SublocalData = await getData(
          `sublocality/sublocalities/${LocationData.localityId}`
        );
        const sortedSubLocal = SublocalData.data.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        });
        setSubLocalityData(sortedSubLocal);
      } else {
        setSubLocalityData([]);
      }
    };
    getSubLocalitiesData();
  }, [LocationData.localityId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if ((name === "latitude" || name === "longitude") && !isValidFloatingPoint(value)) {
    //   message.error(${name} must be a valid decimal number);
    //   return;
    // }
    setLocationData((prev) => ({ ...prev, [name]: value }));
    //setLocationData({ ...LocationData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!isValidFloatingPoint(LocationData.latitude.toString())) {
    //   message.error("Latitude must be a valid floating-point number");
    //   return;
    // }

    // if (!isValidFloatingPoint(LocationData.longitude.toString())) {
    //   message.error("Longitude must be a valid floating-point number");
    //   return;
    // }

    let res;
    if (!Edit) {
      if (
        LocationData.zoneId &&
        LocationData.areaId &&
        LocationData.townId &&
        LocationData.beltId &&
        LocationData.localityId &&
        LocationData.subLocalityId &&
        LocationData.regionId
      ) {
        res = await postData("BusinessUnits/AddBusinessUnit", LocationData);
        if (res.status == 200) {
          message.success("Location added successfully");
          setDataChanged(!DataChanged);
          setModalOpen(false);
        } else {
          message.error("Location not added.");
        }
      } else {
        message.error("Fill all the required fields.");
      }
    } else {
      res = await putData("BusinessUnits/UpdateBusinessUnit", LocationData);
      if (res.status == 200) {
        message.success("Location Edited Successfully");
        setDataChanged(!DataChanged);
        setEdit(false);
        setModalOpen(false);
      } else {
        message.error("Location not added.");
      }
    }
  };

  // const isValidFloatingPoint = (value) => {
  //   const floatingPointRegex = /^-?\d+\.\d+$/;
  //   return floatingPointRegex.test(value);
  // };

  const openModal = (val) => {
    setModalIsOpen(true);
    setLatitudeClicked(val);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={true} className="w-4/5 mt-10">
        <form
          onSubmit={handleSubmit}
          className="ml-60"
          // className="z-[500] fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
        >
          <div className=" absolute w-8/12 bg-white flex flex-col gap-7 rounded-xl p-6 border border-red-600">
            <p className="text-lg font-extrabold text-center">New Location</p>

            <div className="flex gap-3">
              <LocationsInput
                inputField={"Name*"}
                placeholder={"Enter Name"}
                isDropDown={false}
                name={"title"}
                handleChange={handleChange}
                value={LocationData.title}
              />
              <LocationsInput
                inputField={"Record Type*"}
                placeholder={"Select the Record type"}
                name={"masterRecordTypeID"}
                handleChange={handleChange}
                fetchDropDownData={recordTypes}
                value={LocationData.masterRecordTypeID}
              />
              <LocationsInput
                inputField={"Region*"}
                placeholder={"Select the Region"}
                name={"regionId"}
                handleChange={handleChange}
                fetchDropDownData={RegionData}
                value={LocationData.regionId}
              />
            </div>

            <div className="flex gap-5">
              <LocationsInput
                inputField={"Zone*"}
                placeholder={"Enter Zone"}
                name={"zoneId"}
                handleChange={handleChange}
                fetchDropDownData={ZoneData}
                value={LocationData.zoneId}
              />
              <LocationsInput
                inputField={"Area*"}
                placeholder={"Select the Area"}
                name={"areaId"}
                handleChange={handleChange}
                fetchDropDownData={AreaData}
                value={LocationData.areaId}
              />
              <LocationsInput
                inputField={"Belt*"}
                placeholder={"Select the Belt"}
                name={"beltId"}
                handleChange={handleChange}
                fetchDropDownData={BeltData}
                value={LocationData.beltId}
              />
            </div>

            <div className="flex gap-5">
              <LocationsInput
                inputField={"Town*"}
                placeholder={"Select Town"}
                name={"townId"}
                handleChange={handleChange}
                fetchDropDownData={TownData}
                value={LocationData.townId}
              />
              <LocationsInput
                inputField={"Locality*"}
                placeholder={"Select the Locality"}
                name={"localityId"}
                handleChange={handleChange}
                fetchDropDownData={LocalityData}
                value={LocationData.localityId}
              />
              <LocationsInput
                inputField={"Sub-Locality*"}
                placeholder={"Select the Sub-Locality"}
                name={"subLocalityId"}
                handleChange={handleChange}
                fetchDropDownData={SubLocalityData}
                value={LocationData.subLocalityId}
              />
            </div>

            <div className="flex gap-5">
              <LocationsInput
                inputField={"Lat*"}
                placeholder={"Enter the Latitude"}
                isDropDown={false}
                name={"latitude"}
                handleChange={handleChange}
                value={LocationData.latitude}
                openModal={() => openModal("lat")}
              />

              <LocationsInput
                inputField={"Long*"}
                placeholder={"Enter the Longitude"}
                isDropDown={false}
                name={"longitude"}
                handleChange={handleChange}
                value={LocationData.longitude}
                openModal={() => openModal("long")}
              />
              <LocationsInput
                inputField={"Radius*(Meters)"}
                placeholder={"Enter the Radius"}
                isDropDown={false}
                name={"radius"}
                handleChange={handleChange}
                value={LocationData.radius}
              />
            </div>

            <div className=" px-32 flex gap-5 justify-center items-center ">
              <LocationsInput
                inputField={"Contact Name"}
                placeholder={"Enter Contact Name"}
                isDropDown={false}
                name={"contactName"}
                handleChange={handleChange}
                required={false}
                value={LocationData.contactName}
              />
              <LocationsInput
                inputField={"Contact No."}
                placeholder={"Enter Contact Number"}
                isDropDown={false}
                name={"contactNumber"}
                type="tel"
                title="Format: 123-4567890"
                handleChange={handleChange}
                required={false}
                value={LocationData.contactNumber}
              />
            </div>

            <p className="flex justify-center ">
              <ToggleSwitch
                label={"Location Exemption"}
                LocationData={LocationData}
                setLocationData={setLocationData}
                key={LocationData.id}
              />
            </p>
            <div className="flex justify-center gap-x-10 items-center pb-2">
              <button className="btnSubmit btn w-3.5" type="submit">
                {Edit ? "Edit Location" : "Add Location"}
              </button>
              <button
                className="btnCancel btn"
                onClick={(e) => {
                  e.preventDefault();
                  {
                    Edit && setEdit(false);
                  }
                  setModalOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        className="w-6/12 mt-24 ml-96 flex flex-col justify-center items-center border-red-600 border bg-white rounded-lg pt-9"
      >
        {isLoaded && (
          <>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder="Search for places"
                // style={{
                //   boxSizing: `border-box`,
                //   border: `1px solid transparent`,
                //   width: `full`,
                //   height: `32px`,
                //   padding: `0 12px`,
                //   borderRadius: `3px`,
                //   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                //   fontSize: `14px`,
                //   outline: `none`,
                //   textOverflow: `ellipses`,
                //   position: "absolute",
                //   left: "50%",
                //   marginLeft: "-120px",
                //   top: "10px",
                // }}
                className="w-[350px] rounded-full outline-none items-center h-[50px] border border-red-200 relative bottom-3 px-2"
              />
            </Autocomplete>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onClick={handleMapClick}
              className="pt-8 w-full"
            >
              {selectedPosition &&
                selectedPosition.lat &&
                selectedPosition.lng && <Marker position={selectedPosition} />}
            </GoogleMap>
          </>
        )}

        <div className="flex flex-row gap-3 mb-12">
          <button
            onClick={closeModal}
            className="btnSubmit btn w-3.5 mt-8"
            type="submit"
          >
            Add {latitudeClicked == "lat" ? " Latitude" : " Longitude"}
          </button>
          <button onClick={closeModal} className="btnCancel btn mt-8">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default NewLocation2;
