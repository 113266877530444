import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  getData,
  getDataForPermission,
  getDataForPermisson,
  postData,
} from "../../services/NetworkServices";
import { usePermission } from "../../PermissionContext";
import { message } from "antd";

export default function Login({ setRoleId ,setToken}) {
  const [visible, setVisible] = useState(false);
  const [pop, setPop] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const navigate = useNavigate();

  const { permissions, setPermissions } = usePermission();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      username: e.target.username.value,
      password: e.target.password.value,
    };

    postData(`account/login`, payload)
      .then(async (res) => {
        // console.log("loading => ressss login", res);

        if (res && res.name === "AxiosError") {
          let response = res.response.data.Message;
          let jsonString = response.substring(response.indexOf("{"));
          let jsonRes = JSON.parse(jsonString);
          throw new Error(jsonRes.message);
        } else {
          // Handle successful login
          localStorage.setItem("token", res.data.data.jwtToken);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          localStorage.setItem("userRole", res.data.data.userRole);
          localStorage.setItem("roleId", res.data.data.roleId);
          localStorage.setItem("zones", JSON.stringify(res.data.data.zones));
          setRoleId(res.data.data.roleId);
          setToken(res.data.data.jwtToken)

          const roleId = res?.data?.data?.roleId;

          const token = res?.data?.data?.jwtToken;

          getData(`UserPermission/role-accesses/${roleId}`, token)
            .then((res) => {
              console.log("loading => get data res", res);

              setPermissionData(res?.data?.data);
              setPermissions(res?.data?.data);
              navigate("/");
              message.success("Logged in Successfully");
            })
            .catch(() => navigate("/login"));
        }
      })
      .catch((error) => {
        console.log("Error from catch:", error);
        message.error(`Login Failed: ${error.message || "Unknown error"}`);
      });
  };

  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className="flex justify-center items-center w-11/12 h-full">
        <div className="flex justify-center items-center w-2/5 h-[97vh] rounded-l-lg bg-slate-700">
          {/* Logo */}
          <img
            src="/assets/logo.png"
            alt="Logo"
            className="object-cover h-full w-full rounded-l-lg"
          />
        </div>
        <div className="w-3/5 flex justify-center items-center flex-col">
          {/* Fields */}
          <h1 className="flex justify-center items-center font-bold text-4xl">
            Login
          </h1>
          <form
            className="w-full flex justify-center items-center flex-col"
            onSubmit={handleSubmit}
          >
            <div className="w-2/4 flex justify-center items-center mt-10 relative">
              <input
                type="text"
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Username"
                name="username"
              />
              <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                USERNAME
              </div>
              <div className="absolute left-5 text-sm font-bold">
                <img src="/assets/User.png" alt="" />
              </div>
            </div>
            <div className="w-2/4 flex justify-center items-center mt-10 relative">
              <input
                type={visible ? "text" : "password"}
                className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                placeholder="Enter Your Password"
                name="password"
              />
              <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                PASSWORD
              </div>
              <div className="absolute left-5 text-sm font-bold">
                <img src="/assets/lock.png" alt="" />
              </div>
              <div
                className="absolute right-5 text-sm"
                onClick={() => setVisible(!visible)}
              >
                {visible ? (
                  <FaEye className="text-2xl text-gray-300" />
                ) : (
                  <FaEyeSlash className="text-2xl text-gray-300" />
                )}
              </div>
            </div>
            <div className="flex justify-end items-center w-2/4">
              <p
                onClick={() => setPop(true)}
                className="mr-2 text-[#1C838A] underline font-semibold cursor-pointer"
              >
                Forgot Password?
              </p>
            </div>
            <div className="flex justify-center items-center mt-10">
              <button
                type="submit"
                className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      {pop ? (
        <div className="fixed inset-0 bg-blend-darken backdrop-brightness-50">
          <div className="flex items-center justify-center h-[100vh]">
            <div className="bg-white md:w-1/3 min-w-max border-2 p-10 rounded-lg">
              <div className="relative w-full flex flex-col gap-10 justify-center items-center text-center p-10">
                <p className="text-2xl font-bold">Forgot Password</p>
                <div className="relative w-full">
                  <input
                    className="outline-red-200 py-3 px-14 w-full border-2 border-red-200 rounded-full"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <MdOutlineMailOutline className="absolute bottom-4 left-4 text-gray-400" />
                  <p className="absolute bottom-10 left-8 font-bold bg-white">
                    Email
                  </p>
                </div>
                <div className="flex gap-10">
                  <button className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005]">
                    Submit
                  </button>
                  <button
                    onClick={() => setPop(false)}
                    className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
