import React, { useState } from 'react'
import AutoDas from '../components/StockManagement/AutoDas';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { getData } from '../services/NetworkServices';

function StockAutoDas() {
    const [search, setsearch] = useState("");
    const [codesearch, setcodesearch] = useState("");

    const fetchData = () => {
        getData("Stock/auto-DAs")
    }

  return (
    <>
       <div className="flex flex-col gap-20 items-start w-full ">
      <div className="flex justify-between mt-0 h-16  items-center w-full ">
        <div className=''>
        <h1 className="font-semibold text-3xl">Auto-DAs List</h1>

        </div>
        <div className="flex justify-center items-center pb-2 gap-10  ">
          <button
            id="btnSubmit"
            type="submit"
            className="btnFetch btn"
            onClick={fetchData}
            // disabled={!selectedValues.visitor}
          >
            Fetch Data
          </button>
        </div>
        <div className="flex justify-center items-center w-[400px] ">
          <div className="flex justify-center items-center relative  w-80">
            <input
              type="text"
              className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
              name="username"
              value={codesearch}
              onChange={(e) => setcodesearch(e.target.value)}
            />
            <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
              Search
            </div>
            <div className="absolute left-5 text-sm font-bold">
              <FaMagnifyingGlass className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
      <div>
      <AutoDas 
      setsearch={setsearch}
      search={search}
      setcodesearch={setcodesearch}
      codesearch={codesearch}
      />
      </div>
      </div>
    </>
  )
}

export default StockAutoDas
