import React, { createContext, useContext, useState, useEffect } from "react";
import rolesConfig from "./RolesConfig";

const RoleContext = createContext();

export const RoleProvider = ({ children, userRole }) => {
  const [role, setRole] = useState(userRole);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const roleConfig = rolesConfig.find((config) => config.roles.includes(role));
    setPermissions(roleConfig ? roleConfig.permissions : []);
  }, [role]);

  return (
    <RoleContext.Provider value={{ role, permissions, setRole }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext) || { permissions: [] };
