import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { FaAngleUp, FaMagnifyingGlass } from "react-icons/fa6";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { getData, postData } from "../services/NetworkServices";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";

export default function StockManagement() {
  const navigate = useNavigate();
  const [clicked, setclicked] = useState(1);
  const [data, setdata] = useState([]);
  const [show, setshow] = useState(false);
  const [pop, setpop] = useState(0);
  const [drop, setdrop] = useState(0);
  const [gin, setgin] = useState({
    User: "",
    GINname: "",
    posted: [{ title: "", content: 0 }],
  });
  const [grn, setgrn] = useState({
    User: "",
    recieved: [{ title: "", content: 0 }],
  });
  const [search, setsearch] = useState("");
  const [codesearch, setcodesearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [stockuser, setstockuser] = useState("");
  const [grnlist, setgrnlist] = useState("");
  const [userstockedData, setuserstockedData] = useState([]);
  const [zoneDropdownOpen, setZoneDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [stockData, setStockData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value || value === "") {
      setShowMessage(false);
    }
    setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const paginatedData = stockData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const paginatedUserStockedData = userstockedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getSomeData = async () => {
    const token = localStorage.getItem("token");

    const res = await getData("Stock/psp-stock");
    setdata(res.data.data);
  };
  useEffect(() => {
    getSomeData();
  }, [!data]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (selectedUserId) {
      getData(`Stock/psp-stock/${selectedUserId}`)
        .then((res) => {
          setStockData(res.data.data);
          console.log("dataaaaa", res.data.data);
        })
        .catch((err) => console.log("error fetching permissions", err));
    }
  }, [selectedUserId]);

  const postedrequest = async (id) => {
    try {
      const res = await getData(`Stock/user-stock/${id}`);
      setstockuser(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const addMaterial = () => {
    setgin({ ...gin, posted: [...gin.posted, { title: "", content: 0 }] });
  };

  const deleteMaterial = (index) => {
    const updatedMaterial = [...gin.posted];
    updatedMaterial.splice(index, 1);
    setgin({ ...gin, posted: updatedMaterial });
  };

  const addrecievedMaterial = () => {
    setgrn({ ...grn, recieved: [...grn.recieved, { title: "", content: 0 }] });
  };

  const deleterecievedmaterial = (index) => {
    const updatedMaterial = [...grn.recieved];
    updatedMaterial.splice(index, 1);
    setgrn({ ...grn, recieved: updatedMaterial });
  };

  const IssuedStockUser = async () => {
    const res = await getData("User-Data");
    const sortedUserData = res.data?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });
    setstockuser(sortedUserData);
  };

  const InfoStockuser = async (id) => {
    const res = await getData(`Stock/user-stock/${id}`);
    setuserstockedData(res.data.data);
  };

  useEffect(() => {
    IssuedStockUser();
  }, [!stockuser]);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefs = {
    zone: useRef(null),
  };

  const storedZones = localStorage.getItem("zones");

  // Convert the JSON string back to an array
  const zonesArray = storedZones ? JSON.parse(storedZones) : [];
  let sortedZoness = zonesArray.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <div className="flex flex-col justify-start items-start w-full h-[80vh] ">
        <div className="flex justify-between mt-0 h-16  items-center w-full ">
          <h1 className="font-semibold text-3xl">Stock Management</h1>
          <div className="flex justify-center items-center w-[700px] mt-10">
            <div className="flex justify-center items-center relative  w-80">
              <div className="relative left-20">
                <input
                  type="text"
                  className="rounded-3xl px-12 py-3 outline-none border-2 border-red-400"
                  name="username"
                  value={codesearch}
                  onChange={(e) => setcodesearch(e.target.value)}
                />
                <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                  Search
                </div>
                <div className="absolute left-5 bottom-4 text-sm font-bold">
                  <FaMagnifyingGlass className="w-5 h-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center w-full h-16 ">
          <div className="flex justify-start items-start w-full h-16 ">
            <button
              className={`flex justify-center items-center ${
                clicked === 1
                  ? "bg-[#1C838A] text-white"
                  : "bg-[#E7E7E7] text-black"
              }  px-12 py-3 rounded-2xl text-black shadow-lg   transition-all duration-500 hover:scale-110`}
              onClick={() => setclicked(1)}
            >
              Available Stock
            </button>
            <button
              className={`flex justify-center items-center ${
                clicked === 2
                  ? "bg-[#1C838A] text-white"
                  : "bg-[#E7E7E7] text-black"
              } ml-7  px-8 py-3 rounded-2xl text-black shadow-lg transition-all duration-500 hover:scale-110`}
              onClick={() => setclicked(2)}
            >
              Issued Stock
            </button>
          </div>
        </div>
        {clicked === 2 ? (
          <div className="relative w-96 bottom-10">
            <input
              autoComplete="off"
              className="outline-red-200 py-2 px-6 border-2 mt-16 border-red-200 rounded-full"
              type="text"
              placeholder="Select the User"
              value={search}
              onClick={() => setshow(!show)}
              onChange={(e) => setsearch(e.target.value)}
            />
            {show ? (
              <FaAngleUp
                className="absolute bottom-3 left-52 text-xl cursor-pointer"
                onClick={() => setshow(!show)}
              />
            ) : (
              <FaAngleDown
                className="absolute bottom-3 left-52 text-xl cursor-pointer"
                onClick={() => setshow(!show)}
              />
            )}
            <p className="absolute bottom-8 left-8 font-bold bg-white">User</p>

            {show && (
              <div className="absolute z-10 w-60 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                {stockuser.map((item) => (
                  <div
                    key={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      InfoStockuser(item.id);
                      setsearch(item.fullName);
                      setshow(false);
                    }}
                  >
                    {item.fullName}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="relative w-96 bottom-10" ref={dropdownRef}>
            <input
              autoComplete="off"
              className="outline-red-200 py-2 px-6 border-2 mt-16 border-red-200 rounded-full w-60"
              type="text"
              placeholder="Select the Zone"
              value={selectedValues.zonename || ""}
              onChange={(e) => handleChange(e)}
              onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown ? (
              <FaAngleUp
                className="absolute bottom-3 left-52 text-xl cursor-pointer"
                onClick={() => setShowDropdown(!showDropdown)}
              />
            ) : (
              <FaAngleDown
                className="absolute bottom-3 left-52 text-xl cursor-pointer"
                onClick={() => setShowDropdown(!showDropdown)}
              />
            )}
            <p className="absolute bottom-8 left-8 font-bold bg-white">Zone</p>

            {showDropdown && (
              <div className="absolute z-10 w-60 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                {sortedZoness?.map((item) => (
                  <div
                    key={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setSelectedValues({ zonename: item.title });
                      setShowDropdown(false);
                      setSelectedUserId(item.id);
                    }}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="w-full flex justify-center items-start h-full border border-black rounded-2xl">
          <div className="flex flex-col  justify-center items-center w-full rounded-t-2xl ">
            <ul className="flex justify-evenly items-center w-full bg-[#F7F7F7] rounded-t-2xl h-12 border-b border-black ">
              <li className=" w-[150px] flex justify-center items-center">
                Material Code
              </li>
              <li className=" w-[150px] flex justify-center items-center">
                Material Description
              </li>
              <li className=" w-[150px] flex justify-center items-center">
                Batch Code
              </li>
              <li className=" w-[150px] flex justify-center items-center">
                Available Quantity
              </li>
              {/* {clicked === 1 ? (
              <li className=" w-[150px] flex justify-center items-center">
                Issued Quantity
              </li>
            ) : (
              <></>
            )} */}
              {/* <li className=" w-[150px] flex justify-center items-center opacity-0">
              Status
            </li> */}
            </ul>
            {clicked === 1 ? (
              <div className="w-full">
                {paginatedData.length === 0 ? (
                  <p className="flex justify-center items-center w-full text-red-600">
                    No data available
                  </p>
                ) : (
                  paginatedData
                    ?.filter((item) => {
                      return codesearch?.toLowerCase() === ""
                        ? item
                        : item?.materialDescription
                            ?.toLowerCase()
                            .includes(codesearch.toLowerCase());
                    })
                    .map((item, index) => (
                      <ul
                        key={index}
                        className={`flex justify-evenly items-center w-full h-14 ${
                          index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                        }    transition-transform duration-200 ease-in-out`}
                      >
                        <li className="w-[150px] flex justify-center items-center">
                          {item.materialCode}
                        </li>
                        <li className="w-[150px] flex justify-center items-center text-sm">
                          {item.materialDescription}
                        </li>
                        <li className="w-[150px] flex justify-center items-center">
                          {item.batchCode}
                        </li>
                        <li className="w-[150px] flex justify-center items-center">
                          {item.availableQuantity}
                        </li>
                        {/* <li className="w-[150px] flex justify-center items-center">
                        0
                      </li> */}
                        {/* <li
                        className="w-[150px] flex justify-center items-center py-2 rounded-3xl bg-black text-white hover:scale-110 transition-all duration-500"
                        onClick={() => setpop(3)}
                      >
                        View Logs
                      </li> */}
                      </ul>
                    ))
                )}
              </div>
            ) : (
              <div className="w-full">
                {paginatedUserStockedData != null &&
                paginatedUserStockedData.length === 0 ? (
                  <p className="flex justify-center items-center w-full text-red-600">
                    No data available
                  </p>
                ) : (
                  paginatedUserStockedData
                    ?.filter((item) => {
                      return codesearch?.toLowerCase() === ""
                        ? item
                        : item?.materialDescription
                            ?.toLowerCase()
                            .includes(codesearch.toLowerCase());
                    })
                    .map((item, index) => (
                      <ul
                        key={index}
                        className={`flex justify-evenly items-center w-full h-14 ${
                          index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                        }    transition-transform duration-200 ease-in-out`}
                      >
                        <li className="w-[150px] flex justify-center items-center">
                          {item.materialCode}
                        </li>
                        <li className="w-[150px] flex justify-center items-center text-sm">
                          {item.materialDescription}
                        </li>
                        <li className="w-[150px] flex justify-center items-center">
                          {item.unit}
                        </li>
                        <li className="w-[150px] flex justify-center items-center">
                          {item.availableQuantity}
                        </li>

                        <li
                          className="w-[150px] flex justify-center items-center py-2 rounded-3xl bg-black text-white hover:scale-110 transition-all duration-500"
                          onClick={() => setpop(3)}
                        >
                          View Logs
                        </li>
                      </ul>
                    ))
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center mt-4">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={clicked === 1 ? stockData.length : userstockedData.length} // Corrected total count
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50"]}
          />
        </div>

        {/* pop up For GIN */}
        {pop === 1 ? (
          <div className="fixed inset-0 bg-blend-darken backdrop-brightness-50">
            <div className="flex items-center justify-center h-[100vh]">
              <div className="bg-white md:w-2/3 min-w-max border-2 p-10 rounded-lg">
                <div className="relative w-full flex flex-col gap-10 justify-center items-center text-center p-10">
                  <p className="text-2xl font-bold">Goods Issue Note</p>

                  <div className="flex justify-center items-center relative  w-2/3 mt-2 transition-all duration-500 ">
                    <input
                      type="text"
                      className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                      name="username"
                      value={gin.User}
                      onClick={drop === 1 ? () => setdrop(0) : () => setdrop(1)}
                    />
                    <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                      User
                    </div>
                    <div className="absolute right-5 text-sm font-bold">
                      {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
                      <p className="text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 ">
                        {drop === 1 ? (
                          <FaAngleUp
                            className="cursor-pointer"
                            onClick={() => setdrop(0)}
                          />
                        ) : (
                          <FaAngleDown
                            className="cursor-pointer"
                            onClick={() => setdrop(1)}
                          />
                        )}
                      </p>
                    </div>

                    <div
                      className={`absolute top-11 w-full  ${
                        drop === 1 ? "h-56" : "h-0"
                      } bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${
                        drop === 1 ? "border border-red-600" : ""
                      }`}
                    >
                      <ul
                        className={`flex flex-col justify-start w-full ${
                          drop === 1 ? "opacity-100 delay-300" : "opacity-0"
                        } items-start  transition-opacity duration-700 `}
                      >
                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) =>
                            setgin({ ...gin, User: "Anas Danish" })
                          }
                        >
                          Anas Danish
                        </li>

                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) =>
                            setgin({ ...gin, User: "Muhammad Umer Siddiqui" })
                          }
                        >
                          Muhammad Umer Siddiqui
                        </li>
                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) => setgin({ ...gin, User: "Adnan" })}
                        >
                          Adnan
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center relative  w-2/3 mt-2 transition-all duration-500 ">
                    <input
                      type="text"
                      className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                      name="username"
                      value={gin.GINname}
                      onChange={(e) =>
                        setgin({ ...gin, GINname: e.target.value })
                      }
                    />
                    <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                      GIN Name
                    </div>
                  </div>

                  {gin.posted.map((item, index) => (
                    <div
                      key={index}
                      className="w-4/5 flex justify-center items-center"
                    >
                      <div className="flex justify-center items-center relative  w-1/2 mt-2 transition-all duration-500  ">
                        <input
                          type="text"
                          className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                          name="username"
                          value={item.title}
                          // onClick={drop===3?()=>setdrop(0):()=>setdrop(3)}
                          onClick={() => {
                            if (index === grnlist) {
                              setgrnlist(null);
                            } else {
                              setgrnlist(index);
                            }
                          }}
                        />
                        <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                          Material
                        </div>
                        <div className="absolute right-5 text-sm font-bold">
                          <p className="text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 ">
                            {grnlist === index ? (
                              <FaAngleUp
                                className="cursor-pointer"
                                onClick={() => setgrnlist(null)}
                              />
                            ) : (
                              <FaAngleDown
                                className="cursor-pointer"
                                onClick={() => setgrnlist(index)}
                              />
                            )}
                          </p>
                        </div>

                        <div
                          className={`absolute top-11 w-full  ${
                            grnlist === index ? "h-56" : "h-0"
                          } bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${
                            grnlist === index ? "border border-red-600" : ""
                          }`}
                        >
                          <ul
                            className={`flex flex-col justify-start w-full ${
                              grnlist === index
                                ? "opacity-100 delay-300"
                                : "opacity-0"
                            } items-start  transition-opacity duration-700 `}
                          >
                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgin({
                                  ...gin,
                                  posted: gin.posted.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate
                            </li>

                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgin({
                                  ...gin,
                                  posted: gin.posted.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate Herbal" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate Herbal
                            </li>
                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgin({
                                  ...gin,
                                  posted: gin.posted.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate Mint" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate Mint
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/*Second input field  */}

                      <div className="flex justify-center items-center relative  w-56 mt-2 transition-all duration-500  ">
                        <input
                          type="number"
                          className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                          name="username"
                          value={item.content}
                          onChange={(e) =>
                            setgin({
                              ...gin,
                              posted: gin.posted.map((itm, idx) =>
                                idx === index
                                  ? { ...itm, content: e.target.value }
                                  : { itm }
                              ),
                            })
                          }
                          // onClick={drop===4?()=>setdrop(0):()=>setdrop(4)}
                        />
                        <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                          Qty
                        </div>

                        {/* <div className={`absolute top-11 w-full  ${drop ===4 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${drop===4?"border border-red-600":""}`}>
              <ul className={`flex flex-col justify-start w-full ${drop ===4 ? "opacity-100 delay-300" : "opacity-0"} items-start  transition-opacity duration-700 `}>
                <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>Anas Danish</li>
                
                <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>About</li>
                <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>Logout</li>
              </ul>
            </div> */}
                      </div>
                      <div className="flex justify-center items-center ml-2">
                        <MdDelete
                          className="w-7 h-7 cursor-pointer"
                          onClick={() => deleteMaterial(index)}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="flex justify-center items-center w-4/5">
                    <div
                      className="flex w-11/12 justify-end items-center gap-x-2 text-red-600 cursor-pointer"
                      onClick={() => addMaterial()}
                    >
                      <span>
                        <FaPlus className="w-5 h-5" />
                      </span>{" "}
                      <h1>Add Product</h1>
                    </div>
                  </div>

                  <div className="flex gap-10">
                    <button className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005]">
                      Submit
                    </button>
                    <button
                      onClick={() => setpop(0)}
                      className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* Pop Up For GRN */}

        {pop === 2 ? (
          <div className="fixed inset-0 bg-blend-darken backdrop-brightness-50">
            <div className="flex items-center justify-center h-[100vh]">
              <div className="bg-white md:w-2/3 min-w-max border-2 p-10 rounded-lg">
                <div className="relative w-full flex flex-col gap-10 justify-center items-center text-center p-10">
                  <p className="text-2xl font-bold">Goods Recieved Note</p>

                  <div className="flex justify-center items-center relative  w-2/3 mt-2 transition-all duration-500 ">
                    <input
                      type="text"
                      className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                      name="username"
                      value={grn.User}
                      onClick={drop === 1 ? () => setdrop(0) : () => setdrop(1)}
                    />
                    <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                      User
                    </div>
                    <div className="absolute right-5 text-sm font-bold">
                      {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
                      <p className="text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 ">
                        {drop === 1 ? (
                          <FaAngleUp
                            className="cursor-pointer"
                            onClick={() => setdrop(0)}
                          />
                        ) : (
                          <FaAngleDown
                            className="cursor-pointer"
                            onClick={() => setdrop(1)}
                          />
                        )}
                      </p>
                    </div>

                    <div
                      className={`absolute top-11 w-full  ${
                        drop === 1 ? "h-56" : "h-0"
                      } bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${
                        drop === 1 ? "border border-red-600" : ""
                      }`}
                    >
                      <ul
                        className={`flex flex-col justify-start w-full ${
                          drop === 1 ? "opacity-100 delay-300" : "opacity-0"
                        } items-start  transition-opacity duration-700 `}
                      >
                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) =>
                            setgrn({ ...grn, User: "Anas Danish" })
                          }
                        >
                          Anas Danish
                        </li>

                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) =>
                            setgrn({ ...grn, User: "Muhammad Umer Siddiqui" })
                          }
                        >
                          Muhammad Umer Siddiqui
                        </li>
                        <li
                          className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                          onClick={(e) => setgrn({ ...grn, User: "Adnan" })}
                        >
                          Adnan
                        </li>
                      </ul>
                    </div>
                  </div>

                  {grn.recieved.map((item, index) => (
                    <div
                      key={index}
                      className="w-4/5 flex justify-center items-center"
                    >
                      <div className="flex justify-center items-center relative  w-1/2 mt-2 transition-all duration-500  ">
                        <input
                          type="text"
                          className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                          name="username"
                          value={item.title}
                          // onClick={drop===3?()=>setdrop(0):()=>setdrop(3)}
                          onClick={() => {
                            if (index === grnlist) {
                              setgrnlist(null);
                            } else {
                              setgrnlist(index);
                            }
                          }}
                        />
                        <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                          GIN
                        </div>
                        <div className="absolute right-5 text-sm font-bold">
                          {/* <FaMagnifyingGlass className='w-5 h-5'/> */}
                          <p className="text-white flex justify-center items-center rounded-full bg-black p-1 w-5 h-5 ">
                            {grnlist === index ? (
                              <FaAngleUp
                                className="cursor-pointer"
                                onClick={() => setgrnlist(null)}
                              />
                            ) : (
                              <FaAngleDown
                                className="cursor-pointer"
                                onClick={() => setgrnlist(index)}
                              />
                            )}
                          </p>
                        </div>

                        <div
                          className={`absolute top-11 w-full  ${
                            grnlist === index ? "h-56" : "h-0"
                          } bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${
                            grnlist === index ? "border border-red-600" : ""
                          }`}
                        >
                          <ul
                            className={`flex flex-col justify-start w-full ${
                              grnlist === index
                                ? "opacity-100 delay-300"
                                : "opacity-0"
                            } items-start  transition-opacity duration-700 `}
                          >
                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgrn({
                                  ...grn,
                                  recieved: grn.recieved.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate
                            </li>

                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgrn({
                                  ...grn,
                                  recieved: grn.recieved.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate Herbal" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate Herbal
                            </li>
                            <li
                              className="font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white pt-2"
                              onClick={(e) =>
                                setgrn({
                                  ...grn,
                                  recieved: grn.recieved.map((itm, idx) =>
                                    idx === index
                                      ? { ...itm, title: "Colgate Mint" }
                                      : {}
                                  ),
                                })
                              }
                            >
                              Colgate Mint
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/*Second input field  */}

                      <div className="flex justify-center items-center relative  w-56 mt-2 transition-all duration-500  ">
                        <input
                          type="number"
                          className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400"
                          name="username"
                          value={item.content}
                          onChange={(e) =>
                            setgrn({
                              ...grn,
                              recieved: grn.recieved.map((itm, idx) =>
                                idx === index
                                  ? { ...itm, content: e.target.value }
                                  : { itm }
                              ),
                            })
                          }
                          // onClick={drop===4?()=>setdrop(0):()=>setdrop(4)}
                        />
                        <div className="absolute -top-[9px] left-12 text-sm bg-white font-bold">
                          Balance Qty
                        </div>

                        {/* <div className={`absolute top-11 w-full  ${drop ===4 ? "h-56" : "h-0"} bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden z-30  ${drop===4?"border border-red-600":""}`}>
            <ul className={`flex flex-col justify-start w-full ${drop ===4 ? "opacity-100 delay-300" : "opacity-0"} items-start  transition-opacity duration-700 `}>
              <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>Anas Danish</li>
              
              <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>About</li>
              <li className='font-bold flex justify-start w-full border-b border-red-500 pl-4 rounded-b-md transition-all duration-300 hover:bg-red-500 hover:text-white'>Logout</li>
            </ul>
          </div> */}
                      </div>
                      <div className="flex justify-center items-center ml-2">
                        <MdDelete
                          className="w-7 h-7 cursor-pointer"
                          onClick={() => deleterecievedmaterial(index)}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="flex justify-center items-center w-4/5">
                    <div
                      className="flex w-11/12 justify-end items-center gap-x-2 text-red-600 cursor-pointer"
                      onClick={() => addrecievedMaterial()}
                    >
                      <span>
                        <FaPlus className="w-5 h-5" />
                      </span>{" "}
                      <h1>Add Product</h1>
                    </div>
                  </div>

                  <div className="flex gap-10">
                    <button className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005]">
                      Submit
                    </button>
                    <button
                      onClick={() => setpop(0)}
                      className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {pop === 3 ? (
          <div className="fixed inset-0 bg-blend-darken backdrop-brightness-50">
            <div className="flex items-center justify-center h-[100vh]">
              <div className="bg-white md:w-2/3 min-w-max border-2 p-10 rounded-lg">
                <div className="relative w-full flex flex-col gap-10 justify-center items-center text-center p-10">
                  <p className="text-2xl font-bold">Bonus ACT,CAP</p>

                  <div className="flex justify-center items-center relative  w-2/3 mt-2 transition-all duration-500 ">
                    <div className="relative w-96 ">
                      <DatePicker
                        id="stockStartDate"
                        selected={startDate}
                        autoComplete="off"
                        onChange={(date) => setStartDate(date)}
                        className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative"
                        placeholderText="Select the Date"
                      />
                      <FaCalendarAlt
                        className="absolute bottom-4 right-12 text-md cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("stockStartDate").click();
                        }}
                      />
                    </div>

                    <div className="relative w-96 ">
                      <DatePicker
                        id="stockEndDate"
                        selected={endDate}
                        autoComplete="off"
                        onChange={(date) => setEndDate(date)}
                        className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full relative"
                        placeholderText="Select the Date"
                      />
                      <FaCalendarAlt
                        className="absolute bottom-4 right-12 text-md cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("stockEndDate").click();
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center w-11/12">
                    <div className="flex w-11/12 justify-center items-center gap-x-2 text-red-600 cursor-pointer">
                      <ul className="flex justify-evenly items-center gap-x-2 w-full border-b border-black text-black ">
                        <li className="w-32">Date</li>
                        <li className="w-24">Event Type</li>
                        <li className="w-24">Quantity</li>
                        <li className="w-24">Event By</li>
                        <li className="w-24">For</li>
                      </ul>
                    </div>

                    <div className="flex w-11/12 justify-center items-center gap-x-2 text-red-600 cursor-pointer">
                      <ul className="flex justify-evenly items-center gap-x-2 w-full border-b border-black text-black text-sm h-12 ">
                        <li className="w-32">20-MAY-2024</li>
                        <li className="w-24">GIN</li>
                        <li className="w-24">230 EA</li>
                        <li className="w-24">Admin </li>
                        <li className="w-24">Anas Rehman</li>
                      </ul>
                    </div>
                  </div>

                  <div className="flex gap-10">
                    <button className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005]">
                      Submit
                    </button>
                    <button
                      onClick={() => setpop(0)}
                      className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
