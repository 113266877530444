import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { deleteData, getData } from "../../services/NetworkServices";
import dayjs from "dayjs";

function MediaCard({item,index,content}) {
  return (
    <>
    
            <div
              key={item.id}
              className="flex flex-col gap-2  w-[300px]  p-5 shadow-lg shadow-slate-300 rounded-2xl  cursor-pointe  hover:shadow-2xl hover:shadow-neutral-700"
            >
              <div className="flex justify-between">
                <div className="">{item.title}</div>
                <div>
                  <Popover content={content(item)} trigger="hover">
                    <Button>:</Button>
                  </Popover>
                </div>
              </div>
              <div className="flex justify-center">
                <a href={item.filePath} target="_blank">

                <img
                
                className=" rounded-lg h-[250px] object-cover cursor-pointer"
                src={item.thumbnailPath}
                alt={item.fileName}
                
                />
                </a>
              </div>
              <div>
                <p className="font-semibold">
                  Zone: <span className="font-light">{item.zoneName}</span>
                </p>
                <p className="font-semibold">
                  Region: <span className="font-light">{item.regionName}</span>
                </p>
                <p className="font-semibold">
                  Record Type:{" "}
                  <span className="font-light">{item.recordTypeTitle}</span>
                </p>
                <p className="font-semibold">
                  Publication Date:{" "}
                  <span className="font-light">
                    {dayjs(item.publicationDate).format("DD-MMM-YYYY")}
                  </span>
                </p>
              </div>
            </div>
    </>
  );
}

export default MediaCard;
