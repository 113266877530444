import React, { useState, useEffect } from 'react';

function ToggleSwitchForUserManagement({ setFormData, formData, setDisabled, disable, isEditing,isDataFetched }) {
  const [isToggled, setIsToggled] = useState(formData.activeDirectoryFlag !== true);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (formData.activeDirectoryFlag) {
      setIsToggled(false); 
      setDataFetched(true);
    }
    
  }, [isEditing, formData.activeDirectoryFlag]);

  const handleToggle = () => {
    // if (dataFetched || (isEditing )) return;
    // if (dataFetched || (isEditing )) return;

    const newToggledState = !isToggled;

    setFormData({
      ...formData,
      activeDirectoryFlag: newToggledState ? false : true,
    });

    setIsToggled(newToggledState);
    setDisabled(!disable);
  };

  return (
    <div>
      {/* Toggle Switch */}
      <div className="flex items-center gap-2">
        <div
          onClick={handleToggle}
          className={`cursor-pointer h-8 flex items-center rounded-2xl border border-red-600 transition duration-300 w-[100px] ${
            isToggled ? 'bg-red-600 justify-end' : 'bg-red-600 justify-start'
          }`}
        >
          <div
            className={`w-16 h-full bg-white rounded-2xl border border-red-600 shadow-md transform transition-transform ${
              isToggled ? 'translate-x-0 bg-white' : ''
            }`}
          >
            {isToggled ? (
              <span className="text-sm font-bold text-center w-full block pt-1">Non-AD</span>
            ) : (
              <span className="text-sm font-bold text-center w-full block pt-1">AD</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToggleSwitchForUserManagement;
