import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { getData } from "../../services/NetworkServices.js";
import { useRole } from "../../RoleContext.js";
import AttendenceDialog from "./attendence.jsx";
import dayjs from "dayjs";
import { usePermission } from "../../PermissionContext.js";

function AttendanceUser() {
  const fields = [
    "Name",
    "Email",
    "Contact No.",
    "Role",
    "",
    "Attendance Time",
  ];
  const [attendanceData, setAttendenceData] = useState([]);
  const [SearchItem, setSearchItem] = useState("");
  const [selectedRecord, setSelectRecord] = useState(null);
  const [rectype, setRecType] = useState("user");
  const [click, setClicked] = useState(false);
  const [date, setDate] = useState(new Date());
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const [activeButton, setActiveButton] = useState("");
  useEffect(() => {
    attendanceAllUserWithRespectToDateAsOnDate();
  }, []);

  const attendanceAllUserWithRespectToDateAsOnDate = async () => {
    try {
      const date = dayjs().format("YYYY-MM-DD");
      const res = await getData(
        `User-Data/attendance-AllUserWithRespectToDate?AsOnDate=${date}`
      );
      setAttendenceData(res?.data?.data);
      console.log("User-Data/attendance-AllUserWithRespectToDate", res);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const { permissions } = usePermission();
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const [view, setView] = useState(null);
  const hasPermission = () => {
    permissions.forEach((permission) => {
      if (permission.pageRoute === "/usertracking") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
        if (permission.canView) {
          setView(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  // const handleButtonClick = (button) => {
  //   setRecType(button);
  //   setActiveButton(button);

  //   if (button === "Attendance") {
  //     attendanceAllUserWithRespectToDateAsOnDate();
  //   }
  //   // Add any other button-specific logic here
  // };

  return (
    <>
      <div className="my-10">
        <table className="border-2 border-gray-700 rounded-2xl w-[95%] mx-auto text-left ">
          <tbody>
            <tr className="">
              {fields?.map((field) => (
                <th className="font-bold pt-8 pb-4 px-2" key={field}>
                  {field}
                </th>
              ))}
            </tr>
            {attendanceData?.map((r) => (
              <tr className="odd:bg-white even:bg-gray-100" key={r.id}>
                <td className="p-2">{r.name}</td>
                <td className="p-2">{r.email}</td>
                <td className="p-2">{r.contactNo}</td>
                <td className="p-2">{r.role}</td>
                <td className="p-2">{r.isPresent}</td>
                <td className="p-2">{dayjs(r.loginTime).format("HH:mm A")}</td>
                <td className="p-2">
                  {view && (
                    <button
                      className="bg-black text-white py-2 px-6 rounded-lg"
                      onClick={() => {
                        setSelectRecord(r);
                        setClicked(true);
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-right mt-3 p-2">
          <Pagination
            size="small"
            total={currentPagination?.total}
            pageSize={currentPagination.pageSize}
            current={currentPagination.current}
            //   onChange={onPageChange}
          />
        </div>
      </div>
      {click ? (
        <AttendenceDialog
          coordinates={selectedRecord}
          hide={() => {
            setClicked(false);
          }}
          data={selectedRecord}
        />
      ) : null}
    </>
  );
}

export default AttendanceUser;
