import React, { useEffect, useState } from "react";
import JourneyPlan from "./JournryPlan";
import MarketVisit from "./MarketVisit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../services/NetworkServices";
import dayjs from "dayjs";
//THIS MODULE IS STILL UNDER DEVELOPMENT
const JourneyPlanEditJourney = () => {
  // const loc = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  // const { data } = loc.state || {};
  const [visitData, setVisitData] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [zoneTitle, setZoneTitle] = useState(null);
  const [userId, setuserId] = useState(null);
  const [regionId, setregionId] = useState(null);
  // const []

  const [selectedValues, setSelectedValues] = useState({
    journeyTitle: null,
    startDate: null,
    endDate: null,
    region: null,
    zone: null,
    visitor: null,
  });

  const masterID = id.split("-")[0]
  const detailid = id.split("-")[1]

  const getEditableData = async () => {
    let res = await getData(`Journey/ViewJourneyDetailById?masterID=${masterID}&detailid=${detailid}`);
    let startDate = res.data.data[0].startDate.split("T")[0];
    //LOGGING THE DATA FOR  A BETTER VIEW. -H
    setuserId(res.data.data[0].details[0].journeyUsersDtos[0].userName);
    setregionId(res.data.data[0].regionId);
    //SETTING THE VALUES TO THE FETCHED EDIT DETAILS
    setSelectedValues({
      journeyTitle: res.data.data[0].title,
      //TO DISPLAY THE DATE IN CORRECT FORMAT. DAYJS IS NECESSARY AS WE GET DATE AS STRING
      // AND WE NEED TO PASS DATE TO DATEPICKER -H
      startDate: dayjs(res.data.data[0].startDate.split("T")[0], "YYYY-MM-DD"),
      endDate: dayjs(res.data.data[0].endDate.split("T")[0], "YYYY-MM-DD"),
      region: res.data.data[0].regionName,
      zone: res.data.data[0].zoneName,
      visitor: res.data.data[0].details[0].journeyUsersDtos[0].userName,
    });
    setZoneId(res.data.data[0].zoneId);
    setZoneTitle(res.data.data[0].zoneName);
    setVisitData(res.data.data[0].details);
  };
  useEffect(() => {
    if (!id) {
      navigate("/journey");
    }
    getEditableData();

    // alert(id);
  }, []);

  return (
    <>
      <div className="flex w-full  bg-white ">
        <div className="flex justify-center items-center flex-col w-full bg-ceyan-300 p-2">
          <div className="flex justify-start bg-white w-full px-40 text-3xl font-semibold p-5">
            <p>Journey Plan - Edit Journey</p>
          </div>
          <div className="flex w-full ">
            <div className="w-1/3">
              <JourneyPlan
                setZoneTitle={setZoneTitle}
                zoneId={zoneId}
                setZoneId={setZoneId}
                visitData={visitData}
                setVisitData={setVisitData}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
                edit={true}
                userId={userId}
                setuserId={setuserId}
                editregionId={regionId}
              />
            </div>
            <div className="ml-4 w-2/3">
              <MarketVisit
                zoneTitle={zoneTitle}
                zoneId={zoneId}
                visitData={visitData}
                setVisitData={setVisitData}
                selectedValues={selectedValues}
                edit={true}
                userId={userId}
                setuserId={setuserId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyPlanEditJourney;