import React, { useEffect, useState } from "react";
import { deleteData, getData } from "../../services/NetworkServices";
import { FaSearch } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { message, Pagination } from "antd";
import DeleteConfirmModal from "./DeleteConfirmModal";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useRole } from "../../RoleContext";
import { usePermission } from "../../PermissionContext";

function MasterDataDisplay({
  category,
  data,
  setData,
  Edit,
  setEdit,
  setEditData,
  DataChanged,
}) {
  const [CompleteData, setCompleteData] = useState([]);
  const [SearchItem, setSearchItem] = useState("");
  const [DisplayData, setDisplayData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalState, setModalState] = useState({
    isOpen: false,
    currentItem: null,
  });
  const rowPerPage = 4;

  // Fetch Data whenever the category changes
  useEffect(() => {
    const fetchData = async () => {
      let apiURL;
      if (category === "recordTypes") apiURL = `master-record-type`;
      else if (category === "objectives") apiURL = `objective`;
      else if (category === "visitSteps") apiURL = "Status";
      else return setData([]);

      try {
        setLoading(true);
        const res = await getData(apiURL);
        const fetchedData =
          category === "visitSteps"
            ? res.data.data
            : category === "recordTypes"
            ? res.data.data
            : res.data;
        setData(fetchedData);
        setCompleteData(fetchedData);
        setLoading(false);
      } catch (Err) {
        console.log(Err);
        setLoading(false);
      }
    };

    fetchData();
    setSearchItem("");
  }, [category]);

  useEffect(() => {
    const searchData = () => {
      let filteredData = CompleteData;

      if (category === "objectives" || category === "visitSteps") {
        filteredData = CompleteData?.filter((item) =>
          item.title.toLowerCase().includes(SearchItem)
        );
      } else if (category === "recordTypes") {
        filteredData = CompleteData?.filter(
          (item) =>
            item.name.toLowerCase().includes(SearchItem) ||
            item.description.toLowerCase().includes(SearchItem)
        );
      }

      setData(filteredData);
      setCurrentPage(1); // Reset to first page on search
    };

    searchData();
  }, [SearchItem, CompleteData]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * rowPerPage;
    const endIndex = startIndex + rowPerPage;
    setDisplayData(data.slice(startIndex, endIndex));
  }, [currentPage, data]);

  const handleCategoryDelete = async (e, id) => {
    e.preventDefault();
    try {
      const res = await deleteData(`Objective/${id}`);
      if (res.status === 200) {
        setData(data.filter((item) => item.id !== id));
        setCompleteData(CompleteData?.filter((item) => item.id !== id));
        message.success("Item Deleted Successfully");
      } else {
        message.error("Item could not be deleted.");
      }
    } catch (error) {
      message.error("Item could not be deleted.");
    }
    closeModal();
  };

  const openModalForRow = (item) => {
    setModalState({ isOpen: true, currentItem: item });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, currentItem: null });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const getFieldName = () => {
    if (category === "recordTypes") return "Record Types";
    if (category === "objectives") return "Objectives";
    if (category === "visitSteps") return "Visit Steps";
    return "";
  };

  const [editt, setEditt] = useState(null);
  const [add, setAdd] = useState(null);
  const [del, setDel] = useState(null);
  const { permissions } = usePermission();
  const hasPermission = () => {
    permissions.forEach((permission) => {
      if (permission.pageRoute === "/masterrecordmanagement") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEditt(true);
        }
        if (permission.canDelete) {
          setDel(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <div className="p-4 w-8/12 border-[1px] border-gray-400 mx-4 rounded-2xl h-[63vh]">
      {Loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="min-w-full flex justify-between items-center mb-4">
            <h1 className="text-xl font-bold">{getFieldName()}</h1>
            <div className="relative">
              <label className="bg-white absolute left-7 bottom-7 font-bold">
                Search
              </label>
              <input
                type="text"
                className="border border-gray-400 rounded-full py-2 px-8 outline-gray-400"
                value={SearchItem}
                onChange={(e) => setSearchItem(e.target.value.toLowerCase())}
              />
              <button className="absolute left-2 top-2">
                <FaSearch className="w-5 h-5 text-gray-400 mr-2" />
              </button>
            </div>
          </div>
          <table className="min-w-full bg-[#F7F7F7] text-sm">
            <thead className="border-b-4 text-sm border-black">
              <tr className="w-full">
                <th className="py-2 px-4 font-light text-left">
                  {category === "visitSteps" ? "Steps Name" : "Name"}
                </th>
                {category === "recordTypes" && (
                  <>
                    <th className="py-2 px-4 font-light text-left">
                      Description
                    </th>
                    <th className="py-2 px-4 font-light text-left">
                      Distributed To
                    </th>
                  </>
                )}
                {category === "visitSteps" && (
                  <>
                    <th className="py-2 px-4 font-light text-left">
                      Geofencing
                    </th>
                    <th className="py-2 px-4 font-light text-left">
                      Take Picture
                    </th>
                  </>
                )}
                <th className="py-2 px-4 font-light text-left">Status</th>
                {editt && (
                  <th className="py-2 px-4 font-light text-left">Action</th>
                )}
                {category === "objectives" && (
                  <th className="py-2 px-4 border-b text-left"></th>
                )}
              </tr>
            </thead>
            <tbody className="h-3/4">
              {DisplayData?.map((row, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  {category === "recordTypes" && (
                    <>
                      <td className="py-2 text-left px-4 border-b">
                        {row.name}
                      </td>
                      <td className="py-2 text-left px-4 border-b capitalize">
                        {row.description}
                      </td>
                      <td className="py-2 text-left px-4 border-b capitalize">
                        {/* {row?.distributionStatusDto[0]?.title} */}
                        {row?.distributionStatusDto?.map((o, index) => (
                          <span key={index}>
                            {o.title}
                            {index < row?.distributionStatusDto?.length - 1 &&
                              ", "}
                          </span>
                        ))}
                      </td>
                    </>
                  )}
                  {(category === "objectives" || category === "visitSteps") && (
                    <td className="py-2 text-left px-4 border-b">
                      {row.title}
                    </td>
                  )}
                  {category === "visitSteps" && (
                    <>
                      <td className="py-2 text-left px-4 border-b capitalize">
                        {row.isGeoFencing ? "Yes" : "No"}
                      </td>
                      <td className="py-2 text-left px-4 border-b capitalize">
                        {row.isSelfileRequired ? "Yes" : "No"}
                      </td>
                    </>
                  )}
                  <td className="py-2 text-left px-4 border-b capitalize">
                    {row.status ? "Active" : "Inactive"}
                  </td>
                  {editt && (
                    <td className="py-2 text-left px-4 border-b">
                      <button
                        className="bg-black hover:bg-red-600 text-white rounded-lg py-1 px-4"
                        onClick={() => {
                          setEdit(true);
                          setEditData(row);
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  )}
                  {category === "objectives" && del && (
                    <td>
                      <button
                        onClick={() => openModalForRow(row)}
                        className="hover:cursor-pointer"
                      >
                        <FaTrash
                          size={22}
                          className="text-gray-400 hover:text-red-600"
                        />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              {modalState.isOpen && (
                <DeleteConfirmModal
                  ModalOpen={modalState.isOpen}
                  setModalOpen={closeModal}
                  handleSubmit={(e) =>
                    handleCategoryDelete(e, modalState.currentItem.id)
                  }
                  title={modalState.currentItem.title}
                  id={modalState.currentItem.id}
                />
              )}
            </tbody>
          </table>
          <div className="flex justify-end items-end mt-4">
            <Pagination
              current={currentPage}
              pageSize={rowPerPage}
              total={data?.length}
              onChange={onPageChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MasterDataDisplay;
