import React, { useEffect, useState } from "react";
import { getData } from "../../services/NetworkServices";
import { DatePicker, Select, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Grn from "../../pages/Grn";

function StockGrnList() {
  const ginHeadingList = [
    "Grn No",
    "Grn Date",
    "Zone",
    "User",
    "Total Quantity",
  ];
  const masterHeading = [
    "Material Code",
    "Material Decs",
    "Batch Code",
    "Quantity",
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userData, setUserData] = useState([]);
  const [ginListData, setGinListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [zoneId, setZoneId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [pop, setPop] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [masterDetailsData, setMasterDetailsData] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  // Get users
  const getUsers = async () => {
    const token = localStorage.getItem("token");
    const res = await getData("User-Data/GetVisitorsforJC");
    const sortedUser = res.data?.data?.sort((a, b) =>
      a?.fullName?.localeCompare(b?.fullName)
    );
    setUserData(sortedUser);
  };

  useEffect(() => {
    getUsers();
  }, [zoneId]);

  // GIN list API
  const ginListApi = async () => {
    try {
      setLoading(true);
      const res = await getData("Stock/grns");
      setGinListData(res.data.data);
      setFilteredData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ginListApi();
  }, []);
  const stockGrnDetails = async (id) => {
    try {
      setLoading(true);
      const res = await getData(`Stock/grn-details/${id}`);
      setMasterDetailsData(res.data.data);
      // setFilteredData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const filterData = () => {
    let tempData = ginListData;

    // Start Date filter
    if (startDate) {
      tempData = tempData.filter((item) =>
        dayjs(item.createdOn).isSameOrAfter(startDate, "day")
      );
    }

    // End Date filter
    if (endDate) {
      tempData = tempData.filter((item) =>
        dayjs(item.issuanceDate).isSameOrBefore(endDate, "day")
      );
    }

    // Zone filter
    if (selectedZone) {
      tempData = tempData.filter((item) => item.zoneName === selectedZone);
    }

    // User filter
    if (selectedUser) {
      tempData = tempData.filter((item) => item.userInfoId === selectedUser);
    }

    setFilteredData(tempData);
  };

  // Apply filters when user changes start date, end date, zone, or user
  useEffect(() => {
    filterData();
  }, [startDate, endDate, selectedZone, selectedUser]);

  // Paginated data
  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Handle page change
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  // Get the stored user data from localStorage
const storedUserData = localStorage.getItem("userData");

// Check if the data exists before parsing
let zones = [];
if (storedUserData) {
  try {
    const userData = JSON.parse(storedUserData);
    zones = userData.zones || [];
  } catch (error) {
    console.error('Failed to parse userData:', error);
  }
} else {
  console.warn('No userData found in localStorage');
}

console.log('zones:', zones); // Check what zones contains


  const handleGRNClick = () => {
    navigate("/GRN");
  };

  return (
    <>
      {pop === 0 ? (
        <>
          {!click ? (
            <div className="flex flex-col h-full justify-start gap-6 ">
              <div className="flex justify-between pr-8 w-full">
                <h1 className="font-semibold text-[32px]">GRN LIST</h1>
                <button
                  className="w-[180px] btnForNotes btn"
                  onClick={() => {
                    setClick(true);
                  }}
                >
                  Create GRN
                </button>
              </div>
              <div className="flex flex-col justify-center items-center w-full pb-2">
                <div className="flex flex-wrap justify-evenly items-center w-full gap-y-6">
                  {/* Start Date Filter */}
                  <div className="relative w-[240px]">
                    <DatePicker
                      className="outline-red-200 py-3 px-6 border-2 border-red-200 rounded-full relative w-[240px]"
                      placeholder="Start Date"
                      onChange={(date) => setStartDate(date)}
                    />
                    <p className="absolute bottom-9 left-8 font-bold bg-white">
                      Start Date
                    </p>
                  </div>
                  {/* End Date Filter */}
                  <div className="relative w-[240px]">
                    <DatePicker
                      className="outline-red-200 py-3 px-6 border-2 border-red-200 rounded-full relative w-[240px]"
                      placeholder="End Date"
                      onChange={(date) => setEndDate(date)}
                    />
                    <p className="absolute bottom-9 left-8 font-bold bg-white">
                      End Date
                    </p>
                  </div>
                  {/* Zone Filter */}
                  <div className="relative w-[240px]">
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select the Zone"
                      value={selectedZone}
                      onChange={(value) => {
                        const selectedZone = zones.find(
                          (item) => item.id === value
                        );
                        setSelectedZone(selectedZone?.title);
                        setZoneId(value);
                      }}
                    >
                      {zones.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                    <p className="absolute bottom-9 left-8 font-bold bg-white">
                      Zone*
                    </p>
                  </div>
                  {/* User Filter */}
                  <div className="relative w-[240px]">
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select the User"
                      value={selectedUser || undefined}
                      onChange={setSelectedUser}
                    >
                      {userData.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.fullName}
                        </Select.Option>
                      ))}
                    </Select>
                    <p className="absolute bottom-9 left-6 font-bold bg-white px-2 z-10">
                      User*
                    </p>
                  </div>
                </div>
              </div>

              {/* Table */}
              {loading ? (
                <LoadingSpinner />
              ) : (
                <div className="mx-2 bg-yellow-3 flex justify-center items-center border-2 border-gray-300 rounded-2xl text-left capitalize pb-4 h-[30vw] overflow-auto">
                  <div className="h-full w-full">
                    <table className="w-[100%]">
                      <tbody className="w-full">
                        <tr>
                          {ginHeadingList.map((list) => (
                            <th className="font-bold pt-8 pb-4 px-2" key={list}>
                              {list}
                            </th>
                          ))}
                        </tr>
                        {paginatedData.map((r) => (
                          <tr
                            className="odd:bg-white even:bg-gray-100 h-[60px]"
                            key={r.id}
                          >
                            <td className="p-2">{r.grN_No}</td>
                            <td className="p-2">
                              {dayjs(r.issuanceDate).format("YYYY-MM-DD")}
                            </td>
                            <td className="p-2">{r.zoneName}</td>
                            <td className="p-2">{r.fullName}</td>
                            <td className="p-2  w-[160px] text-center">
                              {r.totalQuantity}
                            </td>
                            <td
                              className="cursor-pointer "
                              onClick={() => {
                                setRowData(r);
                                stockGrnDetails(r.id);
                                setPop(3);
                              }}
                            >
                              <p className="rounded-3xl bg-black hover:scale-110 transition-all duration-500  p-1 text-center w-[130px] text-white ">
                                {" "}
                                View Logs
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Pagination */}
              <div className="text-right mt-4 flex justify-end">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={filteredData.length}
                  onChange={handlePageChange}
                  showSizeChanger
                />
              </div>
            </div>
          ) : (
            <Grn setClick={setClick} click={click} />
          )}
        </>
      ) : (
        <>
          {pop === 3 ? (
            <div className="fixed inset-0 bg-blend-darken backdrop-brightness-50">
              <div className="flex items-center justify-center h-[100vh]">
                <div className="bg-white border-2  rounded-3xl w-[800px]">
                  <div className="relative w-full flex flex-col gap-5 justify-center items-center text-center p-8">
                    <p className="text-2xl font-bold">Grn Details</p>
                    <div className="flex flex-col  justify-center items-center relative gap-5 w-2/3 mt-2 transition-all duration-500 ">
                      <div className=" flex  w-full justify-between">
                        <div className="flex  justify-center items-center gap-5">
                          <p className="font-semibold text-xl">GIN:</p>
                          <div>{rowData.grN_No}</div>
                        </div>
                        <div className="flex justify-center items-center gap-5 ">
                          <p className="font-semibold text-xl">Date:</p>
                          <div className="flex  justify-center items-center gap-5 w-[100px]">
                            {dayjs(rowData.createdOn).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </div>
                      <div className=" flex w-full justify-between">
                        <div className="flex justify-center items-center gap-5">
                          <p className="font-semibold text-xl">Zone:</p>
                          <div>{rowData.zoneName}</div>
                        </div>
                        <div className="flex justify-center items-center gap-5">
                          <p className="font-semibold text-xl">User:</p>
                          <div className="flex  justify-center items-center w-[100px]">
                            {rowData.fullName}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mx-2 bg-yellow-3 flex justify-center items-center border-2 border-gray-300 rounded-2xl text-left capitalize pb-4 h-[20vw] overflow-auto  w-full">
                      <div className="h-full w-full">
                        <table className="w-[100%]">
                          <tbody className="w-full">
                            <tr>
                              {masterHeading.map((list) => (
                                <th
                                  className="font-bold pt-8 pb-4 px-2"
                                  key={list}
                                >
                                  {list}
                                </th>
                              ))}
                            </tr>
                            {masterDetailsData.map((m) => (
                              <tr
                                className="odd:bg-white even:bg-gray-100 "
                                key={m.id}
                              >
                                <td className="p-3">{m.materialCode}</td>
                                <td className="p-3">{m.materialDesc}</td>
                                <td className="p-3">{m.batchCode}</td>
                                <td className="p-3">{m.quantity}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <button
                        onClick={() => setPop(0)}
                        className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-black"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
}

export default StockGrnList;
