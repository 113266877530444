import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import { getData, postData } from "../../services/NetworkServices";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { FaCalendarAlt } from "react-icons/fa";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { usePermission } from "../../PermissionContext";
import { message } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { GrEdit } from "react-icons/gr";
import { Navigate, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { type } from "@testing-library/user-event/dist/type";

function StockAdjustment() {
  const [date, setDate] = useState(new Date());
  //const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [disabled, setDisabled] = useState(false);
  const [userData, setUserData] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [filterUserData, setFilterUserData] = useState([]);
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [filterMaterialData, setFilterMaterialData] = useState([]);
  const [filterMaterialTypeData, setFilterMaterialTypeData] = useState([]);
  const [material, setMaterial] = useState([]);
  const [materialType, setMaterialType] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedMaterialType, setSelectedMaterialType] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedAdjustmentType, setSelectedAdjustmentType] = useState("");
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [ginlist, setginlist] = useState([]);
  const [available, setAvailable] = useState(null);
  const [removedMaterials, setRemovedMaterials] = useState([]);
  const [remark, setRemark] = useState("");
  const [increaseStock, setIncreaseStock] = useState(null);
  const [previousIncreaseStock, setPreviousIncreaseStock] = useState(false);

  const [click, setClick] = useState(false);
  const [gin, setgin] = useState({
    Date: "",
    User: "",
    userId: null,
    Quantity: 0,
    Material: {
      materialDescription: "",
      zoneName: "",
      availableQuantity: null,
      materialId: null,
      batchId: null,
      zoneId: null,
    },
    materialType: {
      id: null,
      title: "",
    },
    increaseStock: null,
  });
  const [quantity, setQuantity] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedQuantity, setUpdatedQuantity] = useState(0);
  const [editAvailableQuantityDisplay, seteditAvailableQuantityDisplay] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState({
    materialDropdown: false,
    zone: false,
    user: false,
    materialTypeDropdown: false,
    quantityDropdown: false,
    adjustmentDropdown: false,
  });

  const dropdownRefs = {
    materialDropdown: useRef(null),
    zone: useRef(null),
    user: useRef(null),
    materialTypeDropdown: useRef(null),
    quantityDropdown: useRef(null),
    adjustmentDropdown: useRef(null),
  };

  const { permissions } = usePermission();
  const [editt, setEditt] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions.forEach((permission) => {
      if (permission.pageRoute === "/gin") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEditt(true);
        }
      }
    });
  };

  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  const filterUser = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterUserData(
      userData.filter((user) => user.username.toLowerCase().includes(value))
    );
  };

  const filterZone = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterZoneData(
      zones.filter((zone) => zone.title.toLowerCase().includes(value))
    );
  };

  const filterMaterial = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterMaterialData(
      material.filter((material) =>
        material.materialDescription.toLowerCase().includes(value)
      )
    );
  };

  const filterMaterialType = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterMaterialTypeData(
      materialType.filter((materialType) =>
        materialType.title.toLowerCase().includes(value)
      )
    );
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    setgin({ ...gin, Quantity: quantity + 1 });
    // if (quantity < gin.Material.availableQuantity) {
    // }
  };

  const decrementQuantity = () => {
    if (quantity > 0 && quantity !== 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      setgin({ ...gin, Quantity: quantity - 1 });
    }
  };

  const handleDecrease = () => {
    if (quantity > available) {
      message.error("Not enough stock available!");
    }
  };

  const getUsers = async () => {
    const token = localStorage.getItem("token");

    const res = await getData("User-Data/GetVisitorsforJC");
    const sortedUser = res.data?.data?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });
    setUserData(sortedUser);
    setFilterUserData(sortedUser);
  };

  useEffect(() => {
    getUsers();
    getMaterials();
    getMaterialType();
  }, [zoneId]);

  const getMaterials = async () => {
    const token = localStorage.getItem("token");
    if (zoneId) {
      const res = await getData(`Stock/psp-stock/${zoneId}`);
      setMaterial(res.data.data);
      setFilterMaterialData(res.data.data);
      console.log("response for material", filterMaterialData);
    }
  };

  const getMaterialType = async () => {
    const token = localStorage.getItem("token");
    const res = await getData(`Stock/material-types`);
    setMaterialType(res?.data?.data);
    setFilterMaterialTypeData(res?.data?.data);
    console.log("response for material type: ", filterMaterialTypeData);
  };

  const handleAddMaterial = () => {
    const existingMaterial = (ginlist || []).find(
      (item) => item.Material.materialId === gin.Material.materialId
    );

    if (existingMaterial) {
      message.warning("This material is already selected.");
      return;
    }

    if (
      gin.Quantity > 0 &&
      gin.Material.materialDescription.trim() !== "" &&
      gin.Material.zoneName !== "" &&
      gin.Material.availableQuantity !== null &&
      gin.Material.batchCode !== null &&
      gin.Material.zoneId !== null &&
      gin.Material.ID !== null &&
      gin.materialType.title !== ""
    ) {
      setgin({ ...gin, remarks: remark });
      setginlist([
        ...ginlist,
        { ...gin, remarks: remark, increaseStock: increaseStock },
      ]);

      const materialToRemove = material.find(
        (m) => m.materialId === gin.Material.materialId
      );

      setMaterial(
        material.filter((m) => m.materialId !== gin.Material.materialId)
      );

      if (materialToRemove) {
        setRemovedMaterials([...removedMaterials, materialToRemove]);
      }

      setgin({
        Date: gin.Date,
        User: gin.User,
        userId: gin.userId,
        Quantity: 0,
        Material: {
          materialDescription: "",
          uom: "",
          zoneName: "",
          availableQuantity: null,
          materialId: null,
          batchId: null,
          zoneId: null,
        },
        materialType: {
          id: null,
          title: "",
        },
      });

      console.log("date", gin.Date);

      setQuantity(0);
      setAvailable(null);
      setSelectedMaterial("");
      setDisabled(true);
      setRemark("");
      setIncreaseStock(null);
      setPreviousIncreaseStock(increaseStock);
      setSelectedMaterialType("");
    }
  };

  const handleSubmitSAN = async () => {
    if (ginlist.length > 0) {
      setLoading(true);
      const token = localStorage.getItem("token");
      const increaseStockValue = previousIncreaseStock;

      await postData(
        "Stock/SAN",
        {
          saN_Date: gin.Date,
          zoneId: zoneId,
          details: ginlist.map((gin) => ({
            quantity: gin.Quantity,
            materialId: gin.Material.materialId,
            batchId: gin.Material.batchId,
            materialTypeId: gin.materialType.id,
            remarks: gin.remarks,
            increaseStock: gin.increaseStock,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          const sanNo = res?.data?.data?.saN_No;
          console.log(sanNo);

          setSelectedZone(null);
          message.success("SAN created Successfully.");

          const updatedGinlist = ginlist.map((gin) => ({ ...gin, sanNo }));

          // Clear the ginlist state
          setginlist(updatedGinlist);
          console.log("gin list", ginlist);

          // Clear the ginlist state
          setginlist("");

          // Reset the gin state
          setgin({
            Date: "",
            User: "",
            userId: null,
            Quantity: 0,
            remarks: "",
            Material: {
              materialDescription: "",
              uom: "",
              materialCode: "",
              availableQuantity: null,
              zoneName: null,
              materialId: null,
              batchId: null,
            },
            materialType: {
              id: null,
              title: "",
            },
          });

          // Reset individual fields
          setQuantity(0);
          setAvailable(null);
          setSelectedMaterial("");
          setSelectedUser("");
          setSelectedZone("");
          setDisabled(false);
          setRemark("");
          setSelectedMaterialType("");

          // Optionally, you can also reset other related states
          setZoneId(null);
          setMaterial([]);
          setSelectedMaterial(null);

          // Fetch materials again if necessary
          getMaterials();
        })
        .catch((error) => {
          console.log(error.response.data);
          message.error(error.response.data.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = (index) => {
    const newList = ginlist.filter((item, i) => i !== index);
    setginlist(newList);

    if (ginlist[index]) {
      const restoreMaterial = removedMaterials.find(
        (m) => m.materialId === ginlist[index].Material.materialId
      );

      if (restoreMaterial) {
        setRemovedMaterials(
          removedMaterials.filter(
            (m) => m.materialId !== restoreMaterial.materialId
          )
        );
        setMaterial([...material, restoreMaterial]);
      }
    }

    if (newList.length === 0) {
      setDisabled(false);
      setSelectedZone("");
      setSelectedUser("");
      setSelectedMaterial("");
      setQuantity("");
      setgin({ ...gin, Date: "", User: "" });
    }
  };

  const toggleEdit = (index) => {
    if (edit === false) {
      setEdit(true);
      setEditingRow(index);
      setUpdatedQuantity(ginlist[index].Quantity);
      const material = ginlist[index].Material;
      seteditAvailableQuantityDisplay(
        `Available: ${material.availableQuantity}`
      );
    } else {
      setEdit(false);
      setEditingRow(null);
      seteditAvailableQuantityDisplay(null);
    }
  };

  const handleEdit = (index, e) => {
    e.preventDefault();
    const updated = parseInt(updatedQuantity);
    const newList = ginlist.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          Quantity: updated,
        };
      }
      return item;
    });
    setginlist(newList);
    setEdit(false);
    setEditingRow(null);
  };

  const handleQuantityChange = (e, index) => {
    const updated = parseInt(e.target.value);
    const material = ginlist[index].Material;
    if (updated > material.availableQuantity) {
    } else {
      setUpdatedQuantity(e.target.value);
    }
    // const value = e.target.value;
    // const parsedValue = value === "" ? 0 : parseInt(value);
    // if (increaseStock) { // If adjustment type is "Increase"
    //   setQuantity(parsedValue);
    //   setgin({ ...gin, Quantity: parsedValue });
    // } else { // If adjustment type is "Decrease"
    //   if (parsedValue <= available) {
    //     setQuantity(parsedValue);
    //     setgin({ ...gin, Quantity: parsedValue });
    //   } else {
    //     message.error("Quantity cannot be greater than available quantity!");
    //     setQuantity(available); // Set the quantity to the available quantity
    //     setgin({ ...gin, Quantity: available });
    //   }
    // }
  };
// Get the stored user data from localStorage
const storedUserData = localStorage.getItem("userData");

// Check if the data exists before parsing
let zones = [];
if (storedUserData) {
  try {
    const userData = JSON.parse(storedUserData);
    zones = userData.zones || [];
  } catch (error) {
    console.error('Failed to parse userData:', error);
  }
} else {
  console.warn('No userData found in localStorage');
}

console.log('zones:', zones); // Check what zones contains
    // let sortedZones = zones.sort((a, b) => a.title.localeCompare(b.title));

  const handleClickOutside = (e) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(e.target)
      ) {
        setShowDropdown((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    setDate(currentDate);
    setgin({ ...gin, Date: formattedDate });
  }, []);

  return (
    <main className="ml-6 ">
      <h1 className="h-[37px] my-4 ml-2 font-semibold text-[32px] ">
        Stock Adjustment Notes
      </h1>
      <div className="flex flex-col gap-5  justify-center items-center w-full mt-10 ">
        <div className="flex justify-evenly items-center w-full ">
          <div className="relative flex justify-center items-center   ">
            <div className="relative ">
              <DatePicker
                className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full relative"
                placeholderText="Select the Date"
                selected={date}
                onChange={(date) => {
                  // const formattedDate = date
                  //   ? date.toISOString().split("T")[0]
                  //   : "";
                  // setDate(formattedDate);
                  // setgin({ ...gin, Date: formattedDate });
                  const formattedDate = date ? date.toISOString() : "";
                  setDate(date);
                  setgin({ ...gin, Date: formattedDate });
                }}
                disabled
              />
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Date*
              </p>
              <FaCalendarAlt className="absolute text-black bottom-3 right-6 text-md cursor-pointer" />
            </div>
          </div>

          {/* Zones Starts here */}

          <div
            className="relative flex justify-center items-center "
            ref={dropdownRefs.zone}
          >
            <div className="relative ">
              <input
                className="outline-red-200 py-2 px-6  border-2 border-red-200 rounded-full relative transparent z-100 "
                type="text"
                name="zone"
                autoComplete="off"
                placeholder="Select the zone"
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, zone: !prev.zone }))
                }
                value={selectedZone || ""}
                onChange={(e) => {
                  const zoneValue = e.target.value;
                  setSelectedZone(zoneValue);
                  filterZone(e);
                  if (!zoneValue) {
                    setSelectedMaterial("");
                  }
                }}
                disabled={disabled}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Zone*
              </p>
            </div>
            {showDropdown.zone && (
              <div className="absolute top-11 w-[240px] min-w-24 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg z-10">
                {zones.map((item, index) => (
                  <div
                    key={index}
                    value={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setSelectedZone(item.title);
                      console.log("zooone Id", item.id);
                      setZoneId(item.id);
                      setShowDropdown((prev) => ({
                        ...prev,
                        zone: !prev.zone,
                      }));
                    }}
                    //   onChange={setSelectedZone}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* material type starts Here */}

          <div className="flex flex-col items-center relative ">
            <div className="relative flex justify-center items-center ">
              <div
                className="relative flex justify-center items-center"
                ref={dropdownRefs.materialTypeDropdown}
              >
                <div className="">
                  <input
                    type="text"
                    className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                    value={selectedMaterialType || ""}
                    onChange={(e) => {
                      setSelectedMaterialType(e.target.value);
                      filterMaterialType(e);
                    }}
                    name="materialTypeDropdown"
                    autoComplete="off"
                    placeholder="Select the Material Type"
                    onClick={() =>
                      setShowDropdown((prev) => ({
                        ...prev,
                        materialTypeDropdown: !prev.materialTypeDropdown,
                      }))
                    }
                  />
                  <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
                  <p className="absolute bottom-8 left-8 bg-white font-bold">
                    Material Type*
                  </p>
                </div>
              </div>
              {showDropdown.materialTypeDropdown && (
                <div className="absolute z-10 top-11 min-w-60 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                  {filterMaterialTypeData?.map((item, index) => (
                    <div
                      key={index}
                      value={item.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      // onMouseDown={() => {
                      //   console.log("checkkkk");
                      // }}
                      onMouseDown={() => {
                        console.log("hello yarrrrrrr");
                        setSelectedMaterialType(item.title);
                        console.log("material type value: ", materialType);
                        console.log(
                          "selected material type value: ",
                          selectedMaterialType
                        );

                        setgin({
                          ...gin,
                          materialType: {
                            id: item.id,
                            title: item.title,
                          },
                        });

                        setShowDropdown((prev) => ({
                          ...prev,
                          materialTypeDropdown: !prev.materialTypeDropdown, // Close the dropdown after selection
                        }));
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-evenly items-center w-full">
          {/* Material Dropdown */}
          <div
            className=" relative flex justify-center items-center"
            ref={dropdownRefs.materialDropdown}
          >
            <div className="relative">
              <input
                className=" outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                type="text"
                name="materialDropdown"
                autoComplete="off"
                placeholder="Select the Material"
                onClick={() =>
                  setShowDropdown((prev) => ({
                    ...prev,
                    materialDropdown: !prev.materialDropdown,
                  }))
                }
                value={selectedMaterial || ""}
                onChange={(e) => {
                  setSelectedMaterial(e.target.value);
                  filterMaterial(e);
                }}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-9 left-6 font-bold bg-white px-2">
                Material*
              </p>
            </div>
            {showDropdown.materialDropdown && (
              <div className="absolute z-10 top-11 min-w-24 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                {(filterMaterialData || []).map((item, index) => (
                  <div
                    key={index}
                    value={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      console.log("hello yaarrr");
                      setSelectedMaterial(item.materialDescription);
                      console.log(
                        "selected material value: ",
                        selectedMaterial
                      );
                      setgin({
                        ...gin,
                        Material: {
                          materialDescription: item.materialDescription,
                          uom: item.unit,
                          materialCode: item.materialCode,
                          availableQuantity: item.availableQuantity,
                          zoneName: item.zoneName,
                          zoneId: item.zoneId,
                          materialId: item.materialId,
                          batchId: item.batchId,
                          batchCode: item.batchCode,
                        },
                      });
                      setAvailable(item.availableQuantity);
                      setShowDropdown((prev) => ({
                        ...prev,
                        materialDropdown: !prev.materialDropdown,
                      }));
                    }}
                  >
                    {`${item.materialDescription} | ${item.batchCode}`}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-col items-center relative ">
            <div
              className="relative flex justify-center items-center"
              ref={dropdownRefs.adjustmentDropdown}
            >
              <div className=" ">
                <input
                  type="text"
                  className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                  placeholder="Choose Adjustment Type"
                  value={
                    increaseStock !== null
                      ? increaseStock
                        ? "Increase"
                        : "Decrease"
                      : ""
                  }
                  onClick={() =>
                    setShowDropdown((prev) => ({
                      ...prev,
                      adjustmentDropdown: !prev.adjustmentDropdown,
                    }))
                  }
                  onChange={(e) => {
                    setSelectedAdjustmentType(e.target.value);
                  }}
                />
                <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
                <p className="absolute bottom-8 left-8 bg-white font-bold">
                  Adjustment Type*
                </p>
              </div>
              {showDropdown.adjustmentDropdown && (
                <div className="absolute z-10 top-11 min-w-24 max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                  <div
                    className="px-4 py-2 w-[230px] cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setIncreaseStock(true);
                      setShowDropdown((prev) => ({
                        ...prev,
                        adjustmentDropdown: !prev.adjustmentDropdown,
                      }));
                    }}
                  >
                    Increase
                  </div>
                  <div
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setIncreaseStock(false);
                      setShowDropdown((prev) => ({
                        ...prev,
                        adjustmentDropdown: !prev.adjustmentDropdown,
                      }));
                    }}
                  >
                    Decrease
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Quantity */}
          <div className="flex flex-col items-center relative ">
            <div className="relative flex justify-center items-center">
              <div className="relative">
                <input
                  type="text"
                  className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                  value={quantity || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value === "" ? 0 : parseInt(value);
                    // setQuantity(parsedValue);
                    // setgin({ ...gin, Quantity: parsedValue });
                    // if (
                    //   value === "" ||
                    //   (/^\d+$/.test(value) &&
                    //     parsedValue <= gin.Material.availableQuantity)
                    // ) {
                    //   setQuantity(parsedValue);
                    //   setgin({ ...gin, Quantity: parsedValue });
                    // }
                    if (increaseStock) {
                      // If adjustment type is "Increase"
                      setQuantity(parsedValue);
                      setgin({ ...gin, Quantity: parsedValue });
                    } else {
                      // If adjustment type is "Decrease"
                      if (parsedValue <= available) {
                        setQuantity(parsedValue);
                        setgin({ ...gin, Quantity: parsedValue });
                      } else {
                        message.error(
                          "Quantity cannot be greater than available quantity!"
                        );
                      }
                    }
                  }}
                  max={increaseStock ? null : available}
                />
                <div className="absolute bottom-8 left-8 bg-white font-bold">
                  Quantity*
                </div>
              </div>
              <div className="absolute right-5 bottom-1 text-sm font-bold flex flex-col items-center justify-center">
                <button
                  className="text-black rounded-full focus:outline-none"
                  onClick={incrementQuantity}
                >
                  <TiArrowSortedUp className="cursor-pointer" size={16} />
                </button>
                <button
                  className="text-black rounded-full focus:outline-none"
                  onClick={decrementQuantity}
                >
                  <TiArrowSortedDown className="cursor-pointer" size={16} />
                </button>
              </div>
            </div>

            {available !== null && (
              <div className="flex justify-center">
                <h1 className="font-semibold">
                  Available Quantity:{" "}
                  <span className="font-bold">{available}</span>
                </h1>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center w-full justify-evenly">
          <div className="flex flex-col items-center relative ">
            <div className="relative flex justify-center items-center mr-[360px]">
              <div className=" ">
                <input
                  type="text"
                  className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                  value={remark || ""}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Add Remarks"
                />
                <p className="absolute bottom-8 left-8 bg-white font-bold">
                  Remarks
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center  ">
            {add && (
              <button
                className=" btnForNotes btn w-[240px]"
                onClick={handleAddMaterial}
                disabled={
                  !date ||
                  !selectedZone ||
                  !selectedMaterial ||
                  // !selectedAdjustmentType ||
                  !quantity ||
                  !remark ||
                  !selectedMaterialType
                }
              >
                Add Material
              </button>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="rounded-2xl mb-4 mt-8 border border-[#1E1E1E] min-h-[30vh] h-[40vh] overflow-auto">
            <table className="w-full mx-auto overflow-auto">
              <thead className="bg-[#F7F7F7] border-b-2 border-[#1E1E1E] rounded-2xl text-left overflow-auto">
                <tr>
                  {/* <th className="px-4 pb-1 pt-4 rounded-tl-2xl">SAN Name</th> */}
                  <th className="px-4 pb-1 pt-4 mr-4">SAN Date</th>
                  {/* <th className="px-4 pb-1 pt-4 mr-4">SAN No.</th> */}
                  <th className="px-4 pb-1 pt-4 mr-4">Zone</th>
                  <th className="px-4 pb-1 pt-4 mr-4">Material Type</th>
                  {/* <th className="px-4 pb-1 pt-4 ">Material Type</th> */}
                  <th className="px-4 pb-1 pt-4 ">Material</th>
                  <th className="px-4 pb-1 pt-4 rounded-tr-2xl">Batch Code</th>
                  <th className="px-4 pb-1 pt-4 rounded-tr-2xl">Remarks</th>
                  <th className="px-4 pb-1 pt-4 rounded-tr-2xl">Quantity</th>
                  <th className="px-4 pb-1 pt-4 rounded-tr-2xl"></th>
                  {/* <th className="px-4 pb-1 pt-4 rounded-tr-2xl">Remarks</th> */}
                </tr>
              </thead>

              <tbody className="rounded-2xl  overflow-scroll">
                {Array.isArray(ginlist) && ginlist.length > 0 ? (
                  ginlist.map((gin, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-[#E7E7E7]" : "bg-white"}
                    >
                      <td className="px-4 py-2">
                        {dayjs(gin.Date).format("YYYY-MM-DD")}
                      </td>
                      {/* <td className="px-4 py-2">{gin.sanNo}</td> */}
                      <td className={`px-4 py-2 `}>{gin.Material?.zoneName}</td>
                      <td className={`px-4 py-2 `}>
                        {gin.materialType?.title}
                      </td>
                      <td className={`px-4 py-2 `}>
                        {gin.Material.materialDescription}
                      </td>
                      <td className={`px-4 py-2 `}>{gin.Material.batchCode}</td>
                      <td className={`px-4 py-2 `}>{gin.remarks}</td>
                      {editingRow === index ? (
                        <td
                          className={`px-4 py-2  ${
                            editAvailableQuantityDisplay
                              ? " flex items-center gap-4"
                              : ""
                          }`}
                        >
                          {editAvailableQuantityDisplay && (
                            <div className="font-semibold">
                              {editAvailableQuantityDisplay}
                            </div>
                          )}
                          <form
                            onSubmit={(e) => handleEdit(index, e)}
                            className="relative flex justify-between items-center"
                          >
                            <input
                              type="text"
                              value={updatedQuantity || ""}
                              onChange={(e) => handleQuantityChange(e, index)}
                              className="rounded-3xl pl-2 py-1 w-24 outline-none border border-[#1E1E1E]"
                            />
                            <div className="absolute bottom-2 right-1 flex justify-center items-center gap-2">
                              <button type="submit">
                                <MdOutlineDone className="cursor-pointer h-4 w-4" />
                              </button>
                              <RxCross2
                                onClick={() => {
                                  setEditingRow(null);
                                  seteditAvailableQuantityDisplay(null);
                                }}
                                className="cursor-pointer h-4 w-4"
                              />
                            </div>
                          </form>
                        </td>
                      ) : (
                        <td className={`px-4 py-2 `}>{gin.Quantity}</td>
                      )}
                      <td className={`px-4 py-2 `}>
                        <div className="flex flex-row justify-end items-center gap-0">
                          <button className="text-black px-2 py-1 rounded-full">
                            <GrEdit
                              size={20}
                              className="p-0 m-0"
                              onClick={() => toggleEdit(index)}
                            />
                          </button>
                          <button className="text-black px-2 py-1 rounded-full -ml-1">
                            <RiDeleteBinLine
                              size={20}
                              className="p-0 m-0"
                              onClick={() => handleDelete(index)}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="px-2 py-4">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end gap-4">
            {add && (
              <button
                className=" btnForNotes btn  w-[180px]"
                onClick={handleSubmitSAN}
              >
                Create SAN
              </button>
            )}
            <button
              className="btnForNotesCancel btn w-[180px]"
              onClick={() => navigate("/stockManagement")}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </main>
  );
}

export default StockAdjustment;
