import React from "react";
import { FaSpinner } from "react-icons/fa";

function LoadingSpinner() {
  return (
    <div className="min-w-full min-h-full flex items-center justify-center">
      <FaSpinner className="animate-spin" size={48} color="red" />
    </div>
  );
}

export default LoadingSpinner;
