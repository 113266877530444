// React Imports
import React, { useEffect, useState } from "react";
// Config Imports
import fields from "./config";
// Third party Imports
import axios from "axios";
import { getData } from "../../services/NetworkServices";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api"; // constants
import dayjs from "dayjs";
import { Spin } from "antd";

const AttendenceDialog = (props) => {
  const containerStyle = {
    width: "250px",
    height: "330px",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  // Component FOrm Type
  const formType = "UserFields";
  // Component Data holders
  const [formData, setFormData] = useState({});
  const [fieldSet, setFieldSet] = useState({});
  const [loading, setLoading] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const { coordinates } = props;
  useEffect(()=>{
    setLat(coordinates.latitude)
    setLng(coordinates.longitude)
    setLoading(false)
  })
  const center = {
    lat: lat || 0,
    lng: lng || 0,
  };
  // Following hook is used to load respective field Data
  useEffect(() => {
    // will hold list of apis to be called on load
    const apis = [];
    // will hold state values for form
    const formValues = {};
    // used to hold api field map
    const apiFieldMap = [];
    // Will go through fields
    fields[formType].forEach((item) => {
      formValues[item.key] = "";
      if (item.type === "dropdown") {
        apiFieldMap.push(item.key);
        apis.push(getData(item.url));
      }
    });

    if (apis.length > 0) {
      let respList = {};
      Promise.all([...apis]).then((response) => {
        response.forEach((item, i) => {
          // eslint-disable-next-line
          formValues[apiFieldMap[i]] = item.data.data
            ? [item.data.data[0].id]
            : [];
          console.log(
            apiFieldMap[i] == "userRole" ? item.data.data : item.data
          );
          respList = {
            ...respList,
            [apiFieldMap[i]]:
              apiFieldMap[i] == "userRole" || apiFieldMap[i] == "status"
                ? item.data.data
                : item.data,
          };
        });
        setFieldSet(respList);
        setFormData(formValues);
      });
    }
  }, []);

  const hide = () => {
    props.hide();
  };

  const onSubmit = () => {};

  console.log("coordinates", center, "isLoaded", isLoaded);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all p-10 sm:my-8">
            <div className="grid gap-4 grid-cols-2">
              <div className="text-center font-semibold text-lg w-[250px] border-2">
                <p>Picture</p>
              <img src={coordinates.filePath} alt="Girl in a jacket" width="500" height="600"/>
              </div>
              <div className="text-center font-semibold text-lg w-[250px] border-2">
                <p>Location</p>
                <Spin tip="Loading" size="large" spinning={loading}>
                  {isLoaded &&  (
            
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={18}
                   >
                  {lat && lng && <Marker position={{ lat, lng }} />}
              </GoogleMap>
          )}
        </Spin>
              </div>
            </div>

            <div className="mt-2 text-center">
              <h3>Attendance Time: {dayjs(coordinates.loginTime).format('HH:mm A')}</h3>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                type="button"
                className="rounded-full mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => hide()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendenceDialog;
