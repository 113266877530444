import React, { useEffect, useRef, useState } from "react";
import { getData } from "../services/NetworkServices";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import dayjs from "dayjs";
import { Spin } from "antd";

function UserTracking() {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [visitorData, setVisitorData] = useState([]);
  const [filteredVisitorData, setFilteredVisitorData] = useState([]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [lastLocation, setLastLocation] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef(null);
  const containerStyle = {
    width: "100%",
    height: "480px",
  };
  const center = {
    lat: lat || 0,
    lng: lng || 0,
  };

  useEffect(() => {
    getData("User-Data/visitor")
      .then((response) => {
        if (response?.status === 200) {
          const sortedVisitor = response.data?.data?.sort((a, b) => {
            return a?.fullName?.localeCompare(b?.fullName);
          });
          setVisitorData(sortedVisitor);
          setFilteredVisitorData(sortedVisitor);
        }
      })
      .catch((error) => console.error("Error fetching visitors:", error));
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value || value == "") {
      setLastLocation(false);
      setShowMessage(false);
      setLat(false);
      setLng(false);
    }
    setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const filterVisitors = (e) => {
    const value = e.target.value.toLowerCase();
    setFilteredVisitorData(
      visitorData.filter((visitor) =>
        visitor.fullName.toLowerCase().includes(value)
      )
    );
  };

  const fetchData = () => {
    setLoading(true);
    setShowMessage(false); // Hide message before fetching new data
    getData(
      `User-Data/GetAllUserTracking?userId=${selectedUserId}&noOfRecords=1`
    )
      .then((res) => {
        if (res?.data?.data && res.data.data.length > 0) {
          const location = res.data.data[0];
          setLastLocation(location.createdOn);
          setLat(parseFloat(location.latitude));
          setLng(parseFloat(location.longitude));
          setLoading(false);
        } else {
          setLastLocation(null);
          setLoading(false);
          setLat(null);
          setLng(null);
          setShowMessage(true); // Show message if no records found
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
        setLastLocation(null);
        setLat(null);
        setLng(null);
        setShowMessage(true); // Show message in case of an error
        setLoading(false);
      });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  return (
    <div className="flex justify-between w-full">
      <div className="flex flex-col items-center w-full  gap-2">
        <div className="flex justify-center items-end w-full gap-9 ">
          <div className="relative w-96 pb-1" ref={dropdownRef}>
            <input
              autoComplete="off"
              className="outline-red-200 py-2 px-6 w-full border-2 border-red-200 rounded-full"
              type="text"
              name="visitor"
              placeholder="Select the visitor"
              value={selectedValues.visitor || ""}
              onChange={(e) => {
                handleChange(e);
                filterVisitors(e);
              }}
              onClick={() => setShowDropdown(!showDropdown)}
            />
            <IoIosArrowDropdownCircle className="absolute bottom-4 right-4 text-xl" />
            <p className="absolute bottom-8 left-8 font-bold bg-white">
              Visitor
            </p>
            {showDropdown && (
              <div className="absolute z-10 w-full max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                {filteredVisitorData.map((item) => (
                  <div
                    key={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setSelectedValues({ visitor: item.fullName });
                      setShowDropdown(false);
                      setSelectedUserId(item.id);
                    }}
                  >
                    {item.fullName}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-center items-center pb-2 gap-10 ">
            <button
              id="btnSubmit"
              type="submit"
              className="btnFetch btn"
              onClick={fetchData}
              disabled={!selectedValues.visitor}
            >
              Fetch Location
            </button>
          </div>
          <div className="flex flex-col items-center h-28 w-1/3 gap-5">
            <div>
              <p className="text-3xl font-bold">Last Location</p>
            </div>
            <div className="font-semibold">
              {lastLocation
                ? dayjs(lastLocation).format("DD-MMM-YYYY hh:mm A")
                : ""}
              {showMessage && "No records found for this Visitor's location"}
            </div>
          </div>
        </div>
        <div className="w-full border-red-600 border">
          <Spin tip="Loading" size="large" spinning={loading}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
              >
                {lat && lng && <Marker position={{ lat, lng }} />}
              </GoogleMap>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default UserTracking;
