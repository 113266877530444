import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
const config = { withCredentials: true };
let accessToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${accessToken}`,
  "content-type": "application/json",
};
const baseUrl = process.env.REACT_APP_BACKEND_URL;
// const errorNavigation = (error) => {
//   if(error.statusCode = 401){
//       window.location.href = "/login";
//     }
// }

// const navigate = useNavigate();
export const getData = async (url, token) => {
  token = token || localStorage.getItem("token");
  try {
    const resp = await axios.get(
      // process.env.REACT_APP_BACKEND_URL + url,
      baseUrl + url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
    );
    if (resp) return resp;
  } catch (err) {
    console.log("loading => error", err?.response?.status);
    if (err?.response?.status === 401){
      localStorage.clear();
      window.location = "/login";
    }
  }
};
export const getDataForPermission = async (url, token) => {
  try {
    const resp = await axios.get(
      // process.env.REACT_APP_BACKEND_URL + url,
      baseUrl + url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
    );
    return resp;
  } catch (err) {
    // errorNavigation(err);
  }
};
export const postDataForLogOut = async (url, data,token) => {
  try {
    let resp = await axios.post(
      baseUrl + url,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
    return resp;
  } catch (err) {
    console.log(err);
    // errorNavigation(err)
    return err;
  }
};
export const postData = async (url, data) => {
  try {
    const resp = await axios.post(baseUrl + url, data, {headers});
    return resp;
  } catch (err) {
    console.log(err);
    // errorNavigation(err)
    return err;
  }
};
export const postFormData = async (url, data,token) => {
  try {
    const resp = await axios.post(baseUrl + url, data, {
       headers : {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      }
    });
    return resp;
  } catch (err) {
    // errorNavigation(err)
    console.log(err);
  }
};
export const putFormData = async (url, data) => {
  try {
    const resp = await axios.put(baseUrl + url, data, {headers});
    return resp;
  } catch (err) {
    // errorNavigation(err)
    console.log(err);
  }
};
export const putData = async (url, data) => {
  try {
    const resp = await axios.put(
      // process.env.REACT_APP_BACKEND_URL + url,
      baseUrl + url,
      data,
      {  headers }
    );
    return resp;
  } catch (err) {
    // errorNavigation(err)
    console.log(err);
  }
};
export const deleteData = async (url) => {
  try {
    const resp = await axios.delete(baseUrl + url, {headers});
    return resp;
  } catch (err) {
    console.log(err);
    // errorNavigation(err)
  }
};
