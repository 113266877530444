import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import SecondaryLayout from "./layouts/SecondaryLayout";
import Journey from "./pages/Journey";
import JourneyPlanNewVisitPopUp from "./components/journey/JourneyPlanNewVisitPopUp";
import JourneyPlanCreateJourney from "./components/journey/JourneyPlanCreateJourney";
import StockManagement from "./pages/StockManagement";
import Dashboard from "./pages/Dashboard";
import MasterRecordManagement from "./pages/MasterRecordManagement";
import CreateSurvey from "./components/survey/CreateSurvey";
import Surveys from "./pages/Surveys";
import CSurveys from "./pages/createSurvey";
import Media from "./pages/media";
import JourneyPlanEditJourney from "./components/journey/JourneyEditJourney";
import UserManagement from "./pages/UserManagement";
import Gin from "./pages/Gin";
import Grn from "./pages/Grn";
import VisitReport from "./components/journey/VisitReport";
import { RoleProvider } from "./RoleContext";
import UserTrackingPage from "./pages/UserTrackingPage";
import { useState, useEffect } from "react";
import ProtectedRoute from "./ProtectedRoute";
import RolePermissions from "./components/permissions/RolePermissions";
import { PermissionProvider } from "./PermissionContext";
import AutoDas from "./components/StockManagement/AutoDas";
import StockAutoDas from "./pages/StockAutoDas";
import StockAdjustment from "./components/StockManagement/StockAdjustment";
import Report from "./components/StockManagement/Report";
import StockGinList from "./components/StockManagement/StockGinList";
import StockGrnList from "./components/StockManagement/StockGrnList";
function App() {
  const [roleId ,setRoleId] = useState(null)
  const [token ,setToken] = useState(null)
  // const roleId = localStorage.getItem("roleId");
  return (
    <div className="App scrollbar-thumb-red-500 scrollbar-track-white">
      <PermissionProvider roleId={roleId} token={token}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login setToken={setToken} setRoleId={setRoleId}/>} />
            <Route element={<SecondaryLayout token={token} />}>
              <Route path="/" element={<Dashboard token={token} />} />
              <Route path="/journey" element={<Journey />} />
              <Route
                path="/journeyplan"
                element={<JourneyPlanCreateJourney />}
              />
              <Route
                path="/editJourney/:id"
                element={<JourneyPlanEditJourney />}
              />
              <Route path="/VisitReport/:id" element={<VisitReport />} />
              <Route
                path="/stockManagement"
                element={<StockManagement />}
              ></Route>
              <Route path="/GIN" element={<StockGinList />}></Route>
              <Route path="/GRN" element={<StockGrnList />}></Route>
              <Route path="/autoDas" element={<StockAutoDas />}></Route>
              <Route path="stockNotes" element={<StockAdjustment />}></Route>
              <Route path="/stockin-hand" element={<Report />}></Route>

              <Route path="/survey" element={<Surveys />}></Route>
              <Route path="/createsurvey" element={<CreateSurvey />}></Route>
              <Route
                path="/editjourney"
                element={<JourneyPlanNewVisitPopUp />}
              ></Route>
              <Route
                path="/masterRecordManagement"
                element={<MasterRecordManagement />}
              />
              <Route path="/manageUser" element={<UserManagement />} />
              <Route path="/media" element={<Media />}></Route>
              <Route path="/surveys" element={<Surveys />}></Route>
              <Route path="/CSurveys/:id" element={<CSurveys />}></Route>
              <Route path="/location" element={<UserTrackingPage />}></Route>
              <Route path="/permissions" element={<RolePermissions token={token}/>}></Route>
            </Route>
          </Routes>
        </Router>
      </PermissionProvider>
    </div>
  );
}

export default App;
