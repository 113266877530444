import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import UserDialog from "./dialog.jsx";
import AttendenceDialog from "./attendence.jsx";
import { getData } from "../../services/NetworkServices.js";
import { useRole } from "../../RoleContext.js";
import dayjs from "dayjs";
import { usePermission } from "../../PermissionContext.js";
import { logDOM } from "@testing-library/react";

const ManageUser = ({setSearchItem,SearchItem,setLoading,loading}) => {
  const fields = ["Full Name","userName", "Email", "Contact No.", "Role"];


  const [data, setData] = useState([]);
  // const [triggerApi, setTriggerApi] = useState(false);
  // const [DataChanged, setDataChanged] = useState(false);
  // const [attendanceData, setAttendenceData] = useState([]);
  const [CompleteData, setCompleteData] = useState([]);
  const [click, setClicked] = useState(false);
  const [selectedRecord, onSelectRecord] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);
  // const [rectype, setRecType] = useState("user");
  // const [date, setDate] = useState(new Date());

  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });
  const [Records, setRecords] = useState([]);

  useEffect(() => {
    const getRecords = async () => {
      let res = await getData(`master-record-type`);

      setRecords(res.data);
    };
    getRecords();
  }, []);

  const getRecordName = (id) => {
    let res = Records.map((item) => {
      if (item.id == id) {
        return item.name;
      }
    });
    return res;
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPagination({
      current: page,
      pageSize,
      total: currentPagination.total,
    });
  
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    setPaginatedData(data.slice(startIndex, endIndex));
  };
  useEffect(() => {
    const filteredData = CompleteData.filter((item) =>
      item.fullName.toLowerCase().includes(SearchItem.toLowerCase())
    );
  
    if (currentPagination.current > Math.ceil(filteredData.length / currentPagination.pageSize)) {
      setCurrentPagination((prev) => ({
        ...prev,
        current: 1,
      }));
    }
  
    const startIndex = (currentPagination.current - 1) * currentPagination.pageSize;
    const endIndex = startIndex + currentPagination.pageSize;
  
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  
    setCurrentPagination((prev) => ({
      ...prev,
      total: filteredData.length,
    }));
  }, [SearchItem, CompleteData, currentPagination.current, currentPagination.pageSize]);
  
  
  const fetchdata = async () => {
      let res = await getData("User-Data");
      setData(res.data);
      setCompleteData(res.data);
      
      setCurrentPagination((prev) => ({
        ...prev,
        total: res?.data?.length
      }));
      setPaginatedData(res?.data.slice(0, currentPagination.pageSize));
      console.log("Get");
      setLoading(false);
  };
  
  // Fetch data only on component mount
  useEffect(() => {
    fetchdata();
    // setLoading(false)
  }, [loading]); // Removed loading and click from dependency array
  

 

  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const { permissions } = usePermission();
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/usermanagement") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);
  // console.log("selected Record",selectedRecord)
  return (
    <>
      <div className="my-4 mx-2">
        <div className="flex justify-center items-center  border-2 border-gray-300 rounded-2xl  mx-auto text-left capitalize pb-4">
          <table className="w-[100%] ">
            <tbody>
              <tr className="">
                {fields.map((field) => (
                  <th className="font-bold pt-8 pb-4 px-2" key={field}>
                    {field}
                  </th>
                ))}
              </tr>
              {paginatedData.map((r) => (
                <tr className="odd:bg-white even:bg-gray-100" key={r.id}>
                  <td className="p-2">{r.fullName}</td>
                  <td className="p-2">{r.username}</td>
                  <td className="p-2">{r.email}</td>
                  <td className="p-2">{r.contactNo}</td>
                  <td className="p-2">{r.userRole}</td>
                  {edit && (
                    <td className="flex justify-center p-2">
                      <button
                        className="bg-black text-white py-2 px-6 rounded-lg"
                        onClick={() => {
                          onSelectRecord(r);
                          setClicked(true);
                          console.log("Paginated Data from manageUSer",r)
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right mt-2 flex justify-end">
          <span className="">Page</span>
          <Pagination
            size="small"
            total={currentPagination?.total}
            pageSize={currentPagination.pageSize}
            current={currentPagination.current}
            onChange={onPageChange}
          />
        </div>
      </div>
      {click ? (
        <UserDialog
          setLoading={setLoading}
          hide={() => {
            setClicked(false);
          }}
          data={selectedRecord}
          edit={selectedRecord !== null}
        />
      ) : null}
    </>
  );
};

export default ManageUser;