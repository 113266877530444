import React, { useState } from "react";

const ToggleSwitch = ({ label, LocationData, setLocationData }) => {
  const [isToggled, setIsToggled] = useState(
    !LocationData.locationExemptionFlag
  );

  const handleToggle = () => {
    setIsToggled(!isToggled);
    setLocationData({
      ...LocationData,
      ["locationExemptionFlag"]: isToggled,
    });
  };

  return (
    <div className="flex items-center gap-2">
      <span className="font-bold">{label}</span>
      <div
        onClick={handleToggle}
        className={`cursor-pointer w-20 h-8 flex items-center rounded-2xl border border-red-600 transition duration-300 ${
          isToggled ? "bg-red-600 justify-end" : "bg-red-600 justify-start"
        }`}
      >
        <div
          className={`w-12 h-full bg-white rounded-2xl border border-red-600 shadow-md transform transition-transform ${
            isToggled ? "translate-x-0 bg-white" : ""
          }`}
        >
          {isToggled ? (
            <span className="text-sm font-bold text-center w-full block pt-1">
              No
            </span>
          ) : (
            <span className="text-sm font-bold text-center w-full block pt-1">
              Yes
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
