import React, { useState } from "react";
import JourneyPlan from "./JournryPlan";
import MarketVisit from "./MarketVisit";

const JourneyPlanCreateJourney = () => {
  const [visitData, setVisitData] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [zoneTitle, setZoneTitle] = useState(null);
  const [userId, setuserId] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [selectedValues, setSelectedValues] = useState({
    journeyTitle: "",
    startDate: "",
    endDate: "",
    region: "",
    zone: "",
    visitor: "",
  });
  return (
    <>
      <div className="flex w-full  bg-white ">
        <div className="flex justify-center items-center flex-col w-full bg-ceyan-300 p-2">
          <div className="flex justify-start bg-white w-full px-40 text-3xl font-semibold p-5">
            <p>Journey Plan - Create Journey</p>
          </div>
          <div className="flex w-full ">
            <div className="w-1/3">
              <JourneyPlan
                setZoneTitle={setZoneTitle}
                setZoneId={setZoneId}
                zoneId={zoneId}
                visitData={visitData}
                setVisitData={setVisitData}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
                userId={userId}
                setuserId={setuserId}
                setRegionId={setRegionId}
                regionId={regionId}
              />
            </div>
            <div className="ml-4 w-2/3">
              <MarketVisit
                zoneTitle={zoneTitle}
                zoneId={zoneId}
                visitData={visitData}
                setVisitData={setVisitData}
                selectedValues={selectedValues}
                userId={userId}
                setuserId={setuserId}
                regionId={regionId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyPlanCreateJourney;