// VisitReportPDF.jsx
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: "1pt solid #ccc",
  },
  title: {
    fontSize: 20,
    marginBottom: 40,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  tableCell: {
    width: "45%",
  },
  imageContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "grid",
  },
  image: {
    width: 150,
    height: 150,
    marginVertical: 10,
  },
});

// const visitNotes = values.visitNotesDto || [];
// const journeyPictures = values.journeyPictureDto || [];
// const stockDistribution = values.stockDistributionDto || [];

const VisitReportPDF = ({ values: { ...values } }) => {
  console.log("i got it", values);
  console.log("testt", values.journeyPictureDto);
  const visitNotes = values.visitNotesDto || [];
  const journeyPictures = values.journeyPictureDto || [];
  const stockDistribution = values.stockDistributionDto || [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Visit Report</Text>

        <View style={styles.section}>
          <Text style={{ fontSize: 16, marginBottom: 4 }}>Visit Details</Text>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>
              Record Type: {values.recordName}
            </Text>
            <Text style={styles.tableCell}>
              Objective: {values.objectiveName}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>
              Planned Date: {values.plannedDate}
            </Text>
            <Text style={styles.tableCell}>
              Actual Date: {values.actualDate}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>
              Total Steps: {values.totalSteps}
            </Text>
            <Text style={styles.tableCell}>
              No. of Persons: {values.noOfPersons}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={{ fontSize: 16, marginBottom: 5 }}>Take Picture</Text>
          <View style={styles.imageContainer}>
            {journeyPictures.map((image, index) => (
              <View key={index}>
                <Text style={{}}>File: {image.fileName}</Text>
                {image.filePath ? (
                  <Image
                    style={styles.image}
                    // src={image.filePath}
                    src={{
                      uri: image.filePath,
                      method: "GET",
                      headers: { "Cache-Control": "no-cache" },
                      body: "",
                    }}
                    alt={image.fileName}
                  />
                ) : (
                  <Text>No Image Available</Text>
                )}
              </View>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <Text style={{ fontSize: 16, marginBottom: 5 }}>Take Notes</Text>
          {visitNotes.length > 0 ? (
            visitNotes.map((note, index) => (
              <Text key={index} style={styles.noteText}>
                <strong>Note {index + 1}:</strong>{" "}
                {note.description || "No description available"}
              </Text>
            ))
          ) : (
            <Text>No notes available.</Text>
          )}
        </View>

        <View style={styles.section}>
          <Text style={{ fontSize: 16, marginBottom: 5 }}>
            Stock Distribution
          </Text>
          {stockDistribution.length > 0 ? (
            <>
              <View style={styles.imageContainer}>
                {stockDistribution
                  .map((item) => item.stockPictureResponse || [])
                  .flat()
                  .map((pic, index) => (
                    <View key={index}>
                      {pic.fileName && (
                        <Image
                          src={{
                            uri: pic.filePath,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                            body: "",
                          }}
                          style={styles.image}
                        />
                      )}
                      <Text>{pic.fileName || "No file available"}</Text>
                    </View>
                  ))}
              </View>
              <View>
                {stockDistribution.map((item, index) => (
                  <View key={index} style={styles.stockRow}>
                    <Text style={styles.stockColumn}>
                      Material Name: {item.materialName || "N/A"}
                    </Text>
                    <Text style={styles.stockColumn}>
                      Quantity: {item.quantity || "N/A"}
                    </Text>
                    <Text style={styles.stockColumn}>
                      Distributed To: {item.distributedTo || "N/A"}
                    </Text>
                  </View>
                ))}
              </View>
            </>
          ) : (
            <Text>No stock distribution data available.</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default VisitReportPDF;
