import React, { createContext, useContext, useState, useEffect } from "react";
import { getData, getDataForPermission } from "./services/NetworkServices";

const PermissionContext = createContext();

export const PermissionProvider = ({ children, }) => {
  const [permissions, setPermissions] = useState([]);
  
  const token = localStorage.getItem("token");
  const roleId = localStorage.getItem("roleId");
  const [role, setRole] = useState(roleId);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    console.log("roleIDDD",roleId)
    getDataForPermission(`UserPermission/role-accesses/${roleId}`, token)
      .then((res) => {
        setPermissions(res?.data?.data);
        // console.log(res.data.data);
      })
      .catch((err) => console.log("error fetching permissions", err))
      .finally(() => setLoading(false));
  }, [role, token]);

  return (
    <PermissionContext.Provider
      value={{ role, permissions, setRole, setPermissions }}
    >
      {!loading && children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () =>
  useContext(PermissionContext) || { permissions: [] };

// export const useRole = () => useContext(RoleContext) || { permissions: [] };
