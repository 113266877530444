import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import { MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { message, Select } from "antd";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { getData, postData } from "../services/NetworkServices";
import { useRole } from "../RoleContext";
import { usePermission } from "../PermissionContext";
import { IoIosArrowDropdownCircle } from "react-icons/io";

const Gin = ({ click,setClick }) => {
  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(new Date());
  const [userData, setUserData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [drop, setdrop] = useState(0);
  const [gindrop, setgindrop] = useState(0);
  const [material, setMaterial] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [removedMaterials, setRemovedMaterials] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [filterUserData, setFilterUserData] = useState([]);
  const [filterMaterialData, setFilterMaterialData] = useState([]);
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [available, setAvailable] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");

  const [gin, setgin] = useState({
    Date: "",
    User: "",
    userId: null,
    Quantity: 0,
    Material: {
      materialDescription: "",
      uom: "",
      materialCode: "",
      availableQuantity: null,
      materialId: null,
      batchId: null,
    },
  });
  const [showDropdown, setShowDropdown] = useState({
    materialDropdown: false,
    zone: false,
    user: false,
  });
  const dropdownRefs = {
    materialDropdown: useRef(null),
    zone: useRef(null),
    user: useRef(null),
  };
  const [loading, setLoading] = useState(false);
  const [ginlist, setginlist] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [updatedQuantity, setUpdatedQuantity] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [editAvailableQuantityDisplay, seteditAvailableQuantityDisplay] =
    useState(null);

  const navigate = useNavigate();

  const { permissions } = usePermission();
  const [editt, setEditt] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission?.pageRoute === "/gin") {
        if (permission?.canAdd) {
          setAdd(true);
        }
        if (permission?.canEdit) {
          setEditt(true);
        }
      }
    });
  };

  

  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  const incrementQuantity = () => {
    if (quantity < gin.Material.availableQuantity) {
      setQuantity((prevQuantity) => prevQuantity + 1);
      setgin({ ...gin, Quantity: quantity + 1 });
    }
  };

  const decrementQuantity = () => {
    if (quantity > 0 && quantity !== 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      setgin({ ...gin, Quantity: quantity - 1 });
    }
  };

  const handleAddMaterial = () => {
    const existingMaterial = (ginlist || []).find(
      (item) => item.Material.materialId === gin.Material.materialId
    );

    if (existingMaterial) {
      message.warning("This material is already selected.");
      return;
    }

    if (
      gin.User.trim() !== "" &&
      gin.Quantity > 0 &&
      gin.Material.materialDescription.trim() !== "" &&
      gin.Material.uom.trim() !== "" &&
      gin.Material.materialCode.trim() !== "" &&
      gin.Material.availableQuantity !== null &&
      gin.Material.batchCode !== null &&
      gin.Material.ID !== null
    ) {
      setginlist([...ginlist, gin]);

      const materialToRemove = material.find(
        (m) => m.materialId === gin.Material.materialId
      );

      setMaterial(
        material.filter((m) => m.materialId !== gin.Material.materialId)
      );

      if (materialToRemove) {
        setRemovedMaterials([...removedMaterials, materialToRemove]);
      }

      setgin({
        Date: gin.Date,
        User: gin.User,
        userId: gin.userId,
        Quantity: 0,
        Material: {
          materialDescription: "",
          uom: "",
          materialCode: "",
          availableQuantity: null,
          materialId: null,
          batchId: null,
        },
      });

      setQuantity(0);
      setAvailable(null);
      setSelectedMaterial("");
      setDisabled(true);
    }
  };

  const getMaterials = async () => {
    const token = localStorage.getItem("token");
    if (zoneId) {
      const res = await getData(`Stock/psp-stock/${zoneId}`);
      setMaterial(res?.data?.data);
      setFilterMaterialData(res?.data?.data);
      console.log("response for material", filterMaterialData);
    }
  };

  const getUsers = async () => {
    const res = await getData("User-Data/GetVisitorsforJC");
    const sortedUser = res?.data?.data?.sort((a, b) => {
      // console.log("Get User =>",res)
      return a?.fullName?.localeCompare(b?.fullName);
    });
    setUserData(sortedUser);
    setFilterUserData(sortedUser);
  };
  useEffect(() => {
    getUsers();
    getMaterials();
  }, [zoneId]);

  const handleDelete = (index) => {
    const newList = ginlist.filter((item, i) => i !== index);
    setginlist(newList);

    if (ginlist[index]) {
      const restoreMaterial = removedMaterials.find(
        (m) => m.materialId === ginlist[index].Material.materialId
      );

      if (restoreMaterial) {
        setRemovedMaterials(
          removedMaterials.filter(
            (m) => m.materialId !== restoreMaterial.materialId
          )
        );
        setMaterial([...material, restoreMaterial]);
      }
    }

    if (newList.length === 0) {
      setDisabled(false);
      setSelectedZone("");
      setSelectedUser("");
      setSelectedMaterial("");
      setQuantity("");
      setgin({ ...gin, Date: "", User: "" });
    }
  };

  const toggleEdit = (index) => {
    if (edit === false) {
      setEdit(true);
      setEditingRow(index);
      setUpdatedQuantity(ginlist[index].Quantity);
      const material = ginlist[index].Material;
      seteditAvailableQuantityDisplay(
        `Available: ${material.availableQuantity}`
      );
    } else {
      setEdit(false);
      setEditingRow(null);
      seteditAvailableQuantityDisplay(null);
    }
  };

  const handleEdit = (index, e) => {
    e.preventDefault();
    const updated = parseInt(updatedQuantity);
    const newList = ginlist.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          Quantity: updated,
        };
      }
      return item;
    });
    setginlist(newList);
    setEdit(false);
    setEditingRow(null);
  };

  const handleSubmitGIN = async () => {
    if (ginlist.length > 0) {
      setLoading(true);
      const token = localStorage.getItem("token");

      await postData(
        "Stock/GIN",
        {
          userInfoId: gin.userId,
          issuanceDate: gin.Date,
          zoneId: zoneId,
          details: ginlist.map((gin) => ({
            quantity: gin.Quantity,
            materialId: gin.Material.materialId,
            batchId: gin.Material.batchId,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setSelectedZone(null);
          message.success("GIN created Successfully.");

          // Clear the ginlist state
          setginlist("");

          // Reset the gin state
          setgin({
            Date: "",
            User: "",
            userId: null,
            Quantity: 0,
            Material: {
              materialDescription: "",
              uom: "",
              materialCode: "",
              availableQuantity: null,
              materialId: null,
              batchId: null,
            },
          });

          // Reset individual fields
          setQuantity(0);
          setAvailable(null);
          setSelectedMaterial("");
          setSelectedUser("");
          setSelectedZone("");
          setDisabled(false);

          // Optionally, you can also reset other related states
          setZoneId(null);
          setMaterial([]);
          setSelectedMaterial(null);

          // Fetch materials again if necessary
          getMaterials();
        })
        .catch((error) => {
          console.log(error?.response?.data);
          message.error(error?.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleQuantityChange = (e, index) => {
    const updated = parseInt(e.target.value);
    const material = ginlist[index].Material;
    if (updated > material.availableQuantity) {
    } else {
      setUpdatedQuantity(e.target.value);
    }
  };
// Get the stored user data from localStorage
const storedUserData = localStorage.getItem("userData");

// Check if the data exists before parsing
let zones = [];
if (storedUserData) {
  try {
    const userData = JSON.parse(storedUserData);
    zones = userData.zones || [];
  } catch (error) {
    console.error('Failed to parse userData:', error);
  }
} else {
  console.warn('No userData found in localStorage');
}

console.log('zones:', zones); // Check what zones contains


  const handleClickOutside = (e) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(e.target)
      ) {
        setShowDropdown((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    setDate(currentDate);
    setgin({ ...gin, Date: formattedDate });
  }, []);

  return (
    <main className="ml-6 ">
      <h1 className="h-[37px] my-4 ml-2 font-semibold text-[32px] ">
        Stock Management - GIN
      </h1>
      <div className="flex flex-col gap-5  justify-center items-center w-full mt-10 ">
        <div className="flex justify-evenly items-center w-full ">
          <div className="relative  w-[280px]">
            <DatePicker
              className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full relative w-[280px]"
              placeholderText="Select the Date"
              selected={date}
              onChange={(date) => {
                const formattedDate = date ? date.toISOString() : "";
                setDate(date);
                setgin({ ...gin, Date: formattedDate });
              }}
              disabled
            />
            <p className="absolute bottom-9 left-8 font-bold bg-white">Date*</p>
            <FaCalendarAlt className="absolute text-black bottom-3 right-6 text-md cursor-pointer" />
          </div>

          {/* Zones Starts here */}
          <div className="relative w-[280px] ">
            <Select
              showSearch
              allowClear
              placeholder="Select the Zone"
              value={selectedZone}
              onChange={(value) => {
                const selectedZone = zones.find((item) => item.id === value);
                setSelectedZone(selectedZone.title);
                setZoneId(value);
                if (!selectedZone) {
                  setSelectedMaterial("");
                }
              }}
              // disabled={!selectedRegion}
              filterOption={(input, option) => {
                const label = option.label || option.children;
                return label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              disabled={disabled}
            >
              {zones.map((item) => (
                <Select.Option
                  onClick={() => {
                    setSelectedZone(item.title);
                    setZoneId(item.id);
                  }}
                  onChange={setSelectedZone}
                  key={item.id}
                  value={item.id}
                  label={item.title}
                >
                  {item.title}
                </Select.Option>
              ))}
            </Select>

            <p className="absolute bottom-9 left-8 font-bold bg-white">Zone*</p>
          </div>

          {/* user starts Here */}

          <div className="relative  w-[280px]">
            <Select
              // className="w-[240px] z-10"
              placeholder="Select the User"
              value={selectedUser || undefined}
              onChange={(value, option) => {
                setSelectedUser(option.children);
                if (ginlist.length === 0) {
                  setgin({ ...gin, User: option.children, userId: value });
                }
              }}
              disabled={disabled}
            >
              {filterUserData.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.fullName}
                </Select.Option>
              ))}
            </Select>
            <p className="absolute bottom-9 left-6 font-bold bg-white px-2 z-10">
              User*
            </p>
          </div>
        </div>
        {/* Material Dropdown */}
        <div className="flex justify-evenly items-center w-full">
          <div className="relative w-[280px]">
            <Select
              className="w-[280px] z-10"
              placeholder="Select the Material"
              value={selectedMaterial || undefined}
              onChange={(value, option) => {
                const selectedItem = filterMaterialData.find(
                  (item) => item.materialId === value
                );
                setSelectedMaterial(option.children);
                setgin({
                  ...gin,
                  Material: {
                    materialDescription: selectedItem.materialDescription,
                    uom: selectedItem.unit,
                    materialCode: selectedItem.materialCode,
                    availableQuantity: selectedItem.availableQuantity,
                    materialId: selectedItem.materialId,
                    batchId: selectedItem.batchId,
                    batchCode: selectedItem.batchCode,
                  },
                });
                setAvailable(selectedItem.availableQuantity);
              }}
            >
              {(filterMaterialData || []).map((item) => (
                <Select.Option key={item.materialId} value={item.materialId}>
                  {`${item.materialDescription} | ${item.batchCode}`}
                </Select.Option>
              ))}
            </Select>
            <p className="absolute bottom-9 left-6 font-bold bg-white px-2 z-10">
              Material*
            </p>
          </div>

          {/* Quantity Input */}
          <div className="flex flex-col items-center  relative ">
            <div className="relative flex justify-center items-center w-[280px] ">
              <div className="relative">
                <input
                  type="text"
                  className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 w-[280px]"
                  value={quantity || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const parsedValue = value === "" ? 0 : parseInt(value);
                    if (
                      value === "" ||
                      (/^\d+$/.test(value) &&
                        parsedValue <= gin.Material.availableQuantity)
                    ) {
                      setQuantity(parsedValue);
                      setgin({ ...gin, Quantity: parsedValue });
                    }
                  }}
                />
                <div className="absolute bottom-9 left-8 text-sm bg-white font-bold">
                  Quantity*
                </div>
              </div>
              <div className="absolute right-5 bottom-1 text-sm font-bold flex flex-col items-center justify-center">
                <button
                  className="text-black rounded-full focus:outline-none"
                  onClick={incrementQuantity}
                >
                  <TiArrowSortedUp className="cursor-pointer" size={16} />
                </button>
                <button
                  className="text-black rounded-full focus:outline-none"
                  onClick={decrementQuantity}
                >
                  <TiArrowSortedDown className="cursor-pointer" size={16} />
                </button>
              </div>
            </div>
            {available !== null && (
              <div className="flex justify-center">
                <h1 className="font-semibold">
                  Available Quantity:{" "}
                  <span className="font-bold">{available}</span>
                </h1>
              </div>
            )}
          </div>

          {/* Add Material Button */}
          <div className="flex justify-center items-center  ">
            {add && (
              <button
                className=" btnForNotes btn w-[240px]"
                onClick={handleAddMaterial}
                disabled={
                  !date ||
                  !selectedZone ||
                  !selectedMaterial ||
                  !selectedUser ||
                  !quantity
                }
              >
                Add Material
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Table */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="rounded-2xl mb-4 mt-8 border border-[#1E1E1E] min-h-[30vh] h-[40vh] overflow-auto">
            <table className="w-full mx-auto overflow-auto">
              <thead className="bg-[#F7F7F7] border-b-2 border-[#1E1E1E] rounded-2xl text-left overflow-auto">
                <tr>
                  <th className="px-4 pb-1 pt-4 rounded-tl-2xl">
                    Material code
                  </th>
                  <th className="px-4 pb-1 pt-4 mr-4">Material description</th>
                  <th className="px-4 pb-1 pt-4 ">Batch code</th>
                  <th className="px-4 pb-1 pt-4 ">Quantity</th>
                  <th className="px-4 pb-1 pt-4 rounded-tr-2xl "></th>
                </tr>
              </thead>

              <tbody className="rounded-2xl  overflow-scroll">
                {Array.isArray(ginlist) && ginlist.length > 0 ? (
                  ginlist.map((gin, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-[#E7E7E7]" : "bg-white"}
                    >
                      <td className={`px-4 py-2 `}>
                        {gin.Material.materialCode}
                      </td>
                      <td className={`px-4 py-2 `}>
                        {gin.Material.materialDescription}
                      </td>
                      <td className={`px-4 py-2 `}>{gin.Material.batchCode}</td>
                      {editingRow === index ? (
                        <td
                          className={`px-4 py-2  ${
                            editAvailableQuantityDisplay
                              ? " flex items-center gap-4"
                              : ""
                          }`}
                        >
                          {editAvailableQuantityDisplay && (
                            <div className="font-semibold">
                              {editAvailableQuantityDisplay}
                            </div>
                          )}
                          <form
                            onSubmit={(e) => handleEdit(index, e)}
                            className="relative flex justify-between items-center"
                          >
                            <input
                              type="text"
                              value={updatedQuantity || ""}
                              onChange={(e) => handleQuantityChange(e, index)}
                              className="rounded-3xl pl-2 py-1 w-24 outline-none border border-[#1E1E1E]"
                            />
                            <div className="absolute bottom-2 right-1 flex justify-center items-center gap-2">
                              <button type="submit">
                                <MdOutlineDone className="cursor-pointer h-4 w-4" />
                              </button>
                              <RxCross2
                                onClick={() => {
                                  setEditingRow(null);
                                  seteditAvailableQuantityDisplay(null);
                                }}
                                className="cursor-pointer h-4 w-4"
                              />
                            </div>
                          </form>
                        </td>
                      ) : (
                        <td className={`px-4 py-2 `}>{gin.Quantity}</td>
                      )}
                      <td className={`px-4 py-2 `}>
                        <div className="flex flex-row justify-end items-center gap-0">
                          <button className="text-black px-2 py-1 rounded-full">
                            <GrEdit
                              size={20}
                              className="p-0 m-0"
                              onClick={() => toggleEdit(index)}
                            />
                          </button>
                          <button className="text-black px-2 py-1 rounded-full -ml-1">
                            <RiDeleteBinLine
                              size={20}
                              className="p-0 m-0"
                              onClick={() => handleDelete(index)}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="m-4 font-normal">No data to show</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end gap-4">
            {add && (
              <button
                className=" btnForNotes btn  w-[180px]"
                onClick={handleSubmitGIN}
              >
                Create GIN
              </button>
            )}
            <button
              className="btnForNotesCancel btn w-[180px]"
              onClick={() => {
                navigate("/GIN");
                setClick(!click);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </main>
  );
};

export default Gin;
