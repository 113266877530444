import React, { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import formatDate from "./DateFormatFunc";
import { useRole } from "../../RoleContext";
import { usePermission } from "../../PermissionContext";

export default function JourneyDetails({ data }) {
  // const [EditID, setEditID] = useState(null);
  const { permissions } = usePermission();
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/journey") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  // const hasPermission = (permission) => permissions.includes(permission);

  const navigate = useNavigate();
  return (
    <div className="w-full bg-[#F7F7F7] shadow-xl flex justify-center items-center flex-col rounded-md ">
      <div className="w-11/12 flex justify-between items-center ">
        <h1 className="text-2xl font-bold pt-4"> </h1>
        {edit && (
          <button
            className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl btnSubmit btn"
            onClick={() => {
              data &&
                data.journeyMasterID &&
                navigate(
                  `/editJourney/${data.journeyMasterID}-${data?.details[0]?.journetDetailID}`,
                  {
                    state: { data },
                  }
                );
            }}
          >
            Edit Journey
          </button>
        )}
      </div>
      <div className="w-11/12 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full mb-6 mt-6">
          <ul className="flex justify-evenly items-center border-b-2 border-black w-full">
            <li className=" w-28">Business unit</li>
            <li className=" w-28">Visit Date</li>
            <li className=" w-28">Objectives</li>
            <li className=" w-28">Status</li>
            <li className=" w-28">Town</li>
            
          </ul>
          <div className="flex flex-col justify-center items-center w-full mt-1">
            {data?.details?.map((item, index) => (
              <ul
                key={index}
                className={`flex px-2 h-16 justify-evenly items-center w-full text-sm mt-1 ${
                  index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                } hover:scale-105   transition-transform duration-200 ease-in-out`}
              >
                <li className=" w-28 px-2">{item.busniessUnitName}</li>
                <li className="w-28">
                  {formatDate(item?.visitDate.split("T")[0])}
                </li>
                <li className="flex justify-center items-center flex-col  w-28">
                  {item.objectiveName}
                </li>
                <li className=" w-28 px-2">{item.visitStatusName}</li>
                <li className=" w-28">{item.subLocalityName}</li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
