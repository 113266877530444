import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import JourneyPlanNewVisitPopUp from "./JourneyPlanNewVisitPopUp";
import formatDate from "./DateFormatFunc";
// useEffect
export default function MarketVisit({
  visitData,
  setVisitData,
  zoneId,
  zoneTitle,
  selectedValues,
  edit,
  userId,
  setuserId,
  regionId,
}) {
  const [pop, setPop] = useState(false);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    setVisits([...visitData]);
  }, [visitData]);

  const addVisit = (newVisit) => {
    setVisits([...visits, newVisit]);
    setVisitData([...visits, newVisit]);
    setPop(false);
  };

  const deleteVisit = (indexToDelete) => {
    let newdata = visits.filter((_, index) => index !== indexToDelete);
    setVisitData(newdata);
    setVisits(newdata);
  };

  return (
    <div className="w-full bg-[#F7F7F7] shadow-xl flex justify-center items-center flex-col rounded-md border-4 border-gray-200">
      <div className="w-11/12 flex justify-between items-center">
        <h1 className="text-2xl font-bold pt-4">Visits</h1>
        <button
          id="disbaleBtn"
          disabled={
            !selectedValues.journeyTitle ||
            !selectedValues.startDate ||
            !selectedValues.endDate ||
            // !selectedValues.region ||
            // !selectedValues.zone ||
            !selectedValues.visitor
          } // Disable button if selectedValues is empty
          onClick={() => setPop(true)}
          className="flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005] font-semibold btnSubmit btn"
        >
          Add Visit
        </button>
      </div>
      <div className="w-11/12 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full mb-6 mt-6">
          <ul className="flex justify-evenly items-center border-b-2 border-black w-full">
            <li className="w-28">Date</li>
            <li className="w-28">Town</li>
            <li className="pl-3 w-28">Area</li>
            <li className="w-28">Business Unit</li>
            <li className=" pl-5 w-28">Objective</li>
            {/* <li className="pl-5 w-28">
              <p>No.of</p>
              <p>Person</p>
            </li> */}
            <li className="w-28">Status</li>
          </ul>
          <div className="flex flex-col justify-center items-between w-full mt-5">
            {visits.map((item, index) => (
              <div
                key={index}
                className={`relative flex gap-1 w-full ${
                  index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                } hover:scale-105 transition-transform duration-200 ease-in-out`}
              >
                <ul className="flex justify-evenly items-center w-full text-sm p-2">
                  <li className="w-28">
                    {!edit
                      ? formatDate(item.visitDate)
                      : item.visitDate.split("T")[0]}
                  </li>
                  <li className="w-28">{item.townName || item.town}</li>
                  <li className="w-28">{item.area || item.areaName}</li>
                  <li className="w-28">
                    {item.busniessUnitName || item.businessUnit}
                  </li>
                  <li className="pl-5 w-28">
                    {item.objectiveName || item.objective}
                  </li>
                  {/* <li className="w-28">
                    <p>{item.personName}</p>
                    <p>{item.personContact}</p>
                  </li> */}
                  <li className="w-28">{item.status}</li>
                </ul>
                <button
                  className="absolute right-2 top-2"
                  onClick={() => deleteVisit(index)}
                >
                  <MdDelete className="text-2xl cursor-pointer" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {pop ? (
        <JourneyPlanNewVisitPopUp
          pop={pop}
          zoneId={zoneId}
          zoneTitle={zoneTitle}
          setPop={setPop}
          addVisit={addVisit}
          selectedValues={selectedValues}
          userId={userId}
          regionId={regionId}
        />
      ) : null}
    </div>
  );
}
