import React, { useEffect, useState } from "react";
import { getData } from "../../services/NetworkServices";
import { Pagination } from "antd";
import dayjs from "dayjs";

function AutoDas(props) {
  const [stockAutoData, setStockAutoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  const { setsearch, search, setcodesearch, codesearch } = props;
  const paginatedData = stockAutoData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    getData("Stock/auto-DAs").then((res) => {
      setStockAutoData(res.data.data);
      console.log("Stock/auto-DAs", res.data.data);
    });
  }, []);

  return (
    <>
      <div className="w-[80vw] h-[60vh] flex justify-center items-start border border-black rounded-3xl overflow-auto ">
        <div className="flex flex-col w-full max-w-full">
          {/* Table Header */}
          <div className="w-full static">
            <ul className="flex justify-start items-center min-w-max bg-[#F7F7F7] rounded-t-2xl h-12 border-b border-black">
              {[
                "Shipment Number",
                "Status",
                "Created On",
                "Customer Code",
                "Customer Name",
                "Material Code",
                "Batch Code",
                "Quantity",
                "BUOM",
                "Plant Code",
                "Plant Description",
                "Region",
                "Zone Code",
                "Zone Description",
                "Town",
                "Quotation Type",
                "Reference Code Description",
                "Reference Date",
                "Error Message",
              ].map((heading, index) => (
                <li
                  key={index}
                  className="w-[150px] flex justify-center items-center font-semibold"
                >
                  {heading}
                </li>
              ))}
            </ul>
          </div>

          {/* Table Body with Scroll */}
          <div className="w-full h-[300px]">
            {paginatedData.length === 0 ? (
              <p className="flex justify-center items-center w-full text-red-600">
                No data available
              </p>
            ) : (
              <div className="min-w-max">
                {paginatedData
                  ?.filter((item) =>
                    codesearch.toLowerCase() === ""
                      ? item
                      : (
                          item.customer ||
                          item.materialCode ||
                          item.shipNO ||
                          item.custName
                        )
                          .toLowerCase()
                          .includes(codesearch.toLowerCase())
                  )
                  .map((item, index) => (
                    <ul
                      key={index}
                      className={`flex justify-start items-center w-full h-14 ${
                        index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"
                      } transition-transform duration-200 ease-in-out`}
                    >
                      <li
                        className="w-[150px] flex justify-center items-center"
                        onClick={() => {
                          setsearch(item.shipNO);
                        }}
                      >
                        {item.shipNO}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.autoDAStatus}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {dayjs(item.createdOn).format("YYYY-MM-DD")}
                      </li>
                      <li
                        className="w-[150px] flex justify-center items-center"
                        onClick={() => {
                          setsearch(item.customer);
                        }}
                      >
                        {item.customer}
                      </li>
                      <li
                        className="w-[150px] flex justify-center items-center"
                        onClick={() => {
                          setsearch(item.custName);
                        }}
                      >
                        {item.custName}
                      </li>
                      <li
                        className="w-[150px] flex justify-center items-center"
                        onClick={() => {
                          setsearch(item.materialCode);
                        }}
                      >
                        {item.materialCode}
                      </li>
                      <li className="w-[150px] flex justify-center items-center text-sm">
                        {item.batchCode}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.quantity}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.buom}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.plant}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.plantDesc}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.region}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.zoneCode}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.zoneName}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.town}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.quotationType}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {item.refCodeDesc}
                      </li>
                      <li className="w-[150px] flex justify-center items-center">
                        {dayjs(item.refDate).format("YYYY-MM-DD")}
                      </li>
                      <li className="w-[150px] flex justify-center items-center text-sm">
                        {item.errorMessage}
                      </li>
                    </ul>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-start items-center mt-4  pt-1 text-center">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={stockAutoData.length}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
        />
      </div>
    </>
  );
}

export default AutoDas;
