import { DatePicker } from 'antd'
import React, { useState, useEffect } from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { FaAngleDown, FaAngleUp, FaMagnifyingGlass } from 'react-icons/fa6'
import { useLocation, useNavigate } from 'react-router-dom'
import { getData, postData } from '../services/NetworkServices.js'
import axios from 'axios'
export default function CreateSurvey() {
  const token1 = localStorage.getItem('token');
  const [startDate,setStartDate] = useState([])
  const [drop,setdrop] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const [question, setQuestions] = useState([{
    question: '',
    type: 'Short Text',
    answer: '',
    options: [],
    required: false
  }])
  const [statuses, setStatus] = useState([])
  const [rtypes, setTypes] = useState([])
  const [zones, setZone] = useState([])
  const [regions, setRegion] = useState([])
  const [form, setFormValue] = useState({})
  const typeList = [
    {
      "questionTypeId": 1,
      "questionTypeName": "Short Text"
    },
    {
      "questionTypeId": 2,
      "questionTypeName": "Long Text"
    },
    {
      "questionTypeId": 5,
      "questionTypeName": "Date"
    },
    {
      "questionTypeId": 6,
      "questionTypeName": "File"
    },
    {
      "questionTypeId": 7,
      "questionTypeName": "Number"
    }
  ]

  const setForm = (k, v) => {
    setFormValue({...form, [k]: v})
  }

  useEffect(() => {
    Promise.all([
      getData("master-record-type"),
      getData('Status'),
      getData("Zone/zones/2"),
      getData("region")
    ]).then(resposes => { debugger;
      setStatus(resposes[0].data.data)
      setTypes(resposes[1].data)
      setZone(resposes[2].data)
      setRegion(resposes[3].data)
    })
  }, [])

  const setvalue = (index, field, value) => {
    const temp = [...question];
    temp[index][field] = value;
    temp[index] = { ...temp[index] }
    setQuestions(JSON.parse(JSON.stringify(temp)))
  }

  const create = () => {
    postData("Survey/AddSurvay", {
      "description": "string",
      "status": form.status,
      "recordMasterTypeID": form.record,
      "regionID": form.region,
      "zoneID": form.zone,
      "publishDate": form.publishDate,
      "expiryDate": form.expiryDate,
      "addSurvayQuestionDetails": [...question.map((q, i) => ({
        "questionTypeId": q.type,
        "questionDescription": q.answer,
        "status": true,
        "required": q.required,
        "sequenceNumber": i,
        "isDeleted": false,
      }))]
    })
    .then(response => {
      debugger;
    })
  }

  const add = () => {
    setQuestions([...question, {
      question: '',
      type: 'Short Text',
      answer: '',
      options: [],
      required: false
    }])
  }

  return (
    <div className="flex justify-center flex-col items-center mt-1 w-full">
      <div className="flex flex-col justify-center items-center w-full">
        { /* Main Heading starts */ }
        <div className="flex justify-start items-start w-full my-6">
          <h3 className="font-semibold text-3xl">Surveys - Create Survey</h3>
        </div>
        { /* Main Heading ends */ }

        <div className='w-11/12 flex justify-center items-center bg-white rounded-2xl shadow-md mb-6'>
          <div className='h-64 flex justify-center items-start w-full'>
            <div className='flex flex-col justify-start items-center w-full mt-2 ml-8'>
              { /* Sub Heading starts */ }
              <div className='w-full'>
                <h2 className='font-semibold text-2xl'>Survey Details</h2>
              </div>
              { /* Sub Heading ends */ }

              <div className='flex justify-between w-full mt-2 flex-wrap'>
                { /* Survey name starts */ }
                <div className='flex justify-center items-center  my-2 relative w-80'>
                  <input
                    type="text"
                    value={form.name}
                    className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                    name="username"
                    onChange={(e) => setForm('name', e.target.value)}
                  />
                  <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>Survey Name</div>
                </div>
                { /* Survey name ends */ }

                { /* Publish date starts */ }
                <div className="relative flex justify-center  my-2 items-center w-80">
                  <DatePicker
                    autoComplete="off"
                    selected={form.startDate}
                    onChange={(e) => setForm('publishDate', e)}
                    className=" py-3 px-14 w-[350px] border-2 border-red-400 rounded-full relative "
                    placeholderText="Select the Date"
                    name='startDate'
                  />
                  <FaCalendarAlt className="absolute bottom-4 right-4 text-xl cursor-pointer" />
                  <p className="absolute -top-[9px] left-12 font-bold text-sm bg-white">Publish Date</p>
                </div>
                { /* Publish date ends */ }

                { /* Expiry date starts */ }
                <div className="relative flex flex-wrap justify-between  my-2 items-center w-80">
                  <DatePicker
                    autoComplete="off"
                    selected={form.expiryDate}
                    onChange={(e) => setForm('expiryDate', e)}
                    className=" py-3 px-14 w-[350px] border-2 border-red-400 rounded-full relative"
                    placeholderText="Select the Date"
                    name='startDate'
                  />
                  <FaCalendarAlt className="absolute bottom-4 right-4 text-xl cursor-pointer" />
                  <p className="absolute -top-[9px] left-12 font-bold text-sm bg-white">Expiry Date</p>
                </div>
                { /* Expiry date ends */ }

                { /* Record type starts */ }
                <div className="relative flex justify-center  my-2 items-center w-80">
                  <label className="absolute bottom-8 bg-white left-9 font-bold">Record Type</label>
                  <select value={form.type} onChange={(e) => setForm('type', e.target.value)} className="outline-red-200 py-2 px-6 mt-2 w-full border-2 border-red-200 rounded-full">
                    { rtypes.map(r => (<option value={r.id}>{r.name || r.title}</option>))}
                  </select>
                </div>
                { /* Record type ends */ }

                { /* Region type starts */ }
                <div className="relative flex justify-center  my-2 items-center w-80">
                  <label className="absolute bottom-8 bg-white left-9 font-bold">Region</label>
                  <select value={form.region} onChange={(e) => setForm('region', e.target.value)} className="outline-red-200 py-2 px-6 mt-2 w-full border-2 border-red-200 rounded-full">
                    { regions.map(r => (<option value={r.id}>{r.name || r.title}</option>))}
                  </select>
                </div>
                { /* Region type ends */ }

                { /* Zone type starts */ }
                <div className="relative flex justify-center  my-2 items-center w-80">
                  <label className="absolute bottom-8 bg-white left-9 font-bold">Region</label>
                  <select value={form.zone} onChange={(e) => setForm('zone', e.target.value)} className="outline-red-200 py-2 px-6 mt-2 w-full border-2 border-red-200 rounded-full">
                    { zones.map(r => (<option value={r.id}>{r.name || r.title}</option>))}
                  </select>
                </div>
                { /* Zone type ends */ }

                { /* Status starts */ }
                <div className="relative flex justify-center  my-2 items-center w-80">
                  <label className="absolute bottom-8 bg-white left-9 font-bold">Status</label>
                  <select value={form.status} onChange={(e) => setForm('status', e.target.value)} className="outline-red-200 py-2 px-6 mt-2 w-full border-2 border-red-200 rounded-full">
                    { statuses.map(r => (<option value={r.id}>{r.name || r.title}</option>))}
                  </select>
                </div>
                { /* Status ends */ }
              </div>
            </div>
          </div>
        </div>

        {/* Questions */}
        { question.map((q, i) => (
          <div className='w-11/12 flex justify-center items-center bg-white rounded-2xl shadow-md mb-5'>
            <div className='h-64 flex justify-center items-start w-full'>
              <div className='flex flex-col justify-start items-center w-full'>

                <div className='mt-4 ml-4 flex justify-between items-center w-full'>
                  {/* Questions title starts */}
                  <div className='flex justify-center items-center'>
                    <h1 className='font-semibold text-2xl'>Question {i + 1}</h1>
                  </div>
                  {/* Questions title ends */}

                  {/* toggler starts */}
                 <div className='flex justify-center items-center'>
                   <div className='mr-4 font-semibold'>
                     <h1>Required</h1>
                   </div>
                   <div className='flex justify-center items-center w-full transition-all duration-300 mr-3'>
                     <div className={`w-24 h-10 border border-[#F50005] rounded-2xl flex justify-start transition-all duration-300 items-center gap-x-2 relative`}>
                       <div onClick={() => setvalue(i, 'required', false) } className={`absolute top-2 cursor-pointer transition-all duration-300 left-2 ${!q.required?"text-white":""}`}>Yes</div>
                       <div onClick={() => setvalue(i, 'required', true)} className={`absolute top-2 cursor-pointer right-2 transition-all duration-300 ${q.required?"text-white":""}`}>No</div>
                       <span className={`bg-[#F50005] w-[48px] h-10  flex justify-center transition-all duration-300 ${q.required?"ml-12":"ml-o"} rounded-2xl`}></span>
                     </div>
                   </div>
                 </div>
                 {/* toggler ends */}
                </div>

                <div className='flex justify-center items-start w-full'>
                  <div className='w-2/3 flex flex-col justify-center items-center ml-12 mt-10 '>
                    <div className='flex justify-center items-center relative  w-full'>
                      <input
                        type="text"
                        className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                        value={q.question}
                        name="username"
                        onChange={(e) => setvalue(i, 'question', e.target.value)}
                        />
                        <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                          Question
                        </div>
                        <div className="absolute left-5 text-sm font-bold">
                          <FaMagnifyingGlass className='w-5 h-5'/>
                        </div>
                      </div>
                      <div className='flex justify-center items-center relative mt-7  w-full'>
                        {
                          q.type == 1 ? (<><input
                            type="text"
                            className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                            value={q.answer}
                            onChange={(e) => setvalue(i, 'answer', e.target.value)}
                          /><div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                          </div>
                          <div className="absolute left-5 text-sm font-bold">
                            <FaMagnifyingGlass className='w-5 h-5'/>
                          </div></>) : ''
                        }

                        {
                          q.type == 7 ? (<><input
                            type="number"
                            className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                            value={q.answer}
                            onChange={(e) => setvalue(i, 'answer', e.target.value)}
                          /><div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                          </div>
                          <div className="absolute left-5 text-sm font-bold">
                            <FaMagnifyingGlass className='w-5 h-5'/>
                          </div></>) : ''
                        }

                        {
                          q.type == 2 ? (<><textarea
                            className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                            value={q.answer}
                            name="username"
                            onChange={(e) => setvalue(i, 'answer', e.target.value)}
                          ></textarea>
                          <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>
                          </div>
                          <div className="absolute left-5 text-sm font-bold">
                            <FaMagnifyingGlass className='w-5 h-5'/>
                          </div></>) : ''
                        }

                        {
                          q.type == 5 ? (<><DatePicker
                            autoComplete="off"
                            selected={q.answer}
                            onChange={(date) => setvalue(i, 'answer', date)}
                            className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                            placeholderText="Select the Date"
                          />
                          <FaCalendarAlt className="absolute bottom-4 right-4 text-xl cursor-pointer" /></>) : ''
                        }

                        {
                          q.type == 6 ? (<><input style={{opacity: 0, zIndex: 9999}}
                            type="file"
                            autoComplete="off"
                            selected={q.answer}
                            onChange={(date) => setvalue(i, 'answer', date)}
                            className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400"
                            placeholderText="Select the Date"
                          /><span
                          className="rounded-3xl px-14 py-3 w-full outline-none border-2 border-red-400 absolute">Upload file</span></>) : ''
                        }
                      </div>
                    </div>


                    <div className='w-1/3  mt-10 ml-2'>
                      <div className='flex justify-center items-center relative  w-full transition-all duration-500 '>
                        <select onChange={(e) => setvalue(i, 'type', e.target.value)} className="rounded-3xl px-6 py-3 w-full outline-none border-2 border-red-400">
                          {
                            typeList.map(tl => (<option value={tl.questionTypeId}>{tl.questionTypeName}</option>))
                          }
                        </select>
                        <div className='absolute -top-[9px] left-12 text-sm bg-white font-bold'>Type</div>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        ))}


        <span className="bg-[#F50005] text-white rounded w-[20px] text-center" onClick={add}>+</span>
      </div>

      <div className="flex mt-6 align-right" style={{marginLeft: 'auto'}}>
      <button onClick={()=>{ create() }} className='flex ml-5 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-12 py-3 rounded-bl-3xl bg-[#F50005] hover:bg-red-600 hover:scale-110 transition-all duration-500'> Create</button>
      <button onClick={()=>{navigate("/surveys")}} className='flex ml-5 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-12 py-3 rounded-bl-3xl bg-black hover:scale-110 transition-all duration-500'> Cancel</button>
      </div>
    </div>)
}
