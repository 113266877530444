import React, { useEffect, useState } from "react";
import { postData, putData } from "../../services/NetworkServices";
import { message, Select } from "antd";
import { SelectProps } from "antd";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  UpCircleOutlined,
} from "@ant-design/icons";
import { BsCircle } from "react-icons/bs";
import { useRole } from "../../RoleContext";

import { usePermission } from "../../PermissionContext";
//import "../MasterManagement/"

// Initialize options for Select component
// const options: SelectProps["options"] = [];
// for (let i = 10; i < 36; i++) {
//   options.push({
//     value: i.toString(36) + i,
//     label: i.toString(36) + i,
//   });
// }

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function CreateNew({
  category,
  data,
  setData,
  Edit,
  EditData,
  setEdit,
  setDataChanged,
  DataChanged,
  changingData,
}) {
  const [Name, setName] = useState(
    Edit
      ? ((category == "objectives" || category == "visitSteps") &&
          EditData.title) ||
          (category == "recordTypes" && EditData.name)
      : ""
  );
  const [Status, setStatus] = useState(Edit ? "" : EditData.status);
  const [Description, setDescription] = useState(
    Edit && category == "recordTypes" ? EditData.description : ""
  );
  const [DistributedTo, setDistributedTo] = useState([]); // New state for Select component
  const [Loading, setLoading] = useState(false);
  const [VisitStepsData, setVisitStepsData] = useState(
    Edit && category == "visitSteps" ? EditData && EditData : null
  );

  const resetValues = () => {
    setName("");
    setStatus("");
    setDescription("");
    setDistributedTo([]); // Reset the Select component
  };

  useEffect(() => {
    resetValues();
    setEdit(false);
  }, [category]);

  useEffect(() => {
    if (Edit) {
      if (category == "recordTypes") {
        let incomingStatus = [];
        EditData?.distributionStatusDto?.map((o) =>
          incomingStatus.push(o.title)
        );
        setName(EditData.name);
        setDistributedTo(incomingStatus); // Pre-fill Select component
        setDescription(EditData.description);
        setStatus(EditData.status == true ? "true" : "false");
      } else if (category == "objectives" || category == "visitSteps") {
        setName(EditData.title);
        setStatus(EditData.status == true ? "true" : "false");
      }
      setVisitStepsData(EditData);
    }
  }, [Edit, EditData, category]);

  const getFieldName = () => {
    if (category == "recordTypes") {
      return "Record Type Name*";
    } else if (category == "objectives") {
      return "Objective Name";
    } else if (category == "visitStatuses") {
      return "Status Name";
    } else if (category == "visitSteps") {
      return "Visit Step Name";
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const recordTypePayload = {
        name: Name,
        status: Status == "true" ? true : false,
        description: Description,
        distributionStatusName: DistributedTo, // Include in payload
      };

      const objectivePayload = {
        title: Name,
        status: Status == "true" ? true : false,
      };

      if (!Edit) {
        let res;
        if (category == "recordTypes") {
          res = await postData(
            `master-record-type/AddMasterRecordType`,
            recordTypePayload
          );
        } else if (category == "objectives") {
          res = await postData(`Objective`, objectivePayload);
        } else {
          console.log("Still Under Construction");
        }

        if (res && res.status == 200) {
          setLoading(false);
          message.success("Record Added Successfully.");
          // let createdRecordTypeData = res.data.data
          category == "recordTypes"
            ? setData([...data, res.data.data])
            : setData([...data, res.data]);
          // setData([...data, res.data])
          setDataChanged(!DataChanged);
          resetValues();
        } else {
          setLoading(false);
          message.error("Record was not created.");
        }
      } else {
        let res;
        if (category == "recordTypes") {
          res = await putData(
            `master-record-type/${EditData.id}`,
            recordTypePayload
          );
        } else if (category == "objectives") {
          res = await putData(`objective/${EditData.id}`, objectivePayload);
        } else if (category == "visitSteps") {
          setVisitStepsData({
            ...VisitStepsData,
            ["status"]: Status,
            ["title"]: Name,
          });
          res = await putData(`status/editSatus`, VisitStepsData);
        }

        if (res && res.status == 200) {
          setData(
            data.map((obj) => {
              if (obj.id === EditData.id) {
                return category == "visitSteps"
                  ? VisitStepsData
                  : category == "recordTypes"
                  ? res.data.data
                  : res.data;
              }
              return obj;
            })
          );
          setDataChanged(!DataChanged);
          resetValues();
          setLoading(false);
          message.success("Record was successfully edited.");
        } else {
          setLoading(false);
          message.error("Error: Record not added. Try again.");
        }
        setEdit(false);
      }
    } catch (Err) {
      console.log(Err);
    }
  };

  const { permissions } = usePermission();
  const [editt, setEditt] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions.forEach((permission) => {
      if (permission.pageRoute === "/masterrecordmanagement") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEditt(true);
        }
      }
    });
  };
  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <form
      onSubmit={(e) => {
        if (add) {
          handleSubmit(e);
        } else {
          e.preventDefault();
          message.error("You don't have permission to add a new record.");
        }
      }}
      className="w-[27vw] h-[60vh] border-[1px] rounded-3xl "
      style={{ boxShadow: "0 4px 5px -1px rgba(0, 0, 0, 0.5)" }}
    >
      {Loading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full h-full  flex flex-col items-center justify-between">
          <h1 className="pl-10 my-3 text-2xl font-bold self-start">
            {category == "visitSteps" || Edit ? "Edit" : "Create New"}
          </h1>
          <div className="relative w-10/12">
            <label className=" absolute bottom-8 bg-white left-9 font-bold">
              {getFieldName()}
            </label>
            <input
              type="text"
              spellCheck={false}
              className="w-full py-2 pl-9 border-[1px] border-red-400 rounded-3xl outline-red-400"
              value={Name}
              required
              onChange={(e) => {
                setName(e.target.value);
                category == "visitSteps" &&
                  setVisitStepsData({
                    ...VisitStepsData,
                    ["title"]: e.target.value,
                  });
              }}
            />
          </div>
          {category == "recordTypes" && (
            <>
              <div className="relative w-10/12 mt-4">
                <label className="absolute -top-3 bg-white left-9 font-bold">
                  Description*
                </label>
                <textarea
                  type="textbox"
                  spellCheck={false}
                  style={{ resize: "none" }}
                  className="w-full pl-9 block h-[8vh] px-2 pt-4 border-[1px] border-red-400 rounded-3xl outline-red-400"
                  value={Description}
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="relative w-10/12 mt-4">
                <label className="absolute -top-3 bg-white left-9 font-bold z-10">
                  Distributed To*
                </label>
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                    // height: "50px",
                    // borderRadius: ,
                    // borderColor: "red",
                  }}
                  //options={options}
                  onChange={(value) => setDistributedTo(value)}
                  tokenSeparators={[","]}
                  value={DistributedTo} // Bind state to Select component
                  required
                  className="inputSelect custom-select"
                />
              </div>
            </>
          )}

          <div className="relative w-10/12">
            <label className="absolute bottom-8 bg-white left-9 font-bold">
              Status*
            </label>
            <select
              value={Status}
              onChange={(e) => {
                setStatus(e.target.value);
                category == "visitSteps" &&
                  setVisitStepsData({
                    ...VisitStepsData,
                    ["status"]: e.target.value == "true" ? true : false,
                  });
              }}
              required
              className="block pl-9 w-full px-4 py-3 mt-2 border border-red-600 rounded-3xl shadow-sm focus:outline-none focus:border-red-500"
            >
              <option defaultValue={true} disabled value=""></option>
              <option className="pl-9" value="true">
                Active
              </option>
              <option className="pl-9" value="false">
                Inactive
              </option>
            </select>
          </div>
          {category == "visitSteps" && (
            <div className="flex space-x-10">
              <div className="flex items-center space-x-2">
                {VisitStepsData && VisitStepsData.isGeoFencing && Edit ? (
                  <CheckCircleOutlined
                    className="text-black"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisitStepsData({
                        ...VisitStepsData,
                        ["isGeoFencing"]: false,
                      });
                    }}
                  />
                ) : (
                  <BsCircle
                    className="text-black"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisitStepsData({
                        ...VisitStepsData,
                        ["isGeoFencing"]: true,
                      });
                    }}
                  />
                )}
                <span className="text-black font-bold select-none">
                  Geofencing
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {VisitStepsData && VisitStepsData.isSelfileRequired && Edit ? (
                  <CheckCircleOutlined
                    className="text-black"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisitStepsData({
                        ...VisitStepsData,
                        ["isSelfileRequired"]: false,
                      });
                    }}
                  />
                ) : (
                  <BsCircle
                    className="text-black"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisitStepsData({
                        ...VisitStepsData,
                        ["isSelfileRequired"]: true,
                      });
                    }}
                  />
                )}
                <span className="text-black font-bold select-none">
                  Take Picture
                </span>
              </div>
            </div>
          )}
          <div className="w-full pb-7 flex justify-evenly">
            <button
              className="btnSubmit btn "
              style={{ width: "8vw" }}
              type="submit"
            >
              Submit
            </button>
            <button
              className="btnCancel btn"
              style={{ width: "8vw" }}
              onClick={() => {
                resetValues();
                Edit && setEdit(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </form>
  );
}

export default CreateNew;
