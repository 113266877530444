import { AutoComplete } from "antd";

const UserFields = [
  {
    name: "Email*",
    type: "email",
    required: true,
    key: "email",
  },
  {
    name: "Username*",
    type: "text",
    required: true,
    key: "username",
  },
  {
    AutoComplete:"off",
    name: "Password*",
    type: "password",
    // required: true,
    key: "password",
  },
  {
    name: "Full Name*",
    type: "text",
    required: true,
    key: "fullName",
  },
  {
    name: "Contact No.",
    type: "text",
    required: false,
    key: "contactNo",
  },
  {
    name: "Role*",
    type: "dropdown",
    required: true,
    url: "User-Data/roles",
    key: "userRole",
  },
  {
    name: "Region*",
    type: "dropdown",
    required: true,
    url: "region",
    // id: "regionds",
    key: "regionIds",
  },
  {
    name: "Zone*",
    type: "dropdown",
    required: true,
    url: "Zone/zones/2",
    key: "zoneIds",
  },
  {
    name: "Record Type*",
    type: "dropdown",
    required: true,
    url: "master-record-type",
    key: "masterRecordTypeId",
  },
  {
    name: "Reporting to*",
    type: "dropdown",
    url: "User-Data",
    key: "reportingUser",
    required: true,
  },

  {
    name: "Status*",
    type: "dropdown",
    required: true,
    url: "Status",
    key: "status",
  },
  {
    name: "Device Information.",
    type: "text",
    required: false,
    key: "deviceInfo",
  },
];

export default {
  UserFields: UserFields,
};
