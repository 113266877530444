import React, { useEffect, useState } from "react";
import AccordionItem from "./AccordionItem";
import { useLocation } from "react-router-dom";
import { getData } from "../../services/NetworkServices";
import { message } from "antd";
import { FaTimes } from "react-icons/fa";

export default function Accordion() {
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    getData(`Journey/ViewJourneyResponse/` + id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
        setError(true);
      });
  }, [id]);

  if (error) {
    return message.error("This visit has not been executed yet!");
  }

  const visitNotes = data.visitNotesDto || [];
  const journeyPictures = data.journeyPictureDto || [];
  const stockDistribution = data.stockDistributionDto || [];

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  return (
    <div className="max-w-screen mx-auto bg-white shadow-md rounded-md">
      <AccordionItem title="Visit Details">
        <div className="grid grid-cols-4 gap-4 gap-y-8">
          <p>
            <strong>Record Type:</strong> <br /> {data.recordName || "N/A"}
          </p>
          <p>
            <strong>Objective:</strong> <br /> {data.objectiveName || "N/A"}
          </p>
          <p>
            <strong>Total Steps:</strong> <br /> {data.totalSteps || "N/A"}
          </p>
          <p>
            <strong>No. of Persons:</strong> <br /> {data.noOfPersons || "N/A"}
          </p>
          <p>
            <strong>Planned Date:</strong> <br /> {data.plannedDate || "N/A"}
          </p>
          <p>
            <strong>Actual Date:</strong> <br /> {data.actualDate || "N/A"}
          </p>
          <p>
            <strong>Status:</strong> <br /> {data.visitStatusName || "N/A"}
          </p>
        </div>
        <div className="mt-7">
          <strong>File:</strong> <br />{" "}
          <img
            src={data.visitFilePath}
            alt=""
            className="w-[130px] cursor-pointer"
            onClick={() => openModal(data.visitFilePath)}
          />
          {/* {journeyPictures.map((pic, index) => (
            <div key={index} className="flex flex-col px-2 py-4 rounded-md">
              <p>
                <strong>File:</strong>{" "}
                {pic.fileName && (
                  <img
                    src={pic.filePath}
                    alt=""
                    className="w-1/4 cursor-pointer"
                    onClick={() => openModal(pic.filePath)}
                  />
                )}
                <strong>{pic.fileName || "No file available"}</strong>
              </p>
            </div>
          ))} */}
        </div>
      </AccordionItem>
      <AccordionItem title="Take Picture">
        <div className="grid grid-cols-2 gap-4 gap-y-3">
          {journeyPictures.map((pic, index) => (
            <div key={index} className="flex flex-col px-2 py-4 rounded-md">
              <p>
                <strong>File:</strong>{" "}
                {pic.fileName && (
                  <img
                    src={pic.filePath}
                    alt=""
                    className="w-1/4 cursor-pointer"
                    onClick={() => openModal(pic.filePath)}
                  />
                )}
                <strong>{pic.fileName || "No file available"}</strong>
              </p>
              <p>
                <strong>Time:</strong> <br />{" "}
                {pic.createdOn
                  ? new Date(pic.createdOn).toLocaleString()
                  : "No time available"}
              </p>
            </div>
          ))}
        </div>
      </AccordionItem>
      <AccordionItem title="Take Notes">
        <div className="gap-y-3 grid grid-cols-1">
          {visitNotes.map((note, index) => (
            <p key={index}>
              <strong>Note {index + 1}</strong> <br />{" "}
              {note.description || "No description available"}
            </p>
          ))}
        </div>
      </AccordionItem>
      <AccordionItem title="Stock Distribution">
        <div className="grid grid-cols-3 gap-3">
          {stockDistribution
            .map((item) => item.stockPictureResponse || [])
            .flat()
            .map((pic, index) => {
              const { fileName, filePath } = pic;
              return (
                <p key={index} className="mb-3">
                  {fileName && (
                    <img
                      src={filePath}
                      alt={pic.fileName}
                      className="cursor-pointer"
                      onClick={() => openModal(filePath)}
                    />
                  )}
                  <strong>{fileName || "No file available"}</strong> <br />{" "}
                </p>
              );
            })}
        </div>
        <div className="w-[1050px] flex justify-start items-center">
          <div className="flex flex-col w-full mb-6 mt-6">
            <ul className="flex justify-around items-center border-b-2 border-black w-full font-semibold">
              <li className="w-28 mr-28">Material Name</li>
              <li className="w-28 mr-9">Quantity</li>
              <li className="w-28 mr-28">Distributed To</li>
            </ul>
            {stockDistribution.length > 0 ? (
              stockDistribution.map((item, index) => (
                <ul
                  key={index}
                  className={`flex justify-around items-center w-full text-sm mt-1 mr-2 h-10 ${
                    index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
                >
                  <li className="mr-24">{item.materialName || "N/A"}</li>
                  <li className="mr-24">{item.quantity || "N/A"}</li>
                  <li className="mr-24">{item.distributedTo || "N/A"}</li>
                </ul>
              ))
            ) : (
              <p>No stock distribution data available.</p>
            )}
          </div>
        </div>
      </AccordionItem>

      {/* Modal Implementation */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          <div className="relative bg-white p-4 rounded-md">
            <button
              className="absolute top-2 right-2 text-3xl text-red-500"
              onClick={closeModal}
            >
              <FaTimes />
            </button>
            <img
              src={selectedImage}
              alt="Full Size"
              className="max-w-full max-h-screen"
            />
          </div>
        </div>
      )}
    </div>
  );
}
