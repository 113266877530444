import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { message } from "antd";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../services/NetworkServices";
import { useRole } from "../RoleContext";
import { usePermission } from "../PermissionContext";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import dayjs from "dayjs";

const Grn = ({click,setClick}) => {
  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(null);
  const [userData, setUser] = useState([]);
  const [drop, setdrop] = useState(0);
  const [GINdrop, setGINdrop] = useState(0);
  const [GIN, setGIN] = useState([]);
  const [removedGINs, setRemovedGINs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedZone, setSelectedZone] = useState("");
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [filterUserData, setFilterUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [filterGinData, setFilterGinData] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [selectedGrn, setSelectedGrn] = useState({
    Date: null,
    User: "",
    receiptDate: "",
    // zoneId: null,
    UserId: null,
    selectedGin: {
      ref: "",
      date: null,
      quantity: null,
      detailId: null,
      materialId: null,
      balanceQuantity: null,
      ginMasterId: null,
      materialDesc: null,
      materialCode: null,
      batchId: null,
    },
  });
  const [showDropdown, setShowDropdown] = useState({
    zone: false,
    user: false,
    grnDropdown: false,
  });
  const dropdownRefs = {
    grnDropdown: useRef(null),
    zone: useRef(null),
    user: useRef(null),
  };
  const [grnlist, setgrnlist] = useState([]);

  const navigate = useNavigate();

  const handleAddGIN = () => {
    const existingMaterial = (grnlist || []).find(
      (item) => item.detailId === selectedGrn.detailId
    );

    if (existingMaterial) {
      message.warning("This GIN is already selected.");
      return;
    }
    if (
      (selectedGrn.User.trim() !== "" &&
        selectedGrn.Date !== null &&
        selectedGrn.selectedGin.ref !== "" &&
        selectedGrn.selectedGin.date !== null &&
        selectedGrn.selectedGin.quantity !== null,
      selectedGrn.selectedGin.balanceQuantity !== null,
      selectedGrn.selectedGin.batchId !== null,
      selectedGrn.selectedGin.materialId !== null,
      selectedGrn.selectedGin.detailId !== null)
    ) {
      setgrnlist([...grnlist, selectedGrn]);

      setSelectedGrn({
        Date: selectedGrn.Date,
        User: selectedGrn.User,
        UserId: selectedGrn.UserId,
        selectedGin: {
          ref: "",
          date: null,
          quantity: null,
          detailId: null,
          materialId: null,
        },
      });
      // setSelectedUser(user)
      setDisabled(true);
    }
  };
  // console.log('grnnnnlist',grnlist);

  const getGINs = async () => {
    const token = localStorage.getItem("token");
    const res = await getData(`Stock/UserGIN/${selectedGrn.UserId}`);
    setGIN(res?.data?.data);
    setFilterGinData(res?.data?.data);
    console.log("res Data", res);
  };
  const getUsers = async () => {
    const token = localStorage.getItem("token");

    const res = await getData("User-Data/GetVisitorsforJC");
    const sortedUserData = res.data?.data?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });
    setUser(sortedUserData);
    setFilterUserData(sortedUserData);
    console.log("USerData", res);
  };

  const filterUser = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterUserData(
      userData.filter((user) => user.username.toLowerCase().includes(value))
    );
  };
  const filterGin = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterGinData(
      GIN.filter((gin) => gin.giN_No.toLowerCase().includes(value))
    );
  };
  const filterZone = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterZoneData(
      zones.filter((zone) => zone.title.toLowerCase().includes(value))
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (selectedGrn.UserId) {
      getGINs();
    }
  }, [selectedGrn.UserId]);

  const handleSubmitGRN = async () => {
    // setgrnlist(...grnlist , zoneId)
    if (grnlist.length > 0) {
      setLoading(true);
      let singleList = [];
      grnlist.map((grn) => {
        grn.selectedGin.map((gin) => {
          gin.ginDetailId = grn.detailId;
          singleList.push(gin);
        });
      });
      // console.log('single list ', singleList);
      // zoneId.push(grn)

      const data = grnlist.map((grn) => ({
        userInfoId: grn.UserId,
        receiptDate: grn.Date,
        zoneId: zoneId,
        details: singleList,
      }));

      const obj = data?.pop();
      const token = localStorage.getItem("token");

      try {
        const res = await postData("Stock/GRN", obj, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.status === 200) {
          message.success("GRN created successfully.");

          setDisabled(false);
          // Resetting fields except the date
          setgrnlist("");
          setSelectedGrn((prev) => ({
            ...prev,
            User: "",
            UserId: null,
            selectedGin: {
              ref: "",
              date: prev.selectedGin.date, // Keep the date as it is
              quantity: null,
              detailId: null,
              materialId: null,
            },
          }));
          setSelectedZone("");
          setSelectedUser("");
          setSelectedGrn({
            ...selectedGrn,
            ref: "",
          });
        } else {
          message.error("Failed to create GRN.");
        }
      } catch (error) {
        message.error(error.response?.data?.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    setDate(currentDate);
    setSelectedGrn({ ...selectedGrn, Date: formattedDate });
  }, []);

  const { permissions } = usePermission();
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const hasPermission = () => {
    permissions?.forEach((permission) => {
      if (permission.pageRoute === "/grn") {
        if (permission.canAdd) {
          setAdd(true);
        }
        if (permission.canEdit) {
          setEdit(true);
        }
      }
    });
  };

  useEffect(() => {
    hasPermission();
    // eslint-disable-next-line
  }, [permissions]);

  const handleClickOutside = (e) => {
    Object.keys(dropdownRefs).forEach((key) => {
      if (
        dropdownRefs[key].current &&
        !dropdownRefs[key].current.contains(e.target)
      ) {
        setShowDropdown((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
// Get the stored user data from localStorage
const storedUserData = localStorage.getItem("userData");

// Check if the data exists before parsing
let zones = [];
if (storedUserData) {
  try {
    const userData = JSON.parse(storedUserData);
    zones = userData.zones || [];
  } catch (error) {
    console.error('Failed to parse userData:', error);
  }
} else {
  console.warn('No userData found in localStorage');
}

console.log('zones:', zones); // Check what zones contains


  // console.log("GRN LIst: ",grnlist);
  return (
    <main className="ml-6">
      <h1 className="h-[37px] my-4 ml-2 font-semibold text-[32px] ">
        Stock Management - GRN
      </h1>
      <div className="flex flex-col gap-5  justify-center items-center w-full mt-10 ">
        <div className="flex flex-wrap space-y-5 justify-evenly items-center w-full gap-x-10">
          <div className="relative flex justify-center items-center mt-5  ">
            <div className="relative ">
              <DatePicker
                className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                placeholderText="Select the Date"
                selected={date}
                onChange={(date) => {
                  const formattedDate = date ? date.toISOString() : "";
                  setDate(date);
                  setSelectedGrn({ ...selectedGrn, Date: formattedDate });
                }}
                disabled
              />
              <p className="absolute bottom-9 left-8 font-bold bg-white">
                Date*
              </p>
              <FaCalendarAlt className="absolute text-black bottom-3 right-6 text-md cursor-pointer" />
            </div>
          </div>

          {/* Zones Starts here */}

          <div
            className="relative flex justify-center items-center "
            ref={dropdownRefs.zone}
          >
            <div className="relative ">
              <input
                className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                type="text"
                name="zone"
                autoComplete="off"
                placeholder="Select the zone"
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, zone: !prev.zone }))
                }
                value={selectedZone || ""}
                onChange={(e) => {
                  const zoneValue = e.target.value;
                  setSelectedZone(zoneValue);
                  filterZone(e);
                  if (!zoneValue) {
                    setSelectedUser("");
                  }
                }}
                disabled={disabled}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-9 left-6 font-bold bg-white px-2">
                Zone*
              </p>
            </div>
            {showDropdown.zone && (
              <div className="absolute top-11 w-[240px] max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg z-10">
                {zones.map((item, index) => (
                  <div
                    key={index}
                    value={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setSelectedZone(item.title);
                      console.log("zooone Id", item.id);
                      setZoneId(item.id);
                      setShowDropdown((prev) => ({
                        ...prev,
                        zone: !prev.zone,
                      }));
                    }}
                    //   onChange={setSelectedZone}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* user starts Here */}

          <div
            className="relative flex justify-center items-center"
            ref={dropdownRefs.user}
          >
            <div className="relative ">
              <input
                className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                type="text"
                name="user"
                autoComplete="off"
                placeholder="Select the User"
                onClick={() =>
                  setShowDropdown((prev) => ({ ...prev, user: !prev.user }))
                }
                value={selectedUser || ""}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                  filterUser(e);
                }}
                disabled={disabled}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-9 left-6 font-bold bg-white px-2">
                User*
              </p>
            </div>
            {showDropdown.user && (
              <div className="absolute top-11 w-[240px] max-h-40 overflow-y-auto bg-white border-2 rounded-md shadow-lg">
                {filterUserData.map((item, index) => (
                  <div
                    key={index}
                    value={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      setSelectedUser(item.username);
                      if (grnlist.length === 0) {
                        setSelectedGrn({
                          ...selectedGrn,
                          User: item.username,
                          UserId: item.id,
                        });
                      }
                      setShowDropdown((prev) => ({
                        ...prev,
                        user: !prev.user,
                      }));
                    }}
                  >
                    {item.fullName}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* selectedGin */}
          <div
            className=" relative flex justify-center items-center"
            ref={dropdownRefs.grnDropdown}
          >
            <div className="relative">
              <input
                type="text"
                className="outline-red-200 py-2 px-6 border-2 border-red-200 rounded-full z-10 "
                placeholder=""
                name="grnDropdown"
                value={selectedGrn.ref || []}
                autoComplete="off"
                disabled={!selectedUser || !selectedGrn.UserId}
                onChange={(e) => {
                  setSelectedGrn({
                    ...selectedGrn,
                    selectedGin: e.target.value,
                  });
                  filterGin(e);
                }}
                onClick={() => {
                  setShowDropdown((prev) => ({
                    ...prev,
                    grnDropdown: !prev.grnDropdown,
                  }));
                }}
              />
              <IoIosArrowDropdownCircle className="absolute bottom-3 right-4 text-xl" />
              <p className="absolute bottom-9 left-6 font-bold bg-white px-2">
                GIN*
              </p>
            </div>
            {showDropdown.grnDropdown && (
              <div className="absolute top-11 w-[240px] max-h-40 overflow-y-auto bg-white  border-2 rounded-md shadow-lg ">
                {(filterGinData || []).map((item, index) => (
                  <div
                    key={index}
                    value={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 text-black"
                    onClick={() => {
                      const data = [];
                      item.dto?.map((dto) => {
                        const tempData = {
                          quantity: dto.quantity,
                          balanceQuantity: dto.balanceQuantity,
                          ginMasterId: dto.ginMasterId,
                          materialId: dto.materialId,
                          materialDesc: dto.materialDesc,
                          materialCode: dto.materialCode,
                          batchId: dto.batchId,
                        };

                        data.push(tempData);
                      });
                      setSelectedGrn({
                        ...selectedGrn,
                        // zoneId: item.zoneId,
                        ref: item.giN_No,
                        receiptDate: item.issuanceDate,
                        detailId: item.id,
                        selectedGin: data,
                      });

                      setShowDropdown((prev) => ({
                        ...prev,
                        grnDropdown: !prev.grnDropdown,
                      }));
                    }}
                  >
                    {item.giN_No}
                  </div>
                ))}
              </div>
            )}
          
          </div>
        </div>
        {add && (
          <div className="flex justify-end pr-8 w-full">
            <button
              className="w-[180px] btnForNotes btn"
              onClick={handleAddGIN}
            >
              Add GIN
            </button>
          </div>
        )}
        {/* <div className="flex justify-center items-center relative h-12 transition-all duration-500 col-span-3"></div> */}
      </div>

      {/* Table */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="rounded-2xl mb-4 mt-8 border border-[#1E1E1E] min-h-[30vh] h-[40vh] overflow-auto ">
            <table className="w-full mx-auto overflow-auto">
              <thead className="bg-[#F7F7F7] border-b-2 border-[#1E1E1E] rounded-2xl text-left overflow-auto">
                <tr>
                  <th className="px-4 pb-1 pt-4 rounded-tl-2xl w-1/5">
                    GIN Ref
                  </th>
                  <th className="px-4 pb-1 pt-4 w-1/5">GIN Date</th>
                  <th className="px-4 pb-1 pt-4 w-1/5">User</th>
                  <th className="px-4 pb-1 pt-4 w-1/5">Material Code</th>
                  <th className="px-4 pb-1 pt-4 w-1/5">Material Desc</th>
                  <th className="px-4 pb-1 pt-4 w-1/5">Balance Qty</th>
                </tr>
              </thead>

              <tbody className="rounded-2xl">
                {Array.isArray(grnlist) && grnlist.length > 0 ? (
                  (grnlist || []).map((grn, index) =>
                    grn.selectedGin.map((s, i) => (
                      <tr
                        key={`${index}-${i}`}
                        className={
                          index % 2 === 0 ? "bg-[#E7E7E7]" : "bg-white"
                        }
                      >
                        <td className="px-4 py-2">{grn.ref}</td>
                        <td className="px-4 py-2">
                          {dayjs(grn.Date).format("YYYY-MM-DD")}
                        </td>
                        <td className="px-4 py-2">{grn.User}</td>
                        <td className="px-4 py-2">{s.materialCode}</td>
                        <td className="px-4 py-2">{s.materialDesc}</td>
                        <td className="px-4 py-2">{s.quantity}</td>
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td className="m-4 font-normal">No data to show</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end gap-4">
            {add && (
              <button
                className="w-[180px] h-[40px] flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#F50005] shadow-lg shadow-slate-500 mr-2"
                onClick={handleSubmitGRN}
              >
                Create GRN
              </button>
            )}
            <button
              className="w-[180px] h-[40px] flex justify-center items-center text-center rounded-tl-3xl rounded-br-3xl text-white px-6 py-2 rounded-bl-3xl bg-[#1E1E1E] shadow-lg shadow-slate-500 mr-2"
              onClick={()=>{

                navigate("/GRN")
                setClick(!click)
              }
              }
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </main>
  );
};

export default Grn;
